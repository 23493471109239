<template>
  <div>
    <v-card
      width="-webkit-fill-available"
      height="525"
      elevation="8"
    >
      <v-row  v-if="circular" style="margin-top: 3px;">
        <v-col class="conteiner-circular"
          >
          <v-progress-circular
            style="top: 50%; left: 50%;
                transform: translate(-50%, -50%);" 
            :size="50"
            color="#283271"
            indeterminate>
          </v-progress-circular>
        </v-col>
      </v-row> 
      <v-col v-if="dataGlobal != null && !circular">
        <v-row :style="viewList ? 'margin-bottom: 5px;' : 'margin-bottom: -10px;'">
          <v-col v-if="copyDataGlobal.length > 0" cols="1" class="back_folders">
            <i @click="backFolder()" class="fa-solid fa-arrow-left"></i>
          </v-col>
          <v-col cols="10" style="padding: 15px 0px 0px 10px!important;">
            <p style="margin-bottom: 0px;" v-if="dataGlobal[0].text != undefined">{{ dataGlobal[0].text }}</p>
          </v-col>
          <v-col v-if="viewList" class="change_vista">
            <i  @click="viewList = false" class="fa-solid fa-table-cells"></i>
          </v-col>
          <v-col v-if="!viewList" class="change_vista">
            <i @click="viewList = true" class="fa-solid fa-solid fa-table-list"></i>
          </v-col>
        </v-row>
         <v-row v-if="viewList" style="padding-left: 15px; padding-right: 40px; margin-top: 0px;">
            <v-col cols="8">
              <p class="title_text_tabla">
                Nombre
              </p>
            </v-col>
            <v-col cols="2">
              <p class="title_text_tabla">
                Tamaño
              </p>
            </v-col>
            <v-col cols="2">
              <p style="text-align: end;" class="title_text_tabla">
                Opciones
              </p>
            </v-col>
          </v-row>

        <v-row v-if="!viewList" class="contenedor_files"  v-contextmenu.prevent="showPopup"
          @click="hidePopup" v-on:scroll="handleScroll">
          <v-col
            v-for="(item, index) in  dataGlobal[0].children"
            cols="3"
            :key="index"
          >
            <v-card
              class="item-file pa-2"
              style="cursor: pointer;"
              outlined
              elevation="4"
              rounded="2"
              @contextmenu.prevent.stop="(event) => {if(event.button === 2) openMenu(index)}"
              v-on:dblclick="validIconDoble(item.text, item) ? openFolder(item, index) : ''"
            >
              <v-col style="display: flex; justify-content: flex-end; padding-top: 1px;">
                <v-menu
                  rounded='lg'
                  transition="slide-x-transition"
                  left
                >
                  <template v-slot:activator="{ attrs, on }">
                    <a 
                      id="actionMenu"
                      style="padding: 0px 10px 0px 20px;"
                      v-bind="attrs"
                      v-on="on"
                      @mouseover="activeIcon(index)"
                      @mouseleave="desactiveIcon()"
                      @click="detectFile(item)"
                    >
                      <i :key="index" :class="!optionFile && optionSelect == index ? 'fa-solid fa-ellipsis fa-beat' : 'fa-solid fa-ellipsis-vertical'"></i>
                    </a>
                  </template>  

                  <v-list v-if="!validFolder(item.text) && !geoMpB"
                  >
                    <v-list-item
                      v-for="(opcionFile, index_) in opcionFiles"
                      :key="index_"
                      link
                      @click="index_ == 2 ?  deleteNode(item.id, index) : index_ == 5 ? listUbicacion(index) : index_ == 3 ? asociFile(index) :  index_ == 4 ? shareFile(item) : downloadFile(item.id)"
                    >
                      <v-list-item-icon v-if="statusLoad == false || index_ != 5">
                        <i :class="opcionFile.icon"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="statusLoad == false || index_ != 5" >{{opcionFile.text}}</v-list-item-title>
                        <v-progress-circular
                          v-if="index_ == 5 && statusLoad == true"
                          :size="20"
                          :width="1"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list> 

                  <v-list v-if="validFolder(item.text) && !geoMpB" >
                    <v-list-item
                      v-for="(opcionFolder, index__) in opcionFolders"
                      :key="index__"
                      link
                      @click="deleteNode(item.id, index)"
                      
                    >
                      <v-list-item-icon>
                        <i :class="opcionFolder.icon"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title >{{opcionFolder.text}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  
                  <v-list v-if="geoMpB">
                    <v-list-item
                      v-for="(opcionFolder, index_) in opcionCanvas()"
                      :key="index_"
                      link
                      @click="index_ == 0 ? joinCanvas(index, item) : deleteCanva(item.id)" 
                    >
                      <v-list-item-icon>
                        <i :class="opcionFolder.icon"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title >{{opcionFolder.text}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                          
                </v-menu>

                <v-menu
                  v-if="opcionFiles.length == 5"
                  rounded='lg'
                  transition="slide-x-transition"
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <a 
                      id="menuLocation"
                      v-bind="attrs"
                      v-on="on"
                        
                    >
                      <i style="display: none!important;"  :key="index+'_alt_'"></i>
                    </a>
                  </template>  

                  <v-list
                    id="custom_listGroup"
                  >
                    <v-list-item
                      v-for="(opcionLocation, index__) in opcionFiles[3].children"
                      :key="index__"
                      link
                    >
                      <v-list-item-content v-if="opcionLocation.children != undefined">
                        <v-list-group
                        
                          :value="index__ == 0 ? true : false"
                          no-action
                          sub-group
                        >
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title>Canva:</v-list-item-title>
                              <v-list-item-subtitle>{{opcionLocation.text}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="(data, i) in opcionLocation.children"
                            :key="i"
                            link
                            @click="locationCanvas(opcionLocation)"
                          >
                            <v-list-item-icon v-if="data.idArea != undefined" >
                              <i class="fa-solid fa-vector-square fa-1x"></i>
                            </v-list-item-icon>
                            <v-list-item-icon  v-if="data.idPoint != undefined" >
                              <i class="fa-solid fa-map-pin fa-1x"></i>
                            </v-list-item-icon>
                            <v-list-item-subtitle>{{data.text}}</v-list-item-subtitle>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item-content>

                      <v-list-item-content @click="locationCanvas(opcionLocation)" style="padding: 19px!important;" v-if="opcionLocation.children == undefined">
                        <v-list-item-title>Canva:</v-list-item-title>
                        <v-list-item-subtitle>{{opcionLocation.text}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>                           
                </v-menu>

                <v-menu
                  rounded='lg'
                  transition="slide-x-transition"
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <a 
                      id="menuAsoci"
                      v-bind="attrs"
                      v-on="on"
                        
                    >
                      <i style="display: none!important;"  :key="index+'_alt_'"></i>
                    </a>
                  </template>  

                  <v-list
                    id="custom_listGroup"
                  >
                    <v-list-item
                      link
                    > 
                      <v-list-item-content>
                        <v-list-group
                          :value="true"
                          prepend-icon="mdi-map"
                        >
                          <template v-slot:activator>
                            <v-list-item-title>Canvas Georeferenciado:</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="(opcionAsociGeo, index__) in opcionFiles[3].children.C_GEO"
                            :key="index__"
                            link
                          >
                            <v-list-item-content v-if="opcionAsociGeo.children != undefined">
                              <v-list-group
                              
                                :value="false"
                                no-action
                                sub-group
                              >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{opcionAsociGeo.name}}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>

                                <v-list-item @click="setTypeDocument(item, 0, opcionAsociGeo, 0)" link >
                                  <v-list-item-icon >
                                    <v-icon color="black">mdi-artboard</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-subtitle>Asociar a vista</v-list-item-subtitle>
                                </v-list-item>

                                <v-list-item
                                  v-for="(data, i) in opcionAsociGeo.children[0]"
                                  :key="i"
                                  link
                                  @click="data.type != undefined ? 
                                    setTypeDocument(item, 1, data, 0) : 
                                    setTypeDocument(item, 2, data, 0)"
                                >
                                  <v-list-item-icon v-if="data.type != undefined" >
                                    <i class="fa-solid fa-vector-square fa-1x"></i>
                                  </v-list-item-icon>
                                  <v-list-item-icon  v-if="data.type == undefined" >
                                    <i class="fa-solid fa-map-pin fa-1x"></i>
                                  </v-list-item-icon>
                                  <v-list-item-subtitle v-if="data.name != undefined">{{data.name}}</v-list-item-subtitle>
                                  <v-list-item-subtitle v-if="data.name == undefined">{{data.Codigo}}</v-list-item-subtitle>
                                </v-list-item>

                              </v-list-group>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item-content>
                    </v-list-item> 
                  
                    <v-list-item
                        link
                      > 
                        <v-list-item-content>
                          <v-list-group
                            :value="true"
                            prepend-icon="mdi-image-edit"
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title>Canvas MpBits:</v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <v-list-item
                              v-for="(opcionAsociMpBits, index__) in opcionFiles[3].children.C_MP_BITS"
                              :key="index__+'Mp'"
                              link
                            >
                              <v-list-item-content v-if="opcionAsociMpBits.children != undefined">
                                <v-list-group

                                  :value="false"
                                  no-action
                                  sub-group
                                >
                                  <template v-slot:activator>
                                    <v-list-item-content>
                                      <v-list-item-subtitle>{{opcionAsociMpBits.name}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>

                                  <v-list-item @click="setTypeDocument(item, 0, opcionAsociMpBits, 1)" link >
                                    <v-list-item-icon >
                                      <v-icon color="black">mdi-artboard</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-subtitle>Asociar a vista</v-list-item-subtitle>
                                  </v-list-item>
                                  <v-list-item
                                    v-for="(data, i) in opcionAsociMpBits.children[0]"
                                    :key="i"
                                    link
                                    @click="data.type != undefined ? 
                                      setTypeDocument(item, 1, data, 1) : 
                                      setTypeDocument(item, 2, data, 1)"
                                  >
                                    <v-list-item-icon v-if="data.type != undefined" >
                                      <i class="fa-solid fa-vector-square fa-1x"></i>
                                    </v-list-item-icon>
                                    <v-list-item-icon  v-if="data.type == undefined" >
                                      <i class="fa-solid fa-map-pin fa-1x"></i>
                                    </v-list-item-icon>
                                    <v-list-item-subtitle v-if="data.name != undefined" >{{data.name}}</v-list-item-subtitle>
                                    <v-list-item-subtitle  v-if="data.name == undefined" >{{data.Codigo}}</v-list-item-subtitle>
                                  </v-list-item>
                                </v-list-group>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                      </v-list-item-content>
                    </v-list-item> 

                  </v-list>                           
                </v-menu>

              </v-col>
              <v-col style="display: flex; justify-content: center; padding-top: 1px;">
                <i :class="validIcon(item.text, item)" :style="validColor(item.text)"></i>
              </v-col>
              <div style="height: 35px;">
                <p style="text-align: center; margin-bottom: 0px;" class="ex_text">
                  {{item.text}}
                </p>
              </div>
              <div style="display: flex; justify-content: center;">
                <p style="margin-bottom: 0;">+--------------------------+</p>
              </div>
              <v-row>
                <v-col cols="8">
                  <p class="ex_text">Peso de archivo:</p>
                  <p class="ex_text">N/A</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

        </v-row>

        <v-col v-if="viewList" class="contenedor_files_list"  v-contextmenu.prevent="showPopup"
          @click="hidePopup" v-on:scroll="handleScroll">
          <div 
            v-for="(item, index) in  dataGlobal[0].children"
            :key="index">
            <v-row
              v-contextmenu.prevent="hidePopup"
              style="padding-left: 10px; padding-right: 10px;"
            >
              <v-card
                class="item-file pa-2"
                style="cursor: pointer;"
                outlined
                elevation="1"
                rounded="2"
                @contextmenu.prevent.stop="(event) => {if(event.button === 2) openMenu(index)}"
                v-on:dblclick="validIconDoble(item.text, item) ? openFolder(item, index) : ''"
              >
                <v-row style="display: flex; align-items: center;
                  flex-direction: row; height: 60px;">
                  <v-col cols="1">
                    <i :class="validIcon(item.text, item)" :style="validColor(item.text)"></i>
                  </v-col>
                  <v-col cols="7">
                    <p style="margin-bottom: 0px;" class="text_list">
                      {{item.text}}
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <p class="text_list">N/A</p>
                  </v-col>
                  <v-col cols="2" style="display: flex; justify-content: center;">
                    <v-menu
                      rounded='lg'
                      transition="slide-x-transition"
                      left
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <a
                          id="actionMenu" 
                          style="padding: 0px 10px 0px 20px;"
                          v-bind="attrs"
                          v-on="on"
                          @mouseover="activeIcon(index)"
                          @mouseleave="desactiveIcon()"
                          @click="detectFile(item)"
                        >
                          <i :key="index" class="fa-solid fa-ellipsis"></i>
                        </a>
                      </template>  

                      <v-list v-if="!validFolder(item.text) && !geoMpB">
                        <v-list-item
                          v-for="(opcionFile, index_) in opcionFiles"
                          :key="index_"
                          link
                          @click="index_ == 2 ?  deleteNode(item.id, index) : index_ == 5 ? listUbicacion(index) : index_ == 3 ? asociFile(index) : index_ == 4 ? shareFile(item) : downloadFile(item.id)">

                          <v-list-item-icon v-if="statusLoad == false || index_ != 5">
                            <i :class="opcionFile.icon"></i>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-if="statusLoad == false || index_ != 5" >{{opcionFile.text}}</v-list-item-title>
                            <v-progress-circular
                              v-if="index_ == 5 && statusLoad == true"
                              :size="20"
                              :width="1"
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> 

                      <v-list v-if="validFolder(item.text) && !geoMpB">
                        <v-list-item
                          v-for="(opcionFolder, index__) in opcionFolders"
                          :key="index__"
                          link
                          @click="deleteNode(item.id, index)"
                        >
                          <v-list-item-icon>
                            <i :class="opcionFolder.icon"></i>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title >{{opcionFolder.text}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-list v-if="geoMpB">
                        <v-list-item
                          v-for="(opcionCanva, index__) in opcionCanvas() "
                          :key="index__"
                          link
                          @click="index__ == 0 ? joinCanvas(index, item, false) : deleteCanva(item.id, item)" 
                        >
                          <v-list-item-icon>
                            <i :class="opcionCanva.icon"></i>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title> {{opcionCanva.text}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                          
                    </v-menu>

                    <v-menu
                      v-if="opcionFiles.length == 6"
                      rounded='lg'
                      transition="slide-x-transition"
                      left
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <a 
                          id="menuLocation"
                          v-bind="attrs"
                          v-on="on"
                            
                        >
                          <i style="display: none!important;"  :key="index+'_alt_'"></i>
                        </a>
                      </template>  

                      <v-list
                        id="custom_listGroup"
                      >
                        <v-list-item
                          v-for="(opcionLocation, index__) in opcionFiles[5].children"
                          :key="index__"
                          link
                        >
                          <v-list-item-content v-if="opcionLocation.children != undefined">
                            <v-list-group
                            
                              :value="index__ == 0 ? true : false"
                              no-action
                              sub-group
                            >
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title>{{opcionLocation.text}}</v-list-item-title>
                                </v-list-item-content>
                              </template>

                              <v-list-item
                                v-for="(data, i) in opcionLocation.children"
                                :key="i"
                                link
                                @click="locationCanvas(opcionLocation)"
                              >
                                <v-list-item-icon v-if="data.idArea != undefined" >
                                  <i class="fa-solid fa-vector-square fa-1x"></i>
                                </v-list-item-icon>
                                <v-list-item-icon  v-if="data.idPoint != undefined" >
                                  <i class="fa-solid fa-map-pin fa-1x"></i>
                                </v-list-item-icon>
                                <v-list-item-subtitle>{{data.text}}</v-list-item-subtitle>
                              </v-list-item>
                            </v-list-group>
                          </v-list-item-content>

                          <v-list-item-content @click="locationCanvas(opcionLocation)" style="padding: 19px!important;" v-if="opcionLocation.children == undefined">
                            <v-list-item-title >Canva:</v-list-item-title>
                            <v-list-item-subtitle>{{opcionLocation.text}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>                           
                    </v-menu>

                    <v-menu
                      rounded='lg'
                      transition="slide-x-transition"
                      left
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <a 
                          id="menuAsoci"
                          v-bind="attrs"
                          v-on="on"
                            
                        >
                          <i style="display: none!important;"  :key="index+'_alt_'"></i>
                        </a>
                      </template>  

                      <v-list
                        id="custom_listGroup"
                      >
                        <v-list-item
                          link
                        > 
                          <v-list-item-content>
                            <v-list-group
                              :value="true"
                              prepend-icon="mdi-map"
                            >
                              <template v-slot:activator>
                                <v-list-item-title>Canvas Georeferenciado:</v-list-item-title>
                              </template>

                              <v-list-item
                                v-for="(opcionAsociGeo, index__) in opcionFiles[3].children.C_GEO"
                                :key="index__"
                                link
                              >
                                <v-list-item-content v-if="opcionAsociGeo.children != undefined">
                                  <v-list-group
                                  
                                    :value="false"
                                    no-action
                                    sub-group
                                  >
                                    <template v-slot:activator>
                                      <v-list-item-content>
                                        <v-list-item-subtitle>{{opcionAsociGeo.name}}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>

                                    <v-list-item @click="setTypeDocument(item, 0, opcionAsociGeo, 0)" link >
                                      <v-list-item-icon >
                                        <v-icon color="black">mdi-artboard</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-subtitle>Asociar a vista</v-list-item-subtitle>
                                    </v-list-item>

                                    <v-list-item
                                      v-for="(data, i) in opcionAsociGeo.children[0]"
                                      :key="i"
                                      link
                                      @click="data.type != undefined ? 
                                        setTypeDocument(item, 1, data, 0) : 
                                        setTypeDocument(item, 2, data, 0)"
                                    >
                                      <v-list-item-icon v-if="data.type != undefined" >
                                        <i class="fa-solid fa-vector-square fa-1x"></i>
                                      </v-list-item-icon>
                                      <v-list-item-icon  v-if="data.type == undefined" >
                                        <i class="fa-solid fa-map-pin fa-1x"></i>
                                      </v-list-item-icon>
                                      <v-list-item-subtitle v-if="data.name != undefined">{{data.name}}</v-list-item-subtitle>
                                      <v-list-item-subtitle v-if="data.name == undefined">{{data.Codigo}}</v-list-item-subtitle>
                                    </v-list-item>

                                  </v-list-group>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list-item-content>
                        </v-list-item> 
                      
                        <v-list-item
                            link
                          > 
                            <v-list-item-content>
                              <v-list-group
                                :value="true"
                                prepend-icon="mdi-image-edit"
                              >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title>Canvas MpBits:</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <v-list-item
                                  v-for="(opcionAsociMpBits, index__) in opcionFiles[3].children.C_MP_BITS"
                                  :key="index__+'Mp'"
                                  link
                                >
                                  <v-list-item-content v-if="opcionAsociMpBits.children != undefined">
                                    <v-list-group

                                      :value="false"
                                      no-action
                                      sub-group
                                    >
                                      <template v-slot:activator>
                                        <v-list-item-content>
                                          <v-list-item-subtitle>{{opcionAsociMpBits.name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>

                                      <v-list-item @click="setTypeDocument(item, 0, opcionAsociMpBits, 1)" link>
                                        <v-list-item-icon >
                                          <v-icon color="black">mdi-artboard</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-subtitle>Asociar a vista</v-list-item-subtitle>
                                      </v-list-item>
                                      <v-list-item
                                        v-for="(data, i) in opcionAsociMpBits.children[0]"
                                        :key="i"
                                        link
                                        @click="data.name != undefined ? setTypeDocument(item, 1, data, 1) : 
                                          setTypeDocument(item, 2, data, 1)"
                                      >
                                        <v-list-item-icon v-if="data.type != undefined" >
                                          <i class="fa-solid fa-vector-square fa-1x"></i>
                                        </v-list-item-icon>
                                        <v-list-item-icon  v-if="data.type == undefined" >
                                          <i class="fa-solid fa-map-pin fa-1x"></i>
                                        </v-list-item-icon>
                                        <v-list-item-subtitle v-if="data.name != undefined" >{{data.name}}</v-list-item-subtitle>
                                        <v-list-item-subtitle  v-if="data.name == undefined" >{{data.Codigo}}</v-list-item-subtitle>
                                      </v-list-item>
                                    </v-list-group>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-group>
                          </v-list-item-content>
                        </v-list-item> 

                      </v-list>                           
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-divider ></v-divider>
          </div>
        </v-col>
      </v-col>

      <div v-show="isPopupVisible" class="popup" :style="{ top: popupTop + 'px', left: popupLeft + 'px' }">

        <v-list  v-if="this.oldData == 1  &&  isPopupVisible">
          <v-list-item
            v-for="(opcionCont, index) in opcionContenedorL"
            :key="index"
            link 
            @click="index == 0 ? crearCarpeta(dataGlobal[0].text) : cargarArchivo(dataGlobal[0].text)"
          >
            <v-list-item-icon>
              <i :class="opcionCont.icon"></i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{opcionCont.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>   
                
        <v-list  v-if="this.oldData == 0  &&  isPopupVisible">
          <v-list-item
            v-for="(opcionCont, index) in opcionContenedor"
            :key="index"
            link 
            @click="index == 0 ? crearCarpeta(dataGlobal[0].text) : cargarArchivo(dataGlobal[0].text)"
          >
            <v-list-item-icon>
              <i :class="opcionCont.icon"></i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{opcionCont.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>   

        <v-list v-if="this.ultimType == 'MpBits' && isPopupVisible">
          <v-list-item
            v-for="(opcionCont, index) in opcionMapaBits() "
            :key="index"
            link 
            @click="index == 0 ? crearCanva(dataGlobal[0].text) : '' "
          >
            <v-list-item-icon>
              <i :class="opcionCont.icon"></i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{opcionCont.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>           
        </v-list>

        <v-list v-if="this.ultimType == 'Geo' && isPopupVisible">
          <v-list-item
            v-for="(opcionCont, index) in opcionGeo()"
            :key="index"
            link 
            @click="index == 0 ? crearCanva(dataGlobal[0].text) : ''"
          >
            <v-list-item-icon>
              <i :class="opcionCont.icon"></i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{opcionCont.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>           
        </v-list>
      </div>

      <b-modal v-if="optionAsoci != null" hide-backdrop centered :id="`tagAsing-file`" :title="`Asignacion el tipo de documento para ${optionAsoci.editingItem.text}`" hide-footer>
        <v-row>
          <v-col cols="6">
            <v-select  v-model="selectTag" :items="typeDocument" label="Seleccione tipo"></v-select>
          </v-col>
          <v-col cols="6" style="display: flex; align-items: center;
            justify-content: center;">
            <v-btn
              elevation="2"
              @click="addAsoci();"
            >
              Asignar a un area
            </v-btn> 
          </v-col>
        </v-row>
      </b-modal>

      <b-modal v-if="filePocket != null" size="lg" centered id="modal-shareUser_projectS" :title="`Compartir el archivo ${filePocket.text} al pocket`" hide-footer>
        <v-row>
          <v-col cols="6">
              <v-select @change="verifyPermision()"  v-model="selectUser" :items="users" label="Seleccione el usuario"></v-select>
          </v-col>
          <v-col cols="6" style="display: flex; align-items: center;
            justify-content: center;">
            <v-btn
              elevation="2"
              @click="sendPocket();"
              :disabled="fileF || selectUser == null"
              color="#5AB55E"
              style="color: white;"
            >
              Compartir
            </v-btn> 
          </v-col>
        </v-row>
      </b-modal>

    </v-card>

    <v-snackbar
      v-model="NotiAsoc_asoci"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se han asociado el <strong>documento</strong>

      <template v-slot:action="{ attrs }">
          <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="NotiAsoc = false"
          >
          <v-icon>
              mdi-close
          </v-icon>
          </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotiFail_asoci"
      color="red accent-2"
      outlined
      timeout="5000"
      :top="true"
    >
      No se ha podido realizar la asociación

      <template v-slot:action="{ attrs }">
          <v-btn
          color="red accent-2"
          text
          v-bind="attrs"
          @click="NotiFail = false"
          >
          <v-icon>
              mdi-close
          </v-icon>
          </v-btn>
      </template>
    </v-snackbar>

    <modalCrearCarpeta v-if="dataGlobal != null && !circular" :typeDirct="ultimType"   :titleFolder="dataGlobal[0].text" :projectId="idSelect" :folderId="dataGlobal[0].id" />
    <modalCrearCanva v-if="dataGlobal != null && !circular" :ultimType="ultimType" :titleFolder="dataGlobal[0].text" :permitEdit="permitEdit" :dataGeoL="dataGlobal" :dataMpBits="dataMpBits"/>
    <modalDeleteCanva v-if="dataGlobal != null && !circular" :ultimType="ultimType" :titleFolder="nameCanvas" :idCanvaSelect="idCanvaSelect"/>
    <modalCargaArchivo v-if="dataGlobal != null && !circular" :typeDirct="ultimType"  :editingItem="editingItem" :titleFolder="dataGlobal[0].text" :idFather="dataGlobal[0].id" :projectId="idSelect" :folderId="folderId" :nameFolders="nameFolders" :nameProject="editingItem"   />  
  </div>
</template>
<script>
   
// import api from "@/api";
import filtExistObj from '../../../../../api/scriptE/index'
import axios from "axios";
import api from '../../../../../api/index.js'
import modalCrearCarpeta from './modals/ModalCrearCarpeta.vue';
import ModalCargaArchivo from './modals/ModalCargaArchivo.vue';
import modalCrearCanva from './modals/ModalCrearCanva.vue';
import ModalDeleteCanva from './modals/ModalDeleteCanva.vue';


export default {
  name: "projectSelect",
  components: {
    modalCrearCarpeta, ModalCargaArchivo, modalCrearCanva, ModalDeleteCanva, 
  },
  data() {
    return {
      statusLoad: true,
      dataGlobal: null,
      copyDataGlobal: [],
      newDataGlobal: null,
      currentUser: JSON.parse(localStorage.user),
      NotiAsoc_asoci: false,
      NotiFail_asoci: false,
      fileF: false,
      icons:{
        pdf: 'fa-solid fa-file-pdf fa-3x',
        word: 'fa-solid fa-file-word fa-3x',
        file: 'fa-solid fa-file fa-3x',
        power_point: 'fa-solid fa-file-powerpoint fa-3x',
        file_zip: 'fa-solid fa-file-zipper fa-3x',
        video: 'fa-solid fa-file-video fa-3x',
        excel: 'fa-regular fa-file-excel fa-3x',
        file_csv: 'fa-solid fa-file-csv fa-3x',
        file_image: 'fa-regular fa-file-image fa-3x',
        folders: 'fa-solid fa-folder fa-3x',
        text: 'fa-solid fa-file-lines fa-3x',
        cavas_geo: 'fa-solid fa-map-location-dot fa-3x',
        canvas_mpb: 'fa-solid fa-images fa-3x',
        area: 'fa-solid fa-vector-square fa-3x',
        point: 'fa-solid fa-map-pin fa-3x',
      },
      iconsL:{
        pdf: 'fa-solid fa-file-pdf fa-2x',
        word: 'fa-solid fa-file-word fa-2x',
        file: 'fa-solid fa-file fa-2x',
        power_point: 'fa-solid fa-file-powerpoint fa-2x',
        file_zip: 'fa-solid fa-file-zipper fa-2x',
        video: 'fa-solid fa-file-video fa-2x',
        excel: 'fa-regular fa-file-excel fa-2x',
        file_csv: 'fa-solid fa-file-csv fa-2x',
        file_image: 'fa-regular fa-file-image fa-2x',
        folders: 'fa-solid fa-folder fa-2x',
        text: 'fa-solid fa-file-lines fa-2x',
        cavas_geo: 'fa-solid fa-map-location-dot fa-2x',
        canvas_mpb: 'fa-solid fa-images fa-2x',
        area: 'fa-solid fa-vector-square fa-2x',
        point: 'fa-solid fa-map-pin fa-2x',
      },
      validIconDoble(text, item){
        var textName = text.split('.');
        textName = textName.length > 1 ? textName : null
        var raizIsCG = (this.copyDataGlobal.length < 1 && this.ultimType == 'Geo' && textName == null)
        var raizIsCM = (this.copyDataGlobal.length < 1 && this.ultimType == 'MpBits' && textName == null)
        var area = (this.copyDataGlobal.length >= 1 && this.ultimType != null && textName == null 
          && item.type == undefined)
        var point = (this.copyDataGlobal.length >= 1 && this.ultimType != null && textName == null 
          && item.type != undefined)
        
        return raizIsCG ? true : raizIsCM ?  true : 
        area ? true : point ? true : textName == null ? true: false
      },
      validIcon(text, item) {
        
        var textName = text.split('.')
        textName = textName.length > 1 ? textName : null
        var containChild = false

        if( item.children.length > 0){
          var textNameChild = item.children[item.children.length-1].text.split('.')
          containChild = textNameChild[1] == 'sfr' ? true : false
        }

        var raizIsCG = (this.copyDataGlobal.length < 1 && this.ultimType == 'Geo' && textName == null)
        var raizIsCM = (this.copyDataGlobal.length < 1 && this.ultimType == 'MpBits' && textName == null)
        var area = (this.copyDataGlobal.length >= 1 && (this.ultimType == 'Geo' || this.ultimType == 'MpBits') && textName == null 
          && item.type == undefined && !containChild)
        var point = (this.copyDataGlobal.length >= 1 && (this.ultimType == 'Geo' || this.ultimType == 'MpBits') && textName == null 
          && containChild)

        if(!this.viewList){
          return raizIsCG ? this.icons.cavas_geo : raizIsCM ?  this.icons.canvas_mpb : 
          area ? this.icons.area : point ? this.icons.point : textName == null ? this.icons.folders : 
          textName[1] == 'xlsx' ?  this.icons.excel : textName[1] == 'docx' ? this.icons.word : 
          textName[1] == 'pptx' ? this.icons.power_point : textName[1] == 'png' ? this.icons.file_image : 
          textName[1] == 'pdf' ? this.icons.pdf : textName[1] == 'txt' ? this.icons.text : this.icons.file
        }else{
          return raizIsCG ? this.iconsL.cavas_geo : raizIsCM ?  this.iconsL.canvas_mpb : 
          area ? this.iconsL.area : point ? this.iconsL.point : textName == null ? this.iconsL.folders : 
          textName[1] == 'xlsx' ?  this.iconsL.excel : textName[1] == 'docx' ? this.iconsL.word : 
          textName[1] == 'pptx' ? this.iconsL.power_point : textName[1] == 'png' ? this.iconsL.file_image : 
          textName[1] == 'pdf' ? this.iconsL.pdf : textName[1] == 'txt' ? this.iconsL.text : this.iconsL.file
        }
        
      },
      validColor(text) {
        var textName = text.split('.');
        textName = textName.length > 1 ? textName : null

        return textName == null ? 'color: #DBC056!important;' : textName[1] == 'xlsx' ?  'color: #217346!important;' : 
        textName[1] == 'docx' ? 'color: #2B579A!important;' : textName[1] == 'pptx' ? 'color: #FD7541!important;' :
        textName[1] == 'pdf' ? 'color: #B30B00!important;' : 'color: #5F6368!important;'
      },
      validFolder(text){
       var textName = text.split('.');
       textName = textName.length > 1 ? textName : null 

       return textName == null ? true : false
      }, 
      opcionFiles: [
        {
          text: 'Descargar',
          icon: 'fa-solid fa-download'
        },
        {
          text: 'Renombrar',
          icon: 'fa-regular fa-pen-to-square'
        },
        {
          text: 'Eliminar',
          icon: 'fa-solid fa-trash'
        },
        {
          text: 'Asociar Archivo',
          icon: 'fa-solid fa-paperclip',
          children: null
        },
        {
          text: 'Compartir al pocket',
          icon: 'fa-solid fa-share-from-square'
        },
        {
          text: 'Ubicacion canva',
          icon: 'fa-solid fa-check'
        }
      ],
      opcionFolders: [
        {
          text: 'Renombrar',
          icon: 'fa-regular fa-pen-to-square'
        },
        {
          text: 'Eliminar',
          icon: 'fa-solid fa-trash'
        }
      ],
      opcionCanvas(){ return this.copyDataGlobal.length > 0 ? [
        {
          text: 'Renombrar',
          icon: 'fa-regular fa-pen-to-square'
        },
        {
          text: 'Eliminar',
          icon: 'fa-solid fa-trash'
        }
        ]:[
        {
          text: 'Acceder al canva',
          icon: 'fa-solid fa-right-to-bracket'
        },
        {
          text: 'Renombrar',
          icon: 'fa-regular fa-pen-to-square'
        },
        {
          text: 'Eliminar',
          icon: 'fa-solid fa-trash'
        }
      ] 
      },
      opcionContenedor: [
        {
          text: 'Crear carpeta',
          icon: 'fa-solid fa-plus'
        },
        {
          text: 'Cargar archivo',
          icon: 'fa-solid fa-upload'
        }
      ],
      opcionContenedorL: [
        {
          text: 'Crear carpeta',
          icon: 'fa-solid fa-plus'
        },
        {
          text: 'Cargar archivo',
          icon: 'fa-solid fa-upload'
        }
      ],
      opcionMapaBits() {return (this.copyDataGlobal.length > 0 ) ? (this.copyDataGlobal.length > 1 ? [
        {
          text: 'Crear Punto',
          icon: 'fa-solid fa-map-pin fa-1x'
        }
      ] : [

      {
          text: 'Crear Area',
          icon: 'fa-solid fa-map-location-dot fa-1x'
        },
        {
          text: 'Crear Punto',
          icon: 'fa-solid fa-map-pin fa-1x'
        }
        
      ]) : [
        {
          text: 'Crear Mapa de Bits',
          icon: 'fa-solid fa-images fa-1x'
        }

      ]
      },
      opcionGeo(){
        
       return (this.copyDataGlobal.length > 0 ) ? (this.copyDataGlobal.length > 1 ? [
        {
          text: 'Crear Punto',
          icon: 'fa-solid fa-map-pin fa-1x'
        }
      ] : [

        {
          text: 'Crear Area',
          icon: 'fa-solid fa-map-location-dot fa-1x'
        },
        {
          text: 'Crear Punto',
          icon: 'fa-solid fa-map-pin fa-1x'
        }
        
      ]) : [
        {
          text: 'Crear Canva',
          icon: 'fa-solid fa-map-location-dot fa-1x'
        }

      ]
      },
      isPopupVisible: false,
      popupTop: 0,
      popupLeft: 0,
      optionFile: true,
      optionSelect: null,
      data: null,
      circular: true,
      oldData: null,
      geoMpB: false,
      ultimType: null,
      token : JSON.parse(localStorage.user).token,
      statusRefresh: null,
      titleFolder: '',
      viewList: true,
      idCanvaSelect: null,
      selectC: {
        posicionGeo: null,
        dataGS: null,
        IdCanvas: null,
      },
      nameCanvas: null,
      clientInfo: {},
      folderId: "",
      editingItem: {},
      nameFolders: [],
      selectMBits: {
        posicionMBits: null,
        dataGS: null,
      },
      objectSelect: null,
      posicionDataLocation: null,
      ultimTypeB: null,
      count: 0,
      optionAsoci: null,
      selectTag: null,
      users: null,
      selectUser: null,
      filePocket: {
        id: '',
        text: ''
      },
      tempV: null,
    };
  },
  props: [ "typeDocument", "projects", "idSelect", "valueLoadP", "finishloadP", "permitEdit", 'dataMpBits', 'typeDirct', 'projectId', 'nameProject'],
  mounted: async function () {
    this.getDataCanvas()
    this.getUsers()
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    shareFile: async function(item){
      console.log(item)

      this.filePocket = {
        id: item.id,
        text: item.text
      }
      this.$root.$emit("bv::show::modal", `modal-shareUser_projectS`, "#focusThisOnClose")
    },
    sendPocket: async function(){
      var self = this
      var obj = {
        idProject: this.idSelect,
        idUserSend: this.currentUser.id,
        idUserRecive: this.selectUser,
        idCloudFile: this.filePocket.id,
        nameFile: this.filePocket.text,
      }
        
      await api
      .request( "post", "/setPocketFile", obj, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      })
      .then(async function (res) {
        console.log(res)
        self.$bvModal.hide('modal-shareUser_projectS')

        var obj_ = {
          idProject:  self.idSelect,
          idUserSend: self.currentUser.id,
          idUserRecive: self.selectUser,
          nameFile: self.filePocket.text, 
        }
        await api
        .request( "post", "/sendEmailNotification", obj_, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
        })
        .then(function (res) {
          console.log(res)
        })
        .catch(function(e){
          console.log(e)
        })
      })
      .catch(function (e) {
        console.log(e)
      });
      
    },
    getUsers: async function () {
      var self = this
      var clientsTmp = []
      this.users = []

      await api
      .request(
          "get",
          "/listUsers/" + self.currentUser.client_id + "/clients"
      )
      .then((data) => {
          clientsTmp = data.data.users;
      })
      .catch(function (e) {
          console.log(e);
      });

      clientsTmp.forEach((element) => {
        if (JSON.parse(localStorage.user).id != element.id){
          var tmp_obj = {
              value: element.id,
              text: element.name,
          };
          self.users.push(tmp_obj);
        } 
      });
      console.log(this.users)
    },
    verifyPermision: async function(){
      var self = this;
      var rulesPerson = null;
      var obj = {
        id: this.selectUser,
        key: 'user'
      };
      this.fileF = true;

      const config = {
          headers: { Authorization: `Bearer ${this.token}` },
      };
      await axios
      .post("https://microservices.safered.co/api/getRules", obj, config, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        if(res.data.rules.length > 0){
          rulesPerson = JSON.parse(res.data.rules[0].rule)
        }
      })
      .catch(function (e) {
        console.log(e)
      });
      
      var findV = []
      var data = this.$store.state.dataGlobal

      if(rulesPerson != null){
        rulesPerson.map(async function(element){
          var ind = await filtExistObj.findChildrenEquals(data, element, self.filePocket.id);
          if(ind == true){
            findV.push(ind)
          }
        })
      }

      this.tempV = findV
    }, 
    setTypeDocument(item, posicion, objFigure, typeCanvas){

      if(posicion == 0){
        this.optionAsoci = {
          editingItem: {
            id: item.id,
            text: item.text
          },
          posicion,
          idCanvas: objFigure.id,
          typeCanvas
        }
      }else if(posicion == 1){
        this.optionAsoci = {
          editingItem: {
            id: item.id,
            text: item.text
          },
          posicion,
          idCanvas: objFigure.idCanvas,
          id: objFigure.id,
          typeCanvas
        }
      }else if(posicion == 2){
        this.optionAsoci = {
          editingItem: {
            id: item.id,
            text: item.text
          },
          posicion,
          idCanvas: objFigure.idCanvas,
          id: objFigure.id,
          typeCanvas
        }
      }
        
      this.$root.$emit("bv::show::modal", `tagAsing-file`, "#focusThisOnClose")
    },
    addAsoci: async function(){
      var self = this;
      this.optionAsoci = {
        ...this.optionAsoci,
        idTag: self.selectTag,
      }
      console.log(this.optionAsoci)

      var obj = {};
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      if(this.optionAsoci.posicion == 0){
        obj = {
          arrayLink: [
            {   
              idTag: this.optionAsoci.idTag,
              idUser: this.currentUser.id,
              idProject: this.idSelect,
              idCanvas: this.optionAsoci.idCanvas,
              idGoogle: this.optionAsoci.editingItem.id,
              nameFile: this.optionAsoci.editingItem.text,
              type_canvas: this.optionAsoci.typeCanvas
            },
          ]
         }
      }else if(this.optionAsoci.posicion == 1){
        obj = {
          arrayLink: [
            {
              idTag: this.optionAsoci.idTag,
              idUser: this.currentUser.id,
              idProject: this.idSelect,
              idCanvas:  this.optionAsoci.idCanvas,
              idArea: this.optionAsoci.id,
              idGoogle: this.optionAsoci.editingItem.id,
              nameFile: this.optionAsoci.editingItem.text,
              type_canvas: this.optionAsoci.typeCanvas
            },
          ]
        }

      }else if(this.optionAsoci.posicion == 2){
        obj = {
          arrayLink: [
            {
              idTag: this.optionAsoci.idTag,
              idUser: this.currentUser.id,
              idProject: this.idSelect,
              idCanvas: this.optionAsoci.idCanvas,
              idPoint: this.optionAsoci.id,
              idGoogle: this.optionAsoci.editingItem.id,
              nameFile: this.optionAsoci.editingItem.text,
              type_canvas: this.optionAsoci.typeCanvas
            },
          ]
        }
      }

      await axios
        .post("https://microservices.safered.co/api/linkedFile", obj, config, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (){
          var count = self.$store.state.refreshDGeneral + 1;
          if(self.optionAsoci.typeCanvas == 0){
            self.$store.commit("setRefreshDrawGeo", count)
          }else{
            self.$store.commit("setRefreshImage", count)
          } 
          
          self.NotiAsoc_asoci = true;
        })  
        .catch(function(){
          self.NotiFail_asoci= true;
        });
    },
    getDataCanvas: async function(){
      var dataC = null
      var obj = {
        idProject: this.idSelect
      }
      await api
      .request(
          "post",
          "/getCanvasForIdProject", obj
      )
      .then((response) => {
          dataC = response.data
      }).catch((error) =>{
          console.log(error)
      });
      this.dataCanvas = dataC;
      this.opcionFiles[3].children = this.dataCanvas;
    },
    locationCanvas: function(opcionLocation){
      var self = this
      self.count++

      this.$store.commit("setFiltData", { count: self.count, 
      type_c: opcionLocation.type_canvas == 0 ? 2 : 1 });
      this.$store.commit("setLocationObject", opcionLocation.children[0].idPoint != undefined ? 
      {
        type_: "Point",
        idPoint: opcionLocation.children[0].idPoint
      } :
      {
        type_: "Polygon",
        idArea: opcionLocation.children[0].idArea
      });
      if(opcionLocation.type_canvas == 0){
        this.dataCanvas.C_GEO.map(function(data, index){
          if(data.id == opcionLocation.id)
            self.posicionDataLocation =  index;
        })
      }else{
        console.log(opcionLocation)
      }
      this.objectSelect = opcionLocation
      console.log(this.posicionDataLocation)
    },
    listUbicacion: function(index){
      document.querySelectorAll("[id='menuLocation']")[index].click();
    },
    asociFile: function(index){
      document.querySelectorAll("[id='menuAsoci']")[index].click();
    },
    detectFile: async function(item){
      var self = this
      if(self.opcionFiles.length <= 5){
        self.opcionFiles.push({
          text: 'Ubicacion canva',
          icon: 'fa-solid fa-check'
        })
      }
      self.statusLoad = true

      var obj = {
        idFile: item.id,
        idProject: this.idSelect
      }
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      if(!this.validFolder(item.text)){
        await axios
        .post("https://microservices.safered.co/api/GetLinkCanvas", obj, config)
        .then(function (data) {
          console.log(data)
          
          if(data.data.resp.length > 0){
            self.opcionFiles.push({
              text: 'Ubicacion canva',
              icon: 'fa-solid fa-check',
              children: []
            })
          }else{
            self.opcionFiles.splice(5, 2);
          }
          if(self.opcionFiles.length > 6){
            self.opcionFiles.splice(5, 1);
          }

          data.data.resp.map(function(obj){
            if(obj.idArea == null && obj.idPoint == null){
              self.opcionFiles[5].children.push({
                text:  obj.nameCanvas,
                id: obj.idCanvas,
                type_canvas: obj.type_canvas
              })
            }else{
              if(obj.idArea == null){
                self.opcionFiles[5].children.push({
                  text:  obj.nameCanvas,
                  id: obj.idCanvas,
                  type_canvas: obj.type_canvas,
                  children: [{
                    text: obj.NamePoint,
                    idPoint: obj.idPoint
                  }]
                })
              }else{
                self.opcionFiles[5].children.push({
                  text:  obj.nameCanvas,
                  id: obj.idCanvas,
                  type_canvas: obj.type_canvas,
                  children: [{
                    text: obj.NameArea,
                    idArea: obj.idArea
                  }]
                })
              }
            }
          })
        })
        .catch(function (e) {
          console.log(e.message);
          if(self.opcionFiles.length > 6){
            self.opcionFiles.splice(5, 1);
          }
        });
      }
      console.log(self.opcionFiles);
      self.statusLoad = false
    },
    joinCanvas: async function(index, item){
      var self = this
      
      if(this.geoMpB && this.copyDataGlobal.length < 2){
        this.$store.commit("setActiveDataGeoMpBits", index);
      }
      if(this.ultimType == 'Geo'){
        setTimeout(async () => {
          await self.verifyPosicion(item);
          self.selectC.dataGS = item;

          self.$store.commit("setPosicionselectC", await self.selectC.posicionGeo);
          self.$store.commit("setPosicionselectCE", await self.selectC.posicionGeo);
          self.$store.commit("setDataselectC", await self.selectC.dataGS);

          self.$store.commit("setPermissionC", await item.permissionsC);
          self.$store.commit("setNewVentana", item.id);
        }, "1000");
      }else{
        setTimeout(async () => {
          self.selectMBits.posicionMBits = index;

          this.$store.commit("setPosicionselectMb", await this.selectMBits.posicionMBits)
          this.$store.commit("setPosicionselectMbE", await this.selectMBits.posicionMBits)

          this.$store.commit("setPosicionselectCE", null)
          self.$store.commit("setNewVentana_Mpbits", item.id);
        }, "1000");
      }
      this.ultimType = this.ultimTypeB;
    },
    verifyPosicion: async function (dataG) {
      var self = this;
      if(dataG.opened){
        await this.dataGlobal[0].children.map(function (dataGeo, index) {
          if (dataGeo.id == dataG.id) {
            self.selectC.posicionGeo = index;
            self.selectC.IdCanvas = dataGeo.id;
          }
        });
      }else{
          self.selectC.posicionGeo = this.posicionDataLocation;
          self.selectC.IdCanvas = dataG.id;
      }
      
    },
    openMenu(index){
      const element =  this.$el.querySelectorAll('#actionMenu');
      console.log(element)
      for(let i = 0; i < element.length; i++){
        if(index == i){
          const event = new MouseEvent('click', {
            ctrlKey: true
          });
          element[i].dispatchEvent(event);
        }
      }
      this.isPopupVisible = false;
    }, 
    crearCarpeta(titleFolder) {
      this.$root.$emit("bv::show::modal", "CreateFolders", "#focusThisOnClose")
      this.titleFolder == titleFolder;    
    },
    cargarArchivo(editingItem){
      this.$root.$emit("bv::show::modal", "CreateUploadFile", "#focusThisOnClose");
      this.editingItem = editingItem
    },  
    crearCanva(titleFolder) {
      this.titleFolder = titleFolder
      this.$root.$emit("bv::show::modal", "CreateCanvas", "#focusThisOnClose");
    },
    openFolder(item, index){
      this.copyDataGlobal.push(this.dataGlobal);
      this.dataGlobal = [item];

      if(this.geoMpB && this.copyDataGlobal.length < 2){
        this.$store.commit("setActiveDataGeoMpBits", index);
      }
    },
    deleteCanva (idCanvaSelect, item) {
    this.$root.$emit("bv::show::modal", "DeleteMpBits", "#focusThisOnClose")
    this.idCanvaSelect = idCanvaSelect
    this.nameCanvas = item.text
    },
    backFolder: async function(){
      this.dataGlobal = await this.copyDataGlobal[this.copyDataGlobal.length - 1];
      this.copyDataGlobal.pop();
    },
    activeIcon(index){
      this.optionFile = false; 
      this.optionSelect = index;
    },
    desactiveIcon(){
      this.optionFile = true; 
      this.optionSelect = null;
    },
    showPopup(event) {
      this.popupTop = event.layerY;
      this.popupLeft = event.layerX;
      this.isPopupVisible = true;
    },
    hidePopup(){
      this.isPopupVisible = false;
    },
    handleScroll(){
      this.isPopupVisible = false;
    },
    downloadFile: async function(elementFile){
      var result = {}
      var obj = {
          idFile: elementFile,
          idProject: this.idSelect
      }
      const config = {
          headers: { Authorization: `Bearer ${this.token}` },
      };

      await axios
      .post("https://microservices.safered.co/api/downloadFile", obj, config, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => result = res.data)
      .catch((e) => console.log(e));

      const base64 = await fetch(result.FileB64)
      const blob = await base64.blob();

      var fileURL = window.URL.createObjectURL(blob);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", result.NameFile);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    deleteNode: async function (id, index) {
      var token = JSON.parse(localStorage.user).token;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let formData = new FormData();
      formData.append("fileId", id);
      formData.append("idProject", this.idSelect)
      formData.append("emailClient", this.currentUser.clientInfo.emailContacto)
      
      const data = {};
      formData.forEach((value, key) => (data[key] = value));
      // Log the data.
      await axios
          .post("https://microservices.safered.co/api/deleteComponent", formData, config, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
          })
          .then((res) => console.log(res))
          .catch((e) => console.log(e));
      
      await this.$store.commit("setIndexNodeDelete", index);
      this.circular = true;
      this.statusRefresh = "Delete"
    },
    getTreePathFromExtern: async function () {
      var obj = {
        idUser: JSON.parse(localStorage.user).id,
        idProject: this.idSelect,
        emailClient:  this.currentUser.clientInfo.emailContacto
      };
      console.log(obj)
      var token = JSON.parse(localStorage.user).token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var dt;
      var self = this 
      await axios
        .post("https://microservices.safered.co/api/getStorageProject", obj, config)
        .then(function (data) {
          console.log(data)
          self.folderId = data.data.GOOGLE_API_FOLDER_ID;
          dt = data.data.docProjects;
        })
        .catch(function (e) {
          console.log(e.message);
        });
        
        var array = [
          {
            "text": this.editingItem,
            "value": this.editingItem,
            "id": self.folderId,
            "icon": "",
            "opened": true,
            "parent": self.folderId,
            "children": JSON.parse(JSON.stringify(dt))
          }
        ]
        console.log(this.dataGlobal)
      if(this.dataGlobal[0].children.length > 0){
        var temp =  await filtExistObj.find(array[0]);
        console.log(temp)

        this.nameFolders = []
        temp.map(function(obj){
          if(obj.icon == '' && obj.text != undefined){
            self.nameFolders.push(obj);
          }
        })
        
        self.nameFolders.unshift(
          {
            "text": this.editingItem,
            "value": this.editingItem,
            "id": self.folderId,
            "icon": "",
            "opened": true,
            "parent": self.folderId,
            "children": []
          }
        )
        console.log(self.nameFolders)
        
      }

    },
    findData: async function(dataCanvas){
      var self = this
      var canvasSelect = null   

      await dataCanvas.map(function(data, index){
        if(self.objectSelect.id == data.id){
          canvasSelect = index
        }
      })
      if(canvasSelect != null)
        self.$store.commit("setActiveDataGeoMpBits", canvasSelect);
      
    }
  },

  watch: {
    "$store.state.select_diretory": async function (){
      this.circular = true;
    },
    "$store.state.directoryGeoMpBits_noview": async function(){
      this.findData(this.$store.state.directoryGeoMpBits_noview.data);
    },
    "$store.state.findDataCanvas_market": async function(){
      if(this.$store.state.findDataCanvas_market != null){
        this.ultimTypeB = this.ultimType
        this.ultimType = "Geo"
        this.joinCanvas(this.objectSelect, this.$store.state.findDataCanvas_market[0].children[0])
      }
    },
    "$store.state.directoryGeoMpBits": async function () {
      var self = this
      var dif = [];
      var type = this.$store.state.directoryGeoMpBits.typeCanvas;
     
      console.log(this.ultimType, type)
      if((this.dataGlobal == null || this.copyDataGlobal.length == 0) || (this.oldData == 1 || this.oldData == 0) 
        || type != this.ultimType){
        this.dataGlobal = [ 
          { "text": this.projects[0].name,
            "value": this.projects[0].name,
            "id": this.projects[0].id,
            "icon": "",
            "opened": true,
            "parent": this.projects[0].id,
            "children": this.$store.state.directoryGeoMpBits.data
          }
        ];
        this.$store.commit("setActiveDataGeoMpBits", null);
        this.copyDataGlobal = []
      }else{
        this.newDataGlobal = [
          { "text": this.projects[0].name,
            "value": this.projects[0].name,
            "id": this.projects[0].id,
            "icon": "",
            "opened": true,
            "parent": this.projects[0].id,
            "children": this.$store.state.directoryGeoMpBits.data
          }
        ]
        var temp = await filtExistObj.find(this.dataGlobal[0])
        var temp_new = await filtExistObj.find(this.newDataGlobal[0])

        temp_new.map(function(obj){
          var found = temp.find(element => element.text == obj.text);
          if(found == undefined){
            dif.push(obj)
          }
        });

        dif.map(function(obj){
          if(obj.parent == self.dataGlobal[0].id){
            self.dataGlobal[0].children.unshift(obj);
            if(self.copyDataGlobal[0].id == obj.parent){
              self.copyDataGlobal[0].children.unshift(obj);
            }
          }else if(self.copyDataGlobal[self.copyDataGlobal.length - 1].id == obj.parent){
            self.copyDataGlobal[self.copyDataGlobal.length].children.unshift(obj);
          }
        })
      }

      this.circular = false;
      this.geoMpB = true;
      this.ultimType = this.$store.state.directoryGeoMpBits.typeCanvas;
      this.ultimTypeB = this.ultimType
      this.oldData = 2;
    },
    "$store.state.dataGlobal": async function () {
      var self = this;
      var dif = [];
      var dif_delete = [];
      if(this.ultimType != 'Make'){
        this.copyDataGlobal =  []
      }

      this.ultimType = 'Make';
      this.ultimTypeB = this.ultimType

      if(this.dataGlobal == null || (this.oldData == 1 || this.oldData == 2) || this.copyDataGlobal < 1){
        this.dataGlobal = this.$store.state.dataGlobal;
       
      }else{
        this.newDataGlobal = this.$store.state.dataGlobal;
        var temp = await filtExistObj.find(this.dataGlobal[0])
        var temp_new = await filtExistObj.find(this.newDataGlobal[0])

        if(this.statusRefresh == "Delete"){
          temp.map(function(obj){
            var found = temp_new.find(element => element.text == obj.text);
            if(found == undefined){
              dif_delete.push(obj)
            }
          });

          dif_delete.map(function(obj_1){
            
            if(obj_1.parent == self.dataGlobal[0].id){
              
              var result = self.dataGlobal[0].children.filter(
                children => children.id != obj_1.id);
              self.dataGlobal[0].children = result;

              if(self.copyDataGlobal[0].id == obj_1.parent){

                result = self.copyDataGlobal[0].children.filter(
                  children => children.id != obj_1.id);
                self.copyDataGlobal[0].children = result;
              }
            }else if(self.copyDataGlobal[self.copyDataGlobal.length - 1].id == obj_1.parent){
              result = self.copyDataGlobal[self.copyDataGlobal.length - 1].children.filter(
                children => children.id != obj_1.id);
              self.copyDataGlobal[self.copyDataGlobal.length - 1].children = result;
            }
          })
        }else{
          temp_new.map(function(obj){
            var found = temp.find(element => element.text == obj.text);
            if(found == undefined){
              dif.push(obj)
            }
          });

          dif.map(function(obj){
            if(obj.parent == self.dataGlobal[0].id){
              self.dataGlobal[0].children.unshift(obj);
              if(self.copyDataGlobal[0].id == obj.parent){
                self.copyDataGlobal[0].children.unshift(obj);
              }
            }else if(self.copyDataGlobal[self.copyDataGlobal.length - 1].id == obj.parent){
              self.copyDataGlobal[self.copyDataGlobal.length].children.unshift(obj);
            }
          })
        }
      }
      this.circular = false;
      this.oldData = 0;
      this.geoMpB = false;

      
      
    },
    "$store.state.directoryAux": async function () {
      var self = this
      var dif = [];
      if(this.ultimType != 'Aux'){
        this.copyDataGlobal =  []
      }
      this.ultimType = 'Aux';
      this.ultimTypeB = this.ultimType

      if(this.dataGlobal == null || (this.oldData == 0 || this.oldData == 2) || this.copyDataGlobal < 1){
         this.dataGlobal = this.$store.state.directoryAux;
         
      }else{
        this.newDataGlobal = this.$store.state.directoryAux;

        var temp = await filtExistObj.find(this.dataGlobal[0])
        var temp_new = await filtExistObj.find(this.newDataGlobal[0])

        temp_new.map(function(obj){
          var found = temp.find(element => element.text == obj.text);
          if(found == undefined){
            dif.push(obj)
          }
        });

        dif.map(function(obj){
          if(obj.parent == self.dataGlobal[0].id){
            self.dataGlobal[0].children.unshift(obj);
            if(self.copyDataGlobal[0].id == obj.parent){
              self.copyDataGlobal[0].children.unshift(obj);
            }
          }else if(self.copyDataGlobal[self.copyDataGlobal.length - 1].id == obj.parent){
            self.copyDataGlobal[self.copyDataGlobal.length].children.unshift(obj);
          }
        })
      }
      this.circular = false;
      this.oldData = 1;
      this.geoMpB = false;
      await this.getTreePathFromExtern();
    },
    tempV: function(){
      this.fileF = this.tempV.length > 0 ? true : false;
    },
  },
};
</script>
<style>
  #custom_listGroup{
    padding: 0px;
    max-width: 270px;
    overflow-y: scroll;
    max-height: 250px;
  }

  #custom_listGroup::-webkit-scrollbar {
    width: 7px;
  }

  #custom_listGroup::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
  }


  #custom_listGroup .v-list-item.v-list-item--link.theme--light{
    padding: 0 8px;
  }

  #custom_listGroup .v-list-item.v-list-item--link.theme--light .v-list-item__content{
    padding: 3px 0;
  }

  #custom_listGroup .v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--light{
    min-height: 32px;
  }

  #custom_listGroup .v-list-item__icon.v-list-group__header__prepend-icon{

    margin: 4px 12px 0 0;
  }

  #custom_listGroup .v-list-item__title{
    font-size: 0.85rem;
  }

  #custom_listGroup .v-list-item.v-list-item--link.theme--light{
    min-height: 32px;
  }

  #custom_listGroup .v-list-item__subtitle{
    font-size: 0.75rem;
  }
  .back_folders{
    padding: 15px 0px 0px 10px!important;
    display: flex;
    justify-content: center; 
    align-items: center;
  }

  .back_folders i{
    cursor: pointer;
  }

  .change_vista{
    display: flex; 
    justify-content: center;
  }

  .change_vista i{
    cursor: pointer;
  }

  div.item-file{
    border-radius: 8px!important;
  }

  p.ex_text{
    font-size: 0.65rem!important;
    font-weight: 300!important;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }

  .contenedor_files{
    height: 490px;
    overflow: auto;

  }

  .contenedor_files::-webkit-scrollbar {
    width: 7px;
  }

  .contenedor_files::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
  }

  .contenedor_files_list{
    height: 443px;
    overflow: auto;
  }

  
  .contenedor_files_list::-webkit-scrollbar {
    width: 7px;
  }

  .contenedor_files_list::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
  }

  .popup {
    position: absolute;
    z-index: 100;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    width: 300px;
    max-width: 300px;
    border-radius: 4px;
  }

  div.item-file{
    border-radius: 8px!important;
  }

  p.ex_text{
    font-size: 0.65rem!important;
    font-weight: 300!important;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }

  p.text_list{
    font-size: 0.65rem!important;
    font-weight: 300!important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }

  .contenedor_files{
    height: 480px;
    overflow: auto;
  }

  .contenedor_files::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  .contenedor_files::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
  }

  .popup {
    position: absolute;
    z-index: 100;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    width: 300px;
    max-width: 300px;
    border-radius: 4px;
  }

  .popup .header {
    font-weight: bold;
    margin-bottom: 4px;
    text-transform: uppercase;
    padding: 0.55rem 0.80rem;
    border-bottom: 1px solid #e6e6e6;
    font-size: 15px;
  }

  .popup ul li {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 4px 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .popup ul li:hover {
    background-color: #f1f3f4;
  }

  .popup ul li i {
    margin-right: 8px;
  }

  .popup ul li span {
    flex: 1;
  }

  .popup ul li.disabled {
    opacity: 0.5;
    cursor: default;
  }

  .conteiner-circular{
    height: 480px;     
  }

  p.title_text_tabla{
    font-size: 0.80rem!important;
    font-weight: 300!important;
    margin-bottom: 5px!important;
    margin-top: 0px!important;
  }

</style>
