<template>
     <div v-if="currentCanvas != null">
        <v-row style="margin: 24px;">
            <v-card
                elevation="5"
                style="padding-right: 0px!important; 
                padding-left: 0px!important;"
            >
                <v-card-text  class="text-lg-right" style="padding: 7px 7px!important;" >
                    <v-btn color="#283271" style=" margin: -5px; text-align: right; color: white; cursor: auto;"> 
                        {{ this.currentCanvas.projectName }} / {{ this.currentCanvas.dataCanvasImage.canvas[currentCanvas.posicionMpBits].name }}
                    </v-btn>
                </v-card-text>

               <canvasImage style="margin: 1px" :key="currentCanvas.keyI" :dataPoints="currentCanvas.dataPointsMpBits[currentCanvas.posicionMpBits]"
                :polygonsLoadedMpBits="currentCanvas.polygonsLoadedMpBits[currentCanvas.posicionMpBits]"
                :dataI="currentCanvas.dataCanvasImage.canvas[currentCanvas.posicionMpBits]" 
                :idCanvas="currentCanvas.dataCanvasImage.canvas[currentCanvas.posicionMpBits].id"
                :referencia="'ref' + currentCanvas.posicionMpBits" :id="currentCanvas.posicionMpBits" 
                :rolesActualU="currentCanvas.rolesActualU" :typeDocument="currentCanvas.typeDocument" 
                :projectId="currentCanvas.idSelect" :folderGoogle="currentCanvas.folderGoogle" 
                :listCodeP="currentCanvas.listCodeP" :dataMap="currentCanvas.dataMap"
                :projectName="currentCanvas.projectName"
                :nameGeo="currentCanvas.dataCanvasImage.canvas[currentCanvas.posicionMpBits].name"
            
                 />
            </v-card>
        </v-row>
    </div>
</template>
<script>
import canvasImage from '@/components/layout/component_Main/body_main/canvas/CanvasImage.vue';
import filtExistObj from '../../../api/scriptE/index';

export default {
    name: "mainCanvasMpbits",
    components:{
        canvasImage
    },
    data(){
        return{
            currentCanvas: null,
        }
    },
    async mounted(){
        this.currentCanvas = JSON.parse(localStorage.canvas_info_mbits);
        var oldCurrentCanvas = this.currentCanvas;
        var self = this
        window.addEventListener('storage', function(e) {
            if(e.key === 'canvas_info_mbits') {
                self.currentCanvas = JSON.parse(e.newValue);
                console.log(filtExistObj.newChildren(oldCurrentCanvas.dataMap[0], self.currentCanvas.dataMap[0]))
                // if(self.currentCanvas == ){

                // }else{
                //     self.count++;
                //     self.currentCanvas.keyG = self.count;
                // }
            }
        });
    },
    watch:{
        
    }
}
</script>
<style>

</style>