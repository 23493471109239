<template>
  <v-main :style="
    viewprojectSelect || viewMyEmpresa
      ? `height: 585px; display: flex; flex-direction: column;
                margin-left: 25px; margin-right: 25px; padding: 0px 0px 0px;`
      : `display: flex; flex-direction: column;
                margin-left: 25px; margin-right: 25px; padding: 0px 0px 0px;`
  ">
    <projectsM v-if="viewprojectsM" :finishloadP="finishloadP" :valueLoadP="valueLoadP" :projects="projects" :businessList="businessList" />
    <v-card v-if="viewprojectSelect" style="padding: 15px; margin-top: 25px">
      <v-row>
        <v-col cols="4">
          <directorioGlobal v-if="activeDirectGlobal" :projectId="idSelect" :nameProject="projectName"
            :typeDocument="typeDocument" :typeCanvas="0" />
          <directorioAux v-if="activeDirectAux" :projectId="idSelect" :nameProject="projectName"
            :typeDocument="typeDocument" :idCanvas="null" :typeCanvas="1" />
          <containDirectory v-if="activeDirectVista || filtData.status" :style="filtData.status ? 'display: none;' : '' " :dataPointsMpBits="dataPointsMpBits" 
            :treeDirectories="treeDirectories" :polygonsLoadedMpBits="polygonsLoadedMpBits" 
            :tagsAndFiles="tagsAndFiles" :nameProject="projectName" :dataMpBits="dataMpBits"
            :threeGoogle="threeGoogle" :projectId="idSelect" :key="filtData.count" 
            :dataPoints="dataPoints" :polygonsLoaded="polygonsLoaded" 
            :dataGeo="dataGeo" :linesLoaded="linesLoaded" :searchData="filtData.status"
            :typeCanvas="filtData.type_c == null ? $store.state.select_diretory == 2 ? 'Geo':'MpBits' : filtData.type_c == 2 ? 'Geo':'MpBits' "
          />
        </v-col>
        <v-col cols="8">
          <projectSelect :typeDocument="typeDocument" :permitEdit="permitEdit" :dataGeo="dataGeo" :projects="projects" :idSelect="idSelect" />
        </v-col>
      </v-row>
    </v-card>

    <businessAdm v-if="viewEmpresaAdmin" />
    <projectAdm v-if="viewProyectAdmin" />
    <empresaB v-if="viewMyEmpresa" />
    <usersB v-if="viewUserAdmin" :projects="projects" />
    <groupAdm v-if="viewGroupAdmin" :projects="projects" />
    <clientsB v-if="viewclientesAdmin" />
    <createclientM v-if="viewCreateClients" />

    <!-- <v-card :key="key" v-if="viewcanvasG" style="padding: 15px; margin-top: 25px; margin-bottom: 35px">
      <v-row>
        <splitpanes class="default-theme" @resize="paneSize = $event[0].size">
          <pane min-size="16" :size="paneSize" :max-size="paneSize == 16 ? 16 : 100" style="background-color: white">
            <v-col v-if="paneSize >= 17">
              <directorioAux :projectId="idSelect" :nameProject="projectName" :typeDocument="typeDocument"
                :idCanvas="dataGeo[0].IdCanvas" :typeCanvas="0" v-if="activeDirectAux" />
              <directorioGlobal :projectId="idSelect" :nameProject="projectName" :typeDocument="typeDocument"
                :idCanvas="dataGeo[0].IdCanvas" :typeCanvas="0" v-if="activeDirectGlobal" />
              <directorioVista :key="
                statusGeoR
                  ? keyG + '-' + dataGeo[posicionGeo].IdCanvas
                  : keyD + '-' + dataGeo[posicionGeo].IdCanvas
              " :treeDirectoriesIndex="dataPoints[posicionGeo]" :typeCanvas="0"
                :polygonsLoadedIndex="polygonsLoaded[posicionGeo]" :treeDirectories="treeDirectories"
                :polygonsLoaded="polygonsLoaded" :tagsAndFiles="tagsAndFiles" :nameProject="projectName"
                :dataVista="dataGeo[posicionGeo]" :threeGoogle="threeGoogle" :linesLoaded="linesLoaded[posicionGeo]"
                :style="
                  activeDirectVista
                    ? 'display: flex!important;'
                    : 'display: none!important;'
                "
                :projectId="idSelect" 
                />
            </v-col>
            <v-col v-if="paneSize < 17" style="
                display: flex;
                margin-top: 160px;
                justify-content: center;
                flex-direction: column;
              ">
              <v-btn small color="#283271" dark style="font-size: 10px" @click="activePane('Vista')">
                Directorio Canvas
              </v-btn>
              <v-btn small color="#283271" dark style="font-size: 10px; margin-top: 10px" @click="activePane('Global')">
                Directorio Global
              </v-btn>
              <v-btn small color="#283271" dark style="font-size: 10px; margin-top: 10px"
                @click="activePane('Personal')">
                Directorio Personalizado
              </v-btn>
            </v-col>
          </pane>
          <pane style="background-color: white">
            <v-col>
              <canvasGeo :principal="posicionGeo" :key="keyG + '-' + dataGeo[posicionGeo].IdCanvas"
                :dataPoints="dataPoints[posicionGeo]" :polygonsLoaded="polygonsLoaded[posicionGeo]"
                :linesLoaded="linesLoaded[posicionGeo]" :idCanvas="dataGeo[posicionGeo].IdCanvas" :dateFilt="dateFilt"
                :nameCanvas="dataGeo[posicionGeo].Name" :refM="dataG.Name.replace(/\s+/g, '') + '-' + posicionGeo"
                :refP="dataG.Name.replace(/\s+/g, '') + '-' + posicionGeo + 'P'" :typeDocument="typeDocument"
                :projectId="idSelect" :rolesActualU="rolesActualU" :folderGoogle="folderGoogle" :listCodeP="listCodeP"
                :dataObjectP="dataObjectP" :idPrincipal="dataGeo[0].IdCanvas" :dataLinks="dataLinks"/>
            </v-col>
          </pane>
        </splitpanes>
      </v-row>
      <v-row style="margin-top: 0px !important">
        <v-col style="
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding-top: 5px;
          ">
          <v-btn v-if="permitEdit" class="mx-2" fab dark small color="red" @click="showMDeleteCanvasG = true"
            v-b-popover.hover="" title="Eliminar vista" style="justify-content: center;">
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <modalDeleteCG :showMDeleteCanvasG="showMDeleteCanvasG" :dataDrawLienzoG="dataDrawLienzoG" />
        </v-col>
      </v-row>
    </v-card>
    <v-card :key="key2" v-if="viewcanvasMb" style="padding: 15px; margin-bottom: 35px; margin-top: 25px">
      <v-row>
        <splitpanes class="default-theme" @resize="paneSize = $event[0].size">
          <pane min-size="16" :size="paneSize" :max-size="paneSize == 16 ? 16 : 100" style="background-color: white">
            <v-col v-if="paneSize >= 17">
              <directorioAux :projectId="idSelect" :nameProject="projectName" :typeDocument="typeDocument"
                :idCanvas="dataCanvasImage.canvas[posicionMpBits].id" :typeCanvas="1" v-if="activeDirectAux" />
              <directorioGlobal :projectId="idSelect" :nameProject="projectName" :typeDocument="typeDocument"
                :idCanvas="dataCanvasImage.canvas[posicionMpBits].id" :typeCanvas="1" v-if="activeDirectGlobal" />
              <directorioVista :key="keyI" :treeDirectoriesIndex="dataPointsMpBits[posicionMpBits]"
                :polygonsLoadedIndex="polygonsLoadedMpBits[posicionMpBits]" :treeDirectories="treeDirectories"
                :polygonsLoaded="polygonsLoadedMpBits" :tagsAndFiles="tagsAndFiles" :nameProject="projectName"
                :dataVista="dataMpBits[posicionMpBits]" :typeCanvas="1" :threeGoogle="threeGoogle" :style="
                  activeDirectVista
                    ? 'display: flex!important;'
                    : 'display: none!important;'
                " 
                :projectId="idSelect"
                />
            </v-col>
            <v-col v-if="paneSize < 17" style="
                display: flex;
                margin-top: 160px;
                justify-content: center;
                flex-direction: column;
              ">
              <v-btn small color="#283271" dark style="font-size: 10px" @click="activePane('Vista')">
                Directorio Canvas
              </v-btn>
              <v-btn small color="#283271" dark style="font-size: 10px; margin-top: 10px" @click="activePane('Global')">
                Directorio Global
              </v-btn>
              <v-btn small color="#283271" dark style="font-size: 10px; margin-top: 10px"
                @click="activePane('Personal')">
                Directorio Personalizado
              </v-btn>
            </v-col>
          </pane>
          <pane style="background-color: white">
            <v-col>
              <canvasImage :key="keyI" :dataPoints="dataPointsMpBits[posicionMpBits]"
                :polygonsLoadedMpBits="polygonsLoadedMpBits[posicionMpBits]"
                :dataI="dataCanvasImage.canvas[posicionMpBits]" :idCanvas="dataCanvasImage.canvas[posicionMpBits].id"
                :referencia="'ref' + posicionMpBits" :id="posicionMpBits" :rolesActualU="rolesActualU"
                :typeDocument="typeDocument" :projectId="idSelect" :folderGoogle="folderGoogle"
                :listCodeP="listCodeP" />
            </v-col>
          </pane>
        </splitpanes>
      </v-row>
      <v-row style="margin-top: 0px !important">
        <v-col>
          <btnAction />
        </v-col>
        <v-col style="
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding-top: 5px;
          ">
          <v-btn v-if="permitEdit" class="mx-2" fab dark small color="red" @click="showMDeleteCanvasI = true"
             v-b-popover.hover="" title="Eliminar vista">
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <modalDeleteCI :showMDeleteCanvasI="showMDeleteCanvasI" :dataDrawLienzoI="dataDrawLienzoI" />
        </v-col>
      </v-row>
    </v-card> -->
    <buscarF v-if="rolesActualU != null" :dataPointsMpBits="dataPointsMpBits" :polygonsLoadedMpBits="polygonsLoadedMpBits" 
    :tagsAndFiles="tagsAndFiles" :projectName="projectName" :dataMpBits="dataMpBits" :threeGoogle="threeGoogle" 
    :treeDirectories="treeDirectories" :dataPoints="dataPoints" :polygonsLoaded="polygonsLoaded" 
    :dataGeo="dataGeo" :linesLoaded="linesLoaded" :rolesActualU="rolesActualU" 
    :dateFilt="dateFilt" :typeDocument="typeDocument"/>
  </v-main>
</template>
<script>
// import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import projectsM from "./projects/ProjectsM";
import projectSelect from "./projects/ProjectSelect";
// import canvasGeo from "./canvas/CanvasGeo";
// import canvasImage from "./canvas/CanvasImage.vue";
import directorioGlobal from "./directories/DirectoriesMake.vue";
// import directorioVista from "./directories/Directories.vue";
import directorioAux from "./directories/DirectoriesAux.vue";
import buscarF from "../body_main/buscar/BuscarFile.vue";
// import modalDeleteCG from "../../../modals/ModalDeleteCanvasG.vue";
// import modalDeleteCI from "../../../modals/ModalDeleteCanvasI.vue";
import projectAdm from "../body_main/administracion/proyectos/ProjectAdmin.vue";
import empresaB from "../body_main/administracion/miempresa/Empresa.vue";
import usersB from "../body_main/administracion/usuarios/Users.vue";
import groupAdm from "../body_main/administracion/group/GroupAdmin.vue";
import clientsB from "../body_main/administracion/clientes/Clientes.vue";
import createclientM from "../body_main/administracion/clientes/forms/CreateClientForm.vue";
import businessAdm from '../body_main/administracion/business/BusinessAdmin.vue';
import containDirectory from './projects/ContainDirectory.vue';

export default {
  name: "bodyMain",
  components: {
    projectsM,
    projectSelect,
    directorioGlobal,
    directorioAux,
    buscarF,
    projectAdm,
    empresaB,
    usersB,
    groupAdm,
    clientsB,
    businessAdm,
    createclientM,
    containDirectory,
  },
  data() {
    return {
      filtData: {
        count: 0,
        status: false,
        type_c: null
      },
      viewprojectsM: true,
      viewprojectSelect: false,
      viewcanvasG: false,
      viewcanvasMb: false,
      idSelect: null,
      posicionGeo: null,
      posicionMpBits: null,
      dataG: null,
      activeDirectGlobal: true,
      activeDirectVista: false,
      activeDirectAux: false,
      projectName: "",
      key: 0,
      key2: 0,
      keyG: 0,
      keyD: 0,
      keyI: 0,
      statusGeoR: false,
      showMDeleteCanvasG: false,
      showMDeleteCanvasI: false,
      viewProyectAdmin: false,
      viewMyEmpresa: false,
      viewUserAdmin: false,
      viewGroupAdmin: false,
      viewclientesAdmin: false,
      viewCreateClients: false,
      viewEmpresaAdmin: false,
      permitEdit: false,
      init: false,
      paneSize: 25,
      paneSizeSelect: 40,
      ventanasOpen: []
    };
  },
  props: [
    "projects",
    "dataGeo",
    "typeDocument",
    "dataPoints",
    "polygonsLoaded",
    "linesLoaded",
    "dateFilt",
    "dataPointsMpBits",
    "polygonsLoadedMpBits",
    "dataCanvasImage",
    "treeDirectories",
    "tagsAndFiles",
    "dataMpBits",
    "dataDrawLienzoG",
    "dataDrawLienzoI",
    "changeGeo",
    "changeData",
    "changeMpbits",
    "rolesActualU",
    "threeGoogle",
    "folderGoogle",
    "itemsGoogle",
    "listCodeP",
    "finishloadP",
    "valueLoadP",
    "dataObjectP",
    "dataLinks",
    "businessList"
  ],
  mounted: async function () {
    console.log(this.dataLinks)
   },
  methods: {
    activePane(type) {

      this.paneSize = 25;
      this.paneSizeSelect = 25;
      if (type == 'Global') {
        
        this.activeDirectGlobal = true;
        this.activeDirectAux = false;
        this.activeDirectVista = false;
        console.log(this.activeDirectGlobal)
      } else if (type == 'Personal') {
        this.activeDirectAux = true;
        this.activeDirectGlobal = false;
        this.activeDirectVista = false;
      } else if (type == 'Vista') {
        this.activeDirectVista = true;
        this.activeDirectAux = false;
        this.activeDirectGlobal = false;
      }
    },
    permitSection: async function () {
      var groups = JSON.parse(window.localStorage.user).groups;
      var statusE = false;
      var self = this;

      if (groups != "NaN") {
        groups.map(function (arr) {
          self.rolesActualU.map(function (arr2) {
            if (arr2.project_id == self.idSelect) {
              if (arr2.role_id == 2) {
                statusE = false;
              } else if (arr2.role_id == 4) {
                statusE = true;
              }
            } else {
              if (arr.idProject == self.idSelect) {
                if (arr.idRole == 2) {
                  statusE = false;
                } else if (arr.idRole) {
                  statusE = true;
                }
              }
            }
          });
        });
      } else {
        self.rolesActualU.map(function (arr) {
          if (arr.project_id == self.idSelect) {
            if (arr.role_id == 2) {
              statusE = false;
            } else if (arr.role_id == 4) {
              statusE = true;
            }
          }
        });
      }

      if (JSON.parse(window.localStorage.user).isAdmin == true || statusE) {
        this.permitEdit = true;
      } else {
        this.permitEdit = false;
      }
    },
    setNewVentana: async function(){
      var self = this;
      localStorage.removeItem("canvas_info");
      
      var current_canvas = {
        keyG: this.keyG,
        posicionGeo:  this.posicionGeo, 
        dataPoints: this.dataPoints, 
        polygonsLoaded: this.polygonsLoaded,
        linesLoaded: this.linesLoaded,
        dateFilt: this.dateFilt,
        typeDocument: this.typeDocument,
        idSelect: this.idSelect, 
        rolesActualU: this.rolesActualU,
        folderGoogle: this.folderGoogle, 
        listCodeP: this.listCodeP,
        dataObjectP: this.dataObjectP, 
        dataLinks: this.dataLinks,
        idCanva: this.$store.state.newVentana,
        dataGeo: this.dataGeo,
        permissionC: this.permitEdit,
        projectName: this.$store.state.dataInit.projectName,
        dataMap: [ 
          { "text": this.projects[0].name,
            "value": this.projects[0].name,
            "id": this.projects[0].id,
            "icon": "",
            "opened": true,
            "parent": this.projects[0].id,
            "children": !this.filtData.status ? [this.$store.state.directoryGeoMpBits.data[this.$store.state.activeDataGeoMpBits]] : [this.$store.state.findDataCanvas_market[0].children[this.$store.state.activeDataGeoMpBits]]
          }
        ],
        dataLocation: this.$store.state.locationObject

      }
      window.localStorage.setItem("canvas_info", JSON.stringify(current_canvas));
      let routeData = this.$router.resolve({
        path:
          `/app/mapGeo`,
      });
      let newVentana = window.open(routeData.href, "_blank");
      self.ventanasOpen.push(newVentana)

      async function cerrarPestana(index) {
        await self.ventanasOpen[index].close();
        self.ventanasOpen.splice(index, 1);
      }

      this.$store.commit("setFiltData", null);
      this.$store.commit("setLocationObject", null);
      self.$store.commit("setActiveDataGeoMpBits", null);
      self.$store.commit("setNewVentana", null);
      if(self.ventanasOpen.length > 1)
        await cerrarPestana(0);
    },
    refreshVentana: async function(){
      localStorage.removeItem("canvas_info");
      
      var current_canvas = {
        keyG: this.keyG,
        posicionGeo:  this.posicionGeo, 
        dataPoints: this.dataPoints, 
        polygonsLoaded: this.polygonsLoaded,
        linesLoaded: this.linesLoaded,
        dateFilt: this.dateFilt,
        typeDocument: this.typeDocument,
        idSelect: this.idSelect, 
        rolesActualU: this.rolesActualU,
        folderGoogle: this.folderGoogle, 
        listCodeP: this.listCodeP,
        dataObjectP: this.dataObjectP, 
        dataLinks: this.dataLinks,
        idCanva: this.$store.state.newVentana,
        dataGeo: this.dataGeo,
        permissionC: this.permitEdit,
        projectName: this.$store.state.dataInit.projectName,
        dataMap: [ 
          { "text": this.projects[0].name,
            "value": this.projects[0].name,
            "id": this.projects[0].id,
            "icon": "",
            "opened": true,
            "parent": this.projects[0].id,
            "children": [this.$store.state.directoryGeoMpBits.data[this.$store.state.activeDataGeoMpBits]]
          }
        ],

      }
      window.localStorage.setItem("canvas_info", JSON.stringify(current_canvas));
    },
    setNewVentana_Mpbits: async function(){
      var self = this;
      localStorage.removeItem("canvas_info_mbits");

      var current_canvas = {
        keyI: this.keyI,
        dataPointsMpBits: this.dataPointsMpBits,
        posicionMpBits: this.posicionMpBits,
        polygonsLoadedMpBits: this.polygonsLoadedMpBits,
        dataCanvasImage: this.dataCanvasImage,
        rolesActualU: this.rolesActualU,
        typeDocument: this.typeDocument,
        idSelect: this.idSelect,
        folderGoogle: this.folderGoogle,
        listCodeP: this.listCodeP,
        idCanva: this.$store.state.newVentana_Mpbits,
        permissionC: this.permitEdit,
        projectName: this.$store.state.dataInit.projectName,
        dataMap: [ 
          { "text": this.projects[0].name,
            "value": this.projects[0].name,
            "id": this.projects[0].id,
            "icon": "",
            "opened": true,
            "parent": this.projects[0].id,
            "children": [this.$store.state.directoryGeoMpBits.data[this.$store.state.activeDataGeoMpBits]]
          }
        ],

      }
      window.localStorage.setItem("canvas_info_mbits", JSON.stringify(current_canvas));
      let routeData = this.$router.resolve({
        path:
          `/app/mapBits`,
      });
      let newVentana = window.open(routeData.href, "_blank");
      self.ventanasOpen.push(newVentana)

      async function cerrarPestana(index) {
        await self.ventanasOpen[index].close();
        self.ventanasOpen.splice(index, 1);
      }

      if(self.ventanasOpen.length > 1)
        await cerrarPestana(0);
    },
  },
  watch: {
    "$store.state.filtData": function(){
      console.log(this.$store.state.filtData)
      if(this.$store.state.filtData != null){
        this.filtData = {
          count: this.$store.state.filtData.count++,
          type_c: this.$store.state.filtData.type_c,
          status: true
        }
      }else{
        this.filtData = {
          count: 0,
          type_c: null,
          status: false
        }
      }
    },
    "$store.state.newVentana": async function(){
      if(this.$store.state.newVentana != null){
        this.setNewVentana()
      }
    },
    "$store.state.newVentana_Mpbits": async function(){
      this.setNewVentana_Mpbits()
    },
    viewprojectSelect: function () {
      if(this.viewprojectSelect){
        this.activeDirectGlobal = true;
        this.activeDirectVista = false;
        this.activeDirectAux = false;
      }
    },
    viewcanvasG: function(){
      if(this.viewcanvasG || this.viewcanvasMb){
        this.activeDirectVista = true;
        this.activeDirectGlobal = false;
        this.activeDirectAux = false;
      }
    },
    "$store.state.dataInit": function () {
      this.viewprojectsM = this.$store.state.dataInit.projectsM;
      this.viewprojectSelect = this.$store.state.dataInit.projectSelect;
      this.idSelect = this.$store.state.dataInit.idProject;
      this.projectName = this.$store.state.dataInit.projectName;
      this.permitSection();
      this.init = true;
    },
    "$store.state.restoreInit": function () {
      this.viewprojectsM = this.$store.state.restoreInit.projectsM;
      this.viewprojectSelect = this.$store.state.restoreInit.projectSelect;
      this.viewcanvasG = false;
      this.viewcanvasMb = false;
      this.projectName = "";
      if (this.permitEdit) {
        this.permitSection();
        this.init = false;
      }
    },
    "$store.state.posicionselectC": async function () {
      this.posicionGeo = await this.$store.state.posicionselectC;

      this.$forceUpdate();
      this.key++;
    },
    "$store.state.dataselectC": function () {
      this.dataG = this.$store.state.dataselectC;
    },

    "$store.state.select_diretory": function () {
      var obj = this.$store.state.select_diretory;
      if(obj != null){
        if(obj == 0){
          this.activeDirectGlobal = true; 
          this.activeDirectAux = false;
          this.activeDirectVista = false; 
        } else if(obj == 1){
          this.activeDirectAux = true;
          this.activeDirectGlobal = false;
          this.activeDirectVista = false; 
        } else {
          this.activeDirectVista = true;
          this.activeDirectGlobal = false;
          this.activeDirectAux = false; 
        }
      }else{
        this.activeDirectGlobal = false; 
        this.activeDirectAux = false;
        this.activeDirectVista = false;
      }
    },
    "$store.state.posicionselectMb": async function () {
      this.posicionMpBits = await this.$store.state.posicionselectMb;

      this.$forceUpdate();
      this.key2++;
    },
    "$store.state.selectionCanvas": function () {
      this.viewprojectsM = this.$store.state.selectionCanvas.viewprojectsM;
      this.viewprojectSelect =
      this.$store.state.selectionCanvas.viewprojectSelect;
      this.viewcanvasG = this.$store.state.selectionCanvas.viewcanvasG;
      this.viewcanvasMb = this.$store.state.selectionCanvas.viewcanvasMb;
      this.viewProyectAdmin = false;
      this.viewMyEmpresa = false;
      this.viewUserAdmin = false;
      this.viewGroupAdmin = false;
      this.viewclientesAdmin = false;
      this.viewCreateClients = false;
      this.viewEmpresaAdmin = false;
      this.permitSection();
    },
    "$store.state.selectionCanvasMBits": function () {
      this.viewprojectsM = this.$store.state.selectionCanvasMBits.viewprojectsM;
      this.viewprojectSelect =
      this.$store.state.selectionCanvasMBits.viewprojectSelect;
      this.viewcanvasG = this.$store.state.selectionCanvasMBits.viewcanvasG;
      this.viewcanvasMb = this.$store.state.selectionCanvasMBits.viewcanvasMb;
      this.viewProyectAdmin = false;
      this.viewMyEmpresa = false;
      this.viewUserAdmin = false;
      this.viewGroupAdmin = false;
      this.viewclientesAdmin = false;
      this.viewCreateClients = false;
      this.viewEmpresaAdmin = false,
      this.permitSection();
    },
    showMDeleteCanvasG: function () {
      if (this.showMDeleteCanvasG == true) {
        this.$store.commit("setShowMDeleteCanvasG", true);
      }
    },
    "$store.state.showMDeleteCanvasG": function () {
      if (this.$store.state.showMDeleteCanvasG == false) {
        this.showMDeleteCanvasG = this.$store.state.showMDeleteCanvasG;
      }
    },
    showMDeleteCanvasI: function () {
      if (this.showMDeleteCanvasI == true) {
        this.$store.commit("setShowMDeleteCanvasI", true);
      }
    },
    "$store.state.showMDeleteCanvasI": function () {
      if (this.$store.state.showMDeleteCanvasI == false) {
        this.showMDeleteCanvasI = this.$store.state.showMDeleteCanvasI;
      }
    },
    "$store.state.proyectAdmin": function () {
      if (this.$store.state.proyectAdmin == true) {
        this.viewProyectAdmin = this.$store.state.proyectAdmin;
        this.viewMyEmpresa = false;
        this.viewUserAdmin = false;
        this.viewGroupAdmin = false;
        this.viewprojectsM = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewEmpresaAdmin = false;
      } else {
        this.viewProyectAdmin = this.$store.state.proyectAdmin;
      }
    },
    "$store.state.bussinesAdmin": function(){
      if (this.$store.state.bussinesAdmin == true) {
        this.viewEmpresaAdmin = this.$store.state.bussinesAdmin;
        this.viewUserAdmin = false;
        this.viewProyectAdmin = false;
        this.viewprojectsM = false;
        this.viewGroupAdmin = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewMyEmpresa = false;
      } else {
        this.viewEmpresaAdmin = this.$store.state.bussinesAdmin;
      }
    },
    "$store.state.empresaAdmin": function () {
      if (this.$store.state.empresaAdmin == true) {
        this.viewMyEmpresa = this.$store.state.empresaAdmin;
        this.viewUserAdmin = false;
        this.viewProyectAdmin = false;
        this.viewprojectsM = false;
        this.viewGroupAdmin = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewEmpresaAdmin = false;
      } else {
        this.viewMyEmpresa = this.$store.state.empresaAdmin;
      }
    },
    "$store.state.userAdmin": function () {
      if (this.$store.state.userAdmin == true) {
        this.viewUserAdmin = this.$store.state.userAdmin;
        this.viewProyectAdmin = false;
        this.viewMyEmpresa = false;
        this.viewGroupAdmin = false;
        this.viewprojectsM = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewEmpresaAdmin = false;
      } else {
        this.viewUserAdmin = this.$store.state.userAdmin;
      }
    },
    "$store.state.groupAdmin": function () {
      if (this.$store.state.groupAdmin == true) {
        this.viewGroupAdmin = this.$store.state.groupAdmin;
        this.viewUserAdmin = false;
        this.viewProyectAdmin = false;
        this.viewMyEmpresa = false;
        this.viewprojectsM = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewEmpresaAdmin = false;
      } else {
        this.viewGroupAdmin = this.$store.state.groupAdmin;
      }
    },
    "$store.state.clientesAdmin": function () {
      if (this.$store.state.clientesAdmin == true) {
        this.viewclientesAdmin = this.$store.state.clientesAdmin;
        this.viewprojectsM = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewEmpresaAdmin = false;
      } else {
        this.viewclientesAdmin = this.$store.state.clientesAdmin;
      }
    },
    "$store.state.createClients": function () {
      if (this.$store.state.createClients == true) {
        this.viewCreateClients = this.$store.state.createClients;
        this.viewclientesAdmin = false;
        this.viewprojectsM = false;
        this.viewprojectSelect = false;
        this.viewcanvasG = false;
        this.viewcanvasMb = false;
        this.viewEmpresaAdmin = false,
        this.$store.commit("setClientesAdmin", false);
      } else {
        this.viewCreateClients = this.$store.state.createClients;
      }
    },
    changeGeo: function () {
      this.keyG++;
      this.statusGeoR = true;
      this.refreshVentana();
    },
    changeData: function () {
      this.keyD++;
      this.statusGeoR = false;
      this.refreshVentana();
    },
    changeMpbits: function () {
      this.keyI++;
    },
    permitEdit: function () {
      console.log(this.permitEdit);
      this.$store.commit("setPermission", this.permitEdit);
    },
    init: function () {
      if(!this.init) {
        console.log(this.init);
        this.$store.commit("setPermission", 0);
        this.permitEdit = !this.permitEdit;
      }
    },
  },
};
</script>
<style>
.splitpanes.default-theme .splitpanes__splitter {
  background-color: rgb(192, 196, 200) !important;
}

.v-pane-custom .v-pane .content {
  background: #fff;
}

.v-pane-custom .content .innerContent {
  padding: 20px;
  line-height: 1.5;
}

.paneNested>.content>.innerContent {
  padding: 0;
}
</style>
