<template>
    <b-container id="ChardOrigin" class="container_prop">

        <div v-if="this.loadingMain && !this.errorAlert">
            <b-container 
                style="display: flex; background: transparent;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-top: 70px;
                margin-bottom: 80px;">
                <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="6" style="display: flex; justify-content: center;">
                        <v-card style="height: 410px; width: 310px;">
                            <v-col style="display: flex; justify-content: center;">
                                <img
                                height="280"
                                width="300"
                                :src="logosafenet"
                                alt="Logo SAFE NET"
                                />
                            </v-col>
                            <v-col style="display: flex; flex-direction: column; justify-content: center;">
                                <clip-loader
                                :loading="loadingMain"
                                color="#1e88e5"
                                size="50px"
                                ></clip-loader>
                            </v-col>
                        </v-card>
                    </v-col>
                    <v-col cols="3"></v-col>
                </v-row>
            </b-container>
        </div>

        <div v-if="this.errorAlert">
            <b-container style="display: flex; background: transparent;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-top: 70px;
                margin-bottom: 80px;">
                <b-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="6" style="display: flex; justify-content: center;">
                        <v-card style="height: 410px; width: 310px;">
                            <v-col style="display: flex; justify-content: center;">
                                <img
                                height="280"
                                width="300"
                                :src="logosafenet"
                                alt="Logo SAFE NET"
                                />
                                </v-col>
                            <v-col>
                                <b-row style="justify-content: center;">
                                    <b-col sm="3">
                                        <v-btn color="warning" fab dark>
                                            <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
                                        </v-btn>
                                    </b-col>
                                    <b-col sm="9" v-if="message == false">
                                        <h5>
                                            Este recurso no se encuentra disponible para el usuario.
                                        </h5>
                                    </b-col>
                                    <b-col sm="9" v-if="message == true">
                                        <h5>
                                            El sensor no tiene constantes registradas.
                                        </h5>
                                    </b-col>
                                </b-row>
                            </v-col>
                        </v-card>
                    </v-col>
                    <v-col cols="3"></v-col>   
                </b-row>
            </b-container>
        </div>

        <div class="container" v-if="!this.loadingMain && !this.errorAlert">
            <b-row class="windows">
                <b-col sm="2" class="column_icon">
                    <i class="fa fa-bar-chart fa-lg info" aria-hidden="true"></i>
                </b-col >
                <b-col sm="8" class="column_icon_1">
                    <h6 style="color: white;">
                        Chart - {{nameChart}}
                    </h6>
                </b-col>
                <b-col sm="2" class="column_icon_2">
                    <b-row >
                    </b-row>
                </b-col>
            </b-row>

            <div>
                <b-tabs id="tab">
                    <b-tab class="tab_prop" title="Archivo" active>
                        <div class="menu_windows">
                            <div class="group">
                                <button class="ribbon-button" v-b-modal.modal-4 @click="clickVerifyChart();">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/impresora.png"/>
                                    </span>
                                    <span class="caption">Imprimir</span>
                                </button>
                                <!-- <button disabled class="ribbon-button">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/guardar-datos.png"/>
                                    </span>
                                    <span class="caption">Guardar</span>
                                </button> -->
                                <!-- <button class="ribbon-button" target="_self">
                                    <span class="icon">
                                        <a :href="'/dashboard/admin/projects/'+idProject">
                                            <img src="@/assets/img/chart/salida.png"/>
                                        </a>
                                    </span>
                                    <span class="caption">Exit</span>
                                </button> -->
                                <span class="title">
                                    <p class="text">Opciones Principales</p>
                                </span>
                            </div>
                            <v-col v-if="cargueB == true">
                                <v-row>
                                    <v-col md="8">
                                    </v-col>
                                    <v-col v-if="updateN == true" style="display: flex; align-items: center;" md="2">
                                        <v-row style="background: #46C93A; border-radius: 10px;">
                                            <p style="color: white; text-align: center;" class="text_gauge"><br/>
                                                Fecha última actualización: 
                                                <br/>
                                                {{fechaA}}
                                                <br/>
                                                <a>
                                                    <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                                                </a>
                                            </p>
                                        </v-row>
                                    </v-col>
                                    <v-col md="2" v-if="needleValue > 0" style="display: flex; flex-direction: column; align-items: center;">
                                        <vue-gauge :needleValue="needleValue" :options="options"></vue-gauge>
                                        <p class="text_gauge" style="text-align: center;">
                                            {{valorBattry}}
                                            <br/>
                                            Nivel de batería del datalogger
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </b-tab>
                    <b-tab v-if="permitEdit == true" class="tab_prop" :title="'Ajustes'">
                        <div class="menu_windows">
                            <div class="group">
                                <button class="ribbon-button"  v-b-modal.gen_alert @click="llenarVar">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/alarma-encendida.png"/>
                                    </span>
                                    <span class="caption">Configurar Alarma</span>
                                </button>
                                <!-- <button disabled class="ribbon-button">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/configuraciones.png"/>
                                    </span>
                                    <span class="caption">Activar Alarmas</span>
                                </button> -->
                                <span class="title">
                                    <p class="text">Monitoreo</p>
                                </span>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab disabled class="tab_prop" title="Graficas">
                        <div class="menu_windows">
                            <div class="group">
                                <button class="ribbon-button">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/vistas.png"/>
                                    </span>
                                    <span class="caption">Vistas por selección</span>
                                </button>
                                <button class="ribbon-button">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/ordenar-comida.png"/>
                                    </span>
                                    <span class="caption">Ordenar por tipo</span>
                                </button>
                                <span class="title">
                                    <p class="text">Opciones Principales</p>
                                </span>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab v-if="permitEdit == true"  class="tab_prop" title="Data"><div class="menu_windows">
                            <!-- <div class="group">
                                <button disabled class="ribbon-button" data-toggle='modal-6' v-b-modal.modal-6>
                                    <span class="icon">
                                        <img src="@/assets/img/chart/dato.png"/>
                                    </span>
                                    <span class="caption">Origen Data</span>
                                </button>
                                <button disabled class="ribbon-button">
                                    <span class="icon">
                                        <img src="@/assets/img/chart/almacenamiento-en-la-nube.png"/>
                                    </span>
                                    <span class="caption">Importar Data</span>
                                </button>
                                <span class="title">
                                    <p class="text">Opciones Principales</p>
                                </span>
                            </div> -->
                            <div class="group">
                                <button class="ribbon-button" v-b-modal.modal-3>
                                    <span class="icon">
                                        <img src="@/assets/img/chart/notas.png"/>
                                    </span>
                                    <span class="caption">Parámetros de informe</span>
                                </button>
                                <span class="title">
                                    <p class="text">Edición</p>
                                </span>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>

            <b-modal  hide-footer size="xl" id="modal-3" title="Parámetros de informe">
                <div v-if="idProject == 2">
                        <b-tabs card>
                            <b-tab title="Información general del sitio" active>
                                <b-container>
                                    <br/>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-1" label="Sitio:" label-for="input-1">
                                                <b-form-input
                                                id="input-1"
                                                v-model="parametrosInforme.Sitio"
                                                placeholder="Ingrese Sitio"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-2" label="Fecha:" label-for="input-2">
                                                <b-form-datepicker
                                                id="input-2"
                                                v-model="parametrosInforme.Fecha"
                                                placeholder="Ingrese Fecha"
                                                required
                                                ></b-form-datepicker>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-3" label="Zona Ocensa:" label-for="input-3">
                                                <b-form-input
                                                id="input-3"
                                                v-model="parametrosInforme.ZonaOcensa"
                                                placeholder="Ingrese Zona Ocensa"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-4" label="Línea:" label-for="input-4">
                                                <b-form-input
                                                id="input-4"
                                                v-model="parametrosInforme.Linea"
                                                placeholder="Ingrese Linea"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-5" label="Departamento:" label-for="input-5">
                                                <b-form-input
                                                id="input-5"
                                                v-model="parametrosInforme.Departamento"
                                                placeholder="Ingrese Departamento"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-6" label="Municipio:" label-for="input-6">
                                                <b-form-input
                                                id="input-6"
                                                v-model="parametrosInforme.Municipio"
                                                placeholder="Ingrese Municipio"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-7" label="Vereda:" label-for="input-7">
                                                <b-form-input
                                                id="input-7"
                                                v-model="parametrosInforme.Vereda"
                                                placeholder="Ingrese Vereda"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-8" label="Localización exacta:" label-for="input-8">
                                                <b-form-input
                                                id="input-8"
                                                v-model="parametrosInforme.LocalizacionExacta"
                                                placeholder="Ingrese Localización exacta"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-tab>
                            <b-tab title="Información de sección">
                                <b-container>
                                    <br/>
                                    <b-row>
                                        <b-col sm="2">
                                            <b-dropdown id="dropdown-1" variant="info" :text="nameSection" class="m-md-2">
                                                <b-dropdown-item @click="selectSection(1)">01 (FBG-HV)</b-dropdown-item>
                                                <b-dropdown-item @click="selectSection(2)">02 (FBG-HV)</b-dropdown-item>
                                                <b-dropdown-item @click="selectSection(3)">03 (FBG-HV)</b-dropdown-item>
                                                <b-dropdown-item @click="selectSection(4)">04 (FBG-HV)</b-dropdown-item>
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                    <br/>
                                    <div v-if="sectionC == 1">
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-1" label="Fecha de instalación:" label-for="input-1">
                                                    <b-form-datepicker
                                                    id="input-1"
                                                    v-model="parametrosInforme.Secciones.a1.fechaInstalacion"
                                                    placeholder="Ingrese Fecha de instalación"
                                                    required
                                                    ></b-form-datepicker>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-2" label="Norte:" label-for="input-2">
                                                    <b-form-input
                                                    id="input-2"
                                                    v-model="parametrosInforme.Secciones.a1.norte"
                                                    placeholder="Ingrese Norte"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-3" label="Este:" label-for="input-3">
                                                    <b-form-input
                                                    id="input-3"
                                                    v-model="parametrosInforme.Secciones.a1.este"
                                                    placeholder="Ingrese Este"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-4" label="Altitud:" label-for="input-4">
                                                    <b-form-input
                                                    id="input-4"
                                                    v-model="parametrosInforme.Secciones.a1.altitud"
                                                    placeholder="Ingrese Altitud"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-if="sectionC == 2">
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-1" label="Fecha de instalación:" label-for="input-1">
                                                    <b-form-datepicker
                                                    id="input-1"
                                                    v-model="parametrosInforme.Secciones.a2.fechaInstalacion"
                                                    placeholder="Ingrese Fecha de instalación"
                                                    required
                                                    ></b-form-datepicker>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-2" label="Norte:" label-for="input-2">
                                                    <b-form-input
                                                    id="input-2"
                                                    v-model="parametrosInforme.Secciones.a2.norte"
                                                    placeholder="Ingrese Norte"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-3" label="Este:" label-for="input-3">
                                                    <b-form-input
                                                    id="input-3"
                                                    v-model="parametrosInforme.Secciones.a2.este"
                                                    placeholder="Ingrese Este"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-4" label="Altitud:" label-for="input-4">
                                                    <b-form-input
                                                    id="input-4"
                                                    v-model="parametrosInforme.Secciones.a2.altitud"
                                                    placeholder="Ingrese Altitud"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-if="sectionC == 3">
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-1" label="Fecha de intalación:" label-for="input-1">
                                                    <b-form-datepicker
                                                    id="input-1"
                                                    v-model="parametrosInforme.Secciones.a3.fechaInstalacion"
                                                    placeholder="Ingrese Fecha de intalación"
                                                    required
                                                    ></b-form-datepicker>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-2" label="Norte:" label-for="input-2">
                                                    <b-form-input
                                                    id="input-2"
                                                    v-model="parametrosInforme.Secciones.a3.norte"
                                                    placeholder="Ingrese Norte"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-3" label="Este:" label-for="input-3">
                                                    <b-form-input
                                                    id="input-3"
                                                    v-model="parametrosInforme.Secciones.a3.este"
                                                    placeholder="Ingrese Este"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-4" label="Altitud:" label-for="input-4">
                                                    <b-form-input
                                                    id="input-4"
                                                    v-model="parametrosInforme.Secciones.a3.altitud"
                                                    placeholder="Ingrese Altitud"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-if="sectionC == 4">
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-1" label="Fecha de intalación:" label-for="input-1">
                                                    <b-form-datepicker
                                                    id="input-1"
                                                    v-model="parametrosInforme.Secciones.a4.fechaInstalacion"
                                                    placeholder="Ingrese Fecha de intalación"
                                                    required
                                                    ></b-form-datepicker>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-2" label="Norte:" label-for="input-2">
                                                    <b-form-input
                                                    id="input-2"
                                                    v-model="parametrosInforme.Secciones.a4.norte"
                                                    placeholder="Ingrese Norte"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-3" label="Este:" label-for="input-3">
                                                    <b-form-input
                                                    id="input-3"
                                                    v-model="parametrosInforme.Secciones.a4.este"
                                                    placeholder="Ingrese Este"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="6">
                                                <b-form-group id="input-group-4" label="Altitud:" label-for="input-4">
                                                    <b-form-input
                                                    id="input-4"
                                                    v-model="parametrosInforme.Secciones.a4.altitud"
                                                    placeholder="Ingrese Altitud"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-container>
                            </b-tab>
                            <b-tab title="Lecturas actuales">
                                <b-container>
                                    <br/>
                                    <b-row>
                                        <b-col sm="2">
                                            <b-dropdown id="dropdown-1" variant="info" :text="nameLectura" class="m-md-2">
                                                <b-dropdown-item @click="selectLectura(1)">Anillo 1</b-dropdown-item>
                                                <b-dropdown-item @click="selectLectura(2)">Anillo 2</b-dropdown-item>
                                                <b-dropdown-item @click="selectLectura(3)">Anillo 3</b-dropdown-item>
                                                <b-dropdown-item @click="selectLectura(4)">Anillo 4</b-dropdown-item>
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                    <br/>
                                    <div v-if="LecturaC == 1">
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-1" label="Valor actual - A_HV:" label-for="input-1">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-1"
                                                    v-model="parametrosInforme_infoLecturas.anillo1.valorActual.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-2" label="Valor actual - B_HV:" label-for="input-2">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-2"
                                                    v-model="parametrosInforme_infoLecturas.anillo1.valorActual.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-3" label="Valor actual - C_HV:" label-for="input-3">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-3"
                                                    v-model="parametrosInforme_infoLecturas.anillo1.valorActual.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-4" label="Deformación vs linea base - A_HV:" label-for="input-4">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-4"
                                                    v-model="parametrosInforme_infoLecturas.anillo1.DefLinBase.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-5" label="Deformación vs linea base - B_HV:" label-for="input-5">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-5"
                                                    v-model="parametrosInforme_infoLecturas.anillo1.DefLinBase.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-6" label="Deformación vs linea base - C_HV:" label-for="input-6">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-6"
                                                    v-model="parametrosInforme_infoLecturas.anillo1.DefLinBase.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-if="LecturaC == 2">
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-1" label="Valor actual - A_HV:" label-for="input-1">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-1"
                                                    v-model="parametrosInforme_infoLecturas.anillo2.valorActual.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-2" label="Valor actual - B_HV:" label-for="input-2">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-2"
                                                    v-model="parametrosInforme_infoLecturas.anillo2.valorActual.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-3" label="Valor actual - C_HV:" label-for="input-3">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-3"
                                                    v-model="parametrosInforme_infoLecturas.anillo2.valorActual.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-4" label="Deformación vs línea base - A_HV:" label-for="input-4">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-4"
                                                    v-model="parametrosInforme_infoLecturas.anillo2.DefLinBase.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-5" label="Deformación vs línea base - B_HV:" label-for="input-5">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-5"
                                                    v-model="parametrosInforme_infoLecturas.anillo2.DefLinBase.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-6" label="Deformación vs línea base - C_HV:" label-for="input-6">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-6"
                                                    v-model="parametrosInforme_infoLecturas.anillo2.DefLinBase.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-if="LecturaC == 3">
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-1" label="Valor actual - A_HV:" label-for="input-1">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-1"
                                                    v-model="parametrosInforme_infoLecturas.anillo3.valorActual.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-2" label="Valor actual - B_HV:" label-for="input-2">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-2"
                                                    v-model="parametrosInforme_infoLecturas.anillo3.valorActual.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-3" label="Valor actual - C_HV:" label-for="input-3">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-3"
                                                    v-model="parametrosInforme_infoLecturas.anillo3.valorActual.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-4" label="Deformación vs línea base - A_HV:" label-for="input-4">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-4"
                                                    v-model="parametrosInforme_infoLecturas.anillo3.DefLinBase.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-5" label="Deformación vs línea base - B_HV:" label-for="input-5">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-5"
                                                    v-model="parametrosInforme_infoLecturas.anillo3.DefLinBase.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-6" label="Deformación vs línea base - C_HV:" label-for="input-6">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-6"
                                                    v-model="parametrosInforme_infoLecturas.anillo3.DefLinBase.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-if="LecturaC == 4">
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-1" label="Valor actual - A_HV:" label-for="input-1">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-1"
                                                    v-model="parametrosInforme_infoLecturas.anillo4.valorActual.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-2" label="Valor actual - B_HV:" label-for="input-2">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-2"
                                                    v-model="parametrosInforme_infoLecturas.anillo4.valorActual.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-3" label="Valor actual - C_HV:" label-for="input-3">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-3"
                                                    v-model="parametrosInforme_infoLecturas.anillo4.valorActual.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-4" label="Deformación vs línea base - A_HV:" label-for="input-4">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-4"
                                                    v-model="parametrosInforme_infoLecturas.anillo4.DefLinBase.A_HV"
                                                    placeholder="Ingrese Valor actual - A_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-5" label="Deformación vs línea base - B_HV:" label-for="input-5">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-5"
                                                    v-model="parametrosInforme_infoLecturas.anillo4.DefLinBase.B_HV"
                                                    placeholder="Ingrese Valor actual - B_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="3">
                                                <b-form-group id="input-group-6" label="Deformación vs línea base - C_HV:" label-for="input-6">
                                                    <b-form-input
                                                    type="number" 
                                                    id="input-6"
                                                    v-model="parametrosInforme_infoLecturas.anillo4.DefLinBase.C_HV"
                                                    placeholder="Ingrese Valor actual - C_HV"
                                                    required
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-container>
                            </b-tab>
                            <b-tab title="Resumen del sitio">
                                <b-container>
                                    <br/>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-1" label="No. de secciones instaladas FBG:" label-for="input-1">
                                                <b-form-input
                                                type="number"
                                                id="input-1"
                                                v-model="parametrosInforme.Resume.NSeccionesFBG"
                                                placeholder="Ingrese No. de secciones instaladas FBG"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-2" label="No. de lecturas tomadas:" label-for="input-2">
                                                <b-form-input
                                                type="number"
                                                id="input-2"
                                                v-model="parametrosInforme.Resume.NLecturasFBG"
                                                placeholder="Ingrese No. de lecturas tomadas"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-3" label="No. de secciones instaladas HV:" label-for="input-3">
                                                <b-form-input
                                                type="number"
                                                id="input-3"
                                                v-model="parametrosInforme.Resume.NSeccionesHV"
                                                placeholder="Ingrese No. de secciones instaladas HV"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-4" label="Fecha de instalación:" label-for="input-4">
                                                <b-form-datepicker
                                                id="input-4"
                                                v-model="parametrosInforme.Resume.FechaInstalacionHV"
                                                placeholder="Ingrese Fecha de instalación"
                                                required
                                                ></b-form-datepicker>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-5" label="Periodo monitoreado:" label-for="input-5">
                                                <b-form-input
                                                id="input-5"
                                                v-model="parametrosInforme.Resume.PeriodoMonitoreadoHV"
                                                placeholder="Ingrese Periodo monitoreado"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-6" label="Frecuencia de promedio FBG:" label-for="input-6">
                                                <b-form-input
                                                id="input-6"
                                                v-model="parametrosInforme.Resume.FrecuenciaPromFBG"
                                                placeholder="Ingrese Frecuencia de promedio FBG"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-7" label="Fecha lectura alivio base:" label-for="input-7">
                                                <b-form-datepicker
                                                id="input-7"
                                                v-model="parametrosInforme.Resume.FechaLecturaAlivioBase"
                                                placeholder="Ingrese Fecha lectura alivio base"
                                                required
                                                ></b-form-datepicker>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-8" label="Frecuencia de promedio HV:" label-for="input-8">
                                                <b-form-input
                                                id="input-8"
                                                v-model="parametrosInforme.Resume.FrecuenciaPromHV"
                                                placeholder="Ingrese Frecuencia de promedio HV"
                                                required
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-9" label="Fecha ultima lectura FBG:" label-for="input-9">
                                                <b-form-datepicker
                                                id="input-9"
                                                v-model="parametrosInforme.Resume.FechaUltimaLecturaFBG"
                                                placeholder="Ingrese Fecha ultima lectura FBG"
                                                required
                                                ></b-form-datepicker>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-10" label="Fecha ultima lectura HV:" label-for="input-10">
                                                <b-form-datepicker
                                                id="input-10"
                                                v-model="parametrosInforme.Resume.FechaUltimaLecturaHV"
                                                placeholder="Ingrese Fecha ultima lectura HV"
                                                required
                                                ></b-form-datepicker>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-tab>
                            <b-tab title="Adjuntar imagenes">
                                <b-container>
                                    <br/>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-11" label="Ilustración del sitio:" label-for="input-11">
                                                <v-file-input
                                                    v-model="ilustracion_sitio"
                                                    chips
                                                    accept="image/png, image/jpeg, image/bmp"
                                                    label="Seleccionar una imagen"
                                                ></v-file-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                            <b-form-group id="input-group-12" label="Ubicación de galgas:" label-for="input-12">
                                                <v-file-input
                                                    v-model="ubicacion_galgas"
                                                    chips
                                                    accept="image/png, image/jpeg, image/bmp"
                                                    label="Seleccionar una imagen"
                                                ></v-file-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="6">

                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-tab>
                            <b-tab title="Adjuntar observaciones">
                                <b-container>
                                    <br/>
                                    <b-row>
                                        <b-col sm="12">
                                            <b-form-group id="input-group-13" label="Observaciones:" label-for="input-13">
                                                <b-form-textarea
                                                    v-model="parametrosInforme.Observaciones.text"
                                                    id="input-13"
                                                    placeholder="Ingrese sus observaciones."
                                                >
                                                </b-form-textarea>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-tab>
                        </b-tabs>
                </div>
                <div v-if="idProject != 2">
                    <b-tabs card>
                        <b-tab title="Adjuntar imágenes" active>
                            <b-container>
                                <br/>
                                <b-row>
                                    <b-col sm="6">
                                        <b-form-group id="input-group-13" label="Logo de la empresa:" label-for="input-13">
                                            <b-form-file
                                                accept=".jpg, .png, .svg"
                                                v-model="logo_empresa"
                                                id="input-13"
                                                placeholder="Seleccionar una imagen"
                                            >
                                            </b-form-file>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-tab>
                    </b-tabs>
                </div>
                <b-row>
                    <b-col sm="3">
                        <b-button variant="success" class="mt-3" block @click="saveParametros(); $bvModal.hide('modal-3');">Guardar</b-button>
                    </b-col>
                </b-row>
            </b-modal>

            <b-modal class="modal_preview" hide-footer size="xl" id="modal-4" title="Imprimir el documento">
                <b-row>
                    <b-col sm="3" style="display: flex; align-items: center; justify-content: center;">
                        <b-row class="icons_view">
                            <button style="margin-bottom: 15px;" class="ribbon-button_1" @click="downloadReport();">
                                <span class="icon">
                                    <img style="height: 40px; max-width: 40px;" src="@/assets/img/chart/boton.png"/>
                                </span>
                                <span class="caption"></span>
                            </button>
                            <br/>
                            <button class="ribbon-button_1" @click="previewReport(); hideNone = !hideNone;">
                                <span class="icon">
                                    <img style="height: 40px; max-width: 40px;" src="@/assets/img/chart/file.png"/>
                                </span>
                                <span class="caption"></span>
                            </button>
                        </b-row>
                    </b-col>
                    <b-col sm="9">
                        <div v-if="fileView == null" class="content_scroll_1" style="overflow-y: scroll!important; height: 510px!important; background: gray; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                            <div class="box_nocarga">
                                <p style="color: white; font-style: italic;">Clic en el botón previsualizar o en el botón de
                                    descargar.
                                </p>
                            </div>
                        </div>
                        <div v-if="fileView != null" class="content_scroll" style="overflow-y: scroll!important; height: 510px!important; background: gray;">
                            <pdf 
                                v-for="i in numPages"
                                :key="i"
                                :src="fileView"
                                :page="i"
                                style="display: inline-block; width: 100%"
                                @num-pages="pageCount = $event"
                                @page-loaded="currentPage = $event"
                            ></pdf>
                        </div>
                    </b-col>
                </b-row>
            </b-modal>

                <b-modal centered hide-footer no-close-on-esc no-close-on-backdrop size="xl" id="modal-6" title="Origen Data">
                     <div>
                        <b-tabs card>
                            <b-tab title="Formulas de las series" active>
                                <b-col>
                                    <b-row>
                                        <b-col sm="3">
                                            <div>
                                                <b-card>
                                                    <MathLiveInput :config="config" v-model="formula" v-on:input="input()">{{formulaLoad}}</MathLiveInput>
                                                </b-card>
                                            </div>
                                        </b-col>
                                        <b-col sm="9">
                                            
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-tab>
                        </b-tabs>
                     </div>
                </b-modal>
            <div>
                <b-card class="card_windows">
                    <div v-if="finish == true">
                        <BodyC :series="series" :exportExcel="exportExcel" :activeDataChart="activeDataChart" :nameProject="nameProject" :chartOptions="chartOptions" :namePoint="namePoint"/>
                    </div>
                </b-card>
                <br/>
            </div>

            <b-modal hide-footer class="modal" id="gen_alert" title="Generar Alerta">

                        <!-- Modal body -->
                        <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-6">
                            <div class="form-group">
                                <label for="name_sensor">Nombre del sensor:</label>
                                <select class="form-control" @change="consultSensor(nameSensor)" v-model="nameSensor">
                                <option v-for="(nameS, index) in RegNameSensor" :key="nameS.id" :id="index"  
                                >{{nameS.name}}
                                </option>
                                </select>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="form-group">
                                <label for="tipo_alerta">Tipo de alerta:</label>
                                <select class="form-control" v-model="tipo_alerta">
                                <option>Rojo Superior</option>
                                <option>Naranja Superior</option>
                                <option>Amarillo Superior</option>
                                <option>Amarillo Inferior</option>
                                <option>Naranja Inferior</option>
                                <option>Rojo Inferior</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                            <div class="form-group">
                                <label for="num_x1">X_1:</label>
                                <input type="number" class="form-control"  v-model="num_x1">
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="form-group">
                                <label for="num_x2">X_2:</label>
                                <input type="number" class="form-control" v-model="num_x2">
                            </div>
                            </div>
                        <div class="row">
                            <div class="col-sm-3 text-center">
                            <button v-if="parseInt(this.num_x1) < parseInt(this.num_x2) && this.tipo_alerta.length > 0 && this.existAlertFound == true" type="button" class="btn btn-primary" @click="addtypeAlert(tipo_alerta)">Agregar</button>
                            <button v-if="parseInt(this.num_x1) < parseInt(this.num_x2) && this.tipo_alerta.length > 0 && this.existAlertFound == false" type="button" class="btn btn-primary" @click="addNewtypeAlert()">Agregar</button>
                            </div>
                            <div class="col-sm-9">
                            </div>
                        </div>
                        </div>
                        <br/>
                        <div v-if="this.existAlertFound == true" lass="content-modal scroll"> 
                            <ul class="list-group">
                            <li v-for="(typeA, index) in typeAlert[positionAlert]" :key="typeA.id" :id="index" class="list-group-item">{{typeA.tipoalerta}} &nbsp; {{typeA.num_x1}} - {{typeA.num_x2}}</li>
                            </ul>
                        </div>
                        <div v-if="this.existAlertFound == false" lass="content-modal scroll"> 
                            <ul class="list-group">
                            <li v-for="(typeA, index) in typeAlert" :key="typeA.id" :id="index" class="list-group-item">{{typeA.tipoalerta}} &nbsp; {{typeA.num_x1}} - {{typeA.num_x2}}</li>
                            </ul>
                        </div>
                        </div>

                        <!-- Modal footer -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                            <button v-if="this.existAlertFound == true" type="button" class="btn btn-success" @click="addAlert">Guardar</button>
                            <button v-if="this.existAlertFound == false" type="button" class="btn btn-success" @click="addNewAlert">Guardar</button>
                        </div>

            </b-modal>

        </div>
    </b-container>
</template>
<script type="module">
/*eslint-disable */

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

import MathLive from "mathlive";
import MathLiveInput from "./math_live/MathLiveInput.vue";
import BodyC from "./layouts/BodyChart.vue";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import logosafenet from "@/assets/img/logos/safered-logo-01.png";
import VueGauge from 'vue-gauge';
import axios from "axios";
import pdf from 'vue-pdf';
import getD from "safered-chart-files";

export default {
    name: "Chart",
    components:{
        MathLiveInput,
        ClipLoader,
        BodyC,
        pdf,
        VueGauge,
    },
    data(){
        return{
            logosafenet,
            message: false,
            nameSensor: "",
            RegNameSensor: [],
            alertFound: [],
            positionAlert: 0,
            tipo_alerta: "",
            num_x1: 0,
            num_x2: 0,
            typeAlert: [],
            data: [],
            alert: [],
            temp: false,
            existAlertFound: false,
            formula: 'h(x)',
            formulaLoad: "",
            config:{
                smartMode: true,
                virtualKeyboardMode: "manual",
            },
            fechaA: "",
            cargueB: false,
            updateN: false,
            needleValue: 0,
            options: {
                hasNeedle: false,
                outerNeedle: false,
                needleUpdateSpeed: 5,
                arcColors: ["grey"],
                arcDelimiters: [0],
                rangeLabel: ["0%","100%"],
                centralLabel: "N/A",
                rangeLabelFontSize: 6,
                needleColor: "black",
                chartWidth: 75,
            },
            hideNone: false,
            fileView: null,
            numPages: undefined,
            currentPage: 0,
			pageCount: 0,
            chartOptions: [],
            idChart: "",
            nameProject: "",
            series: [],
            namePoint: [],
            groupSensor: "",
            valorBattry: "",
            finish: false,
            nameP: [],
            activeDataChart: 0,
            loadingMain: true,
            sectionC: 1,
            nameSection: "01 (FBG-HV)",
            LecturaC: 1,
            nameLectura: "Anillo 1",
            idProject: "",
            ilustracion_sitio: null,
            ubicacion_galgas: null,
            logo_empresa: null,
            ilu_sit: null,
            ubic_galg: null,
            log_empr: null,
            parametrosCloudOInforme: [],
            parametrosInforme :{
                Sitio: "",
                Fecha: "",
                ZonaOcensa: "",
                Linea: "",
                Departamento: "",
                Municipio: "",
                LocalizacionExacta: "",
                Observaciones: {
                    text: null,
                    fecha: null,
                    usuario: null,
                },
                Secciones: {
                    a1:{
                        name: "01 (FBG-HV)",
                        fechaInstalacion: "",
                        norte: "",
                        este: "",
                        altitud: ""
                    },
                    a2:{
                        name: "02 (FBG-HV)",
                        fechaInstalacion: "",
                        norte: "",
                        este: "",
                        altitud: ""
                    },
                    a3:{
                        name: "03 (FBG-HV)",
                        fechaInstalacion: "",
                        norte: "",
                        este: "",
                        altitud: ""
                    },
                    a4:{
                        name: "04 (FBG-HV)",
                        fechaInstalacion: "",
                        norte: "",
                        este: "",
                        altitud: ""
                    }
                },
                Resume:{
                    NSeccionesFBG: "",
                    NLecturasFBG: "",
                    NSeccionesHV: "",
                    FechaInstalacionHV: "",
                    PeriodoMonitoreadoHV: "",
                    FrecuenciaPromFBG: "",
                    FechaLecturaAlivioBase: "",
                    FrecuenciaPromHV: "",
                    FechaUltimaLecturaFBG: "",
                    FechaUltimaLecturaHV: "",
                }
            },
            parametrosInforme_infoLecturas: {
                anillo1 : {
                    valorActual: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    },
                    DefLinBase: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    }
                },
                anillo2 : {
                    valorActual: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    },
                    DefLinBase: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    }
                },
                anillo3 : {
                    valorActual: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    },
                    DefLinBase: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    }
                },
                anillo4 : {
                    valorActual: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    },
                    DefLinBase: {
                        A_HV: 0,
                        B_HV: 0,
                        C_HV: 0
                    }
                }
            },
            exportExcel: false,
            errorAlert: false,
            nameChart: '',
            permitEdit: false,
            img1: null,
            img2: null,
            currentUser: null,
            dataConfig: []
        }
    },
    async mounted() {
        this.loadConfigforChart()
        this.currentUser = JSON.parse(localStorage.user)
        this.nameChart = this.$route.params.namePoint;    
        this.permitSection();
        MathLive.renderMathInDocument();
        this.chartOptions.push(this.$store.state.chartOptions);
        this.idProject = this.$route.params.projectId;
        this.idChart = this.$route.params.idChart;
        await this.loadAlert();
        await this.loadProjects();
        await this.getSensorGrupo();

        if(this.groupSensor == "Lect-Process"){
            await this.loadDataLectProcess();
            this.finish = true;
        }else if(this.groupSensor == "Lect-Direct"){
            await this.loadDataLectDirect();
            this.finish = true;
        }else if(this.groupSensor == "Lect-Files"){
            await this.loadDataFichero();
            this.finish = true;
        }else{
            this.loadingMain= false;
            this.errorAlert = true;
        }

        this.cargarParametros();
        this.loadValB();
        this.loadUltimaActualizacion();
        this.loadFormula();
        this.asignarNameS();
        this.loadingMain= false;
    },
    methods: {
        loadConfigforChart: async function () {
            var temp = {
                idPoint: this.$route.params.idChart,
            };
            var dataC = [];
            await axios
            .post("https://api.safered.co/api/getConfigChart", temp)
            .then((response) => {
            dataC = response.data;
            for (let i = 0; i < dataC.length; i++) {
                this.dataConfig.push({
                    config: dataC[i].config,
                    indexChart: dataC[i].indexChart,
                });
            }
                this.dataPreCloud = true;
            })
            .catch(function (error) {
                console.log(error);
            });

        },
        llenarVar(){
            this.typeAlert = [];
            for(let i=0;i<this.alertFound[0].data.length;i++){
                this.typeAlert.push(this.alertFound[0].data[i].dataAlert);
            }

            this.data = this.alertFound[0].data;

        },
        consultSensor(nameS){
            for(let i=0;i<this.alertFound[0].data.length;i++){
                if(this.alertFound[0].data[i].name_sensor.includes(nameS) === true){
                    this.positionAlert = i;
                }
            }
        },
        addNewtypeAlert(){
           
            var exist = this.typeAlert[i].find(element => element.tipoalerta == this.tipoalerta);

            if(exist.length == undefined){
                this.typeAlert.push({
                    tipoalerta: this.tipo_alerta,
                    num_x1: this.num_x1,
                    num_x2: this.num_x2
                })
            }else{
                alert("No se puede repetir el tipo de alerta");
            }

            this.tipo_alerta = "";
            this.num_x1 = 0;
            this.num_x2 = 0;
        },
        addtypeAlert(tipo_alerta){
            for(let i=0;i<this.typeAlert[this.positionAlert].length;i++){
                if(this.typeAlert[this.positionAlert][i].tipoalerta == null){
                    this.typeAlert[this.positionAlert][i].tipoalerta = this.tipo_alerta;
                    this.typeAlert[this.positionAlert][i].num_x1 = this.num_x1;
                    this.typeAlert[this.positionAlert][i].num_x2 =  this.num_x2;
                    this.temp = true;
                }else if(this.typeAlert[this.positionAlert][i].tipoalerta.includes(tipo_alerta) == true){
                    this.typeAlert[this.positionAlert][i].tipoalerta = this.tipo_alerta;
                    this.typeAlert[this.positionAlert][i].num_x1 = this.num_x1;
                    this.typeAlert[this.positionAlert][i].num_x2 = this.num_x2;
                    this.temp = true;
                }else if(this.typeAlert[this.positionAlert][i].tipoalerta.includes(tipo_alerta) == false){
                    this.typeAlert[this.positionAlert].push({
                        tipoalerta: this.tipo_alerta,
                        num_x1: this.num_x1,
                        num_x2: this.num_x2
                    })
                    this.temp = true;
                }
            };
            if(this.temp == false){
                this.typeAlert[this.positionAlert].push({
                tipoalerta: this.tipo_alerta,
                num_x1: this.num_x1,
                num_x2: this.num_x2
                })
            }
            this.tipo_alerta = "";
            this.num_x1 = 0;
            this.num_x2 = 0;

            this.data[this.positionAlert].name_sensor = this.nameSensor;
            this.data[this.positionAlert].dataAlert = this.typeAlert[this.positionAlert];

        },
        addNewAlert(){
            this.alert = [];
            this.data.push({
                name_sensor: this.nameSensor,
                dataAlert: this.typeAlert
            })
            if(this.data.length > 11){
                this.alert.push({
                    proyecto_id: this.$route.params.projectId,
                    punto: this.$route.params.idChart,
                    data: this.data
                })
                this.nameSensor = "",
                this.typeAlert = []
                this.sendAlert();
            }else if(this.data.length < 11){
                
                var dataA = [{
                    num_x1: null,
                    num_x2: null,
                    tipoalerta: null
                }];

                var dataF = [];


                for(let i=0;i<this.RegNameSensor.length; i++){
                    var exist = this.data.find(element => element.name_sensor == this.RegNameSensor[i].name);
                    
                    if(exist == undefined){
                        dataF.push({
                            name_sensor: this.RegNameSensor[i].name,
                            dataAlert: dataA
                        })
                    }
                }

                dataF.push(this.data[0])

                this.alert.push({
                    proyecto_id: this.$route.params.projectId,
                    punto: this.$route.params.idChart,
                    data: dataF
                })

                this.nameSensor = "";
                this.typeAlert = [];
                this.sendAlert();
            }
        },
        addAlert(){
            this.alert = [];
            this.alert.push({
                proyecto_id: this.$route.params.projectId,
                punto: this.$route.params.idChart,
                data: this.data
            });
            this.nameSensor = "",
            this.typeAlert = [],
            this.sendAlert();
        },
        asignarNameS(){
            for(let i=0;i<this.series.length;i++){
                for(let j=0;j<this.series[i].length;j++){
                    this.RegNameSensor.push(this.series[i][j]);
                }
            }
        },
        sendAlert(){
            axios.post('https://api.safered.co/api/storeAlarmsRange', this.alert)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        loadAlert: async function(){
            var status = false;
            await axios
            .get('https://api.safered.co/api/getAlarmsRange/' + this.$route.params.idChart)
            .then((response) => {
                response.data.forEach((element) => {
                this.alertFound.push({
                    proyecto_id: element.proyecto_id,
                    punto: element.punto,
                    data: JSON.parse(element.data)
                })
                })
            }).catch((e) =>{
                this.existAlertFound = false;
                status = true;
            });
            
            if(status == false){
                if(this.alertFound[0].data.length > 0){
                    this.existAlertFound = true;
                }else{
                    this.existAlertFound = false;
                }
            }
        },
        permitSection: async function(){
            if(JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true){
                this.permitEdit = true;
            }else{
                this.permitEdit = false;
            }
        },
        loadFormula: async function(){
            var dataFormula = [];
            var resultFormat = [];
            await axios
            .post('https://api.safered.co/api/getFormulaFromSensor/KM521')
            .then((response) => {
                dataFormula = response.data;
              
            }).catch((e) =>{
                console.log(e);
            });
            
            for(let i=0; i<dataFormula.length; i++){

                resultFormat.push({
                    nameSensor: dataFormula[i].nameSensor,
                    arraySensor: dataFormula[i].arraySensor,
                    expression: dataFormula[i].expression.replaceAll('((float) $constAgroup[\"', '').replaceAll('\"][0]->value', '')
                    .replaceAll('(float)$canal[\"', '').replaceAll('\"][$i]->value', '').replaceAll('(double)$constAgroup[\"', '')
                    .replaceAll('(double)$canal[\"', '').replaceAll('(float)$constAgroup[\"', '').replaceAll('(float) $constAgroup[\"', '')
                    .replaceAll('))))', ')))').replaceAll('_', '.'),
                });
            }
            this.formulaLoad = resultFormat[0].expression;
        },
        cargarParametros: async function(){
            var temp = {
                idProject: this.$route.params.projectId,
                idPoint: this.$route.params.idChart
            }
            var obj = [];
            var img = [];
            await axios
            .post('https://api.safered.co/api/getConfigInforme', temp)
            .then(function (response) {
                obj.push({
                    infoLecturas: response.data.parametros.infoLecturas,
                    infoPoint: response.data.parametros.infoPoint
                })
                img.push(response.data.imagenes);

            })
            .catch(function (error) {
                console.log(error);
            });
            if(img[0].length > 1){
                this.ilu_sixt = "data:image/png;base64,"+img[0][0];
                this.ubic_galg = "data:image/png;base64,"+img[0][1];
            
            }else{
                this.log_empr = "data:image/png;base64,"+img[0][0];
            }
            
            this.parametrosCloudOInforme = obj;
            this.loadDataParametros();
        },
        loadDataParametros: async function(){

            if(this.parametrosCloudOInforme[0].infoLecturas.anillo1 != undefined){
                this.parametrosInforme = this.parametrosCloudOInforme[0].infoPoint;
            }else if(this.parametrosCloudOInforme[0].infoPoint.Departamento != undefined){
                this.parametrosInforme_infoLecturas = this.parametrosCloudOInforme[0].infoLecturas;
            }

        },
        saveParametros: async function(){

            let date = new Date();
            this.parametrosInforme.Observaciones.fecha = date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes()
            this.parametrosInforme.Observaciones.usuario = this.currentUser.name

            if(this.idProject == 2){

                var formData = {
                    idPoint: this.$route.params.idChart,
                    idProject: this.$route.params.projectId,
                    infoPoint: this.parametrosInforme,
                    infoLecturas: this.parametrosInforme_infoLecturas,
                    img1: this.img1,
                    img2: this.img2
                }

                
                await axios
                .post('https://api.safered.co/api/setConfigInforme', formData)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
                this.cargarParametros();
            }else{
                
                let formData = new FormData();
                formData.append("img1", this.logo_empresa),
                formData.append("idPoint", this.$route.params.idChart),
                formData.append("idProject", this.$route.params.projectId)

                await axios
                .post('https://api.safered.co/api/setConfigInforme', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        selectSection(numero){
            if(numero == 1){
                this.sectionC = numero;
                this.nameSection = "01 (FBG-HV)";
            }else if(numero == 2){
                this.sectionC = numero;
                this.nameSection = "02 (FBG-HV)";
            }else if(numero == 3){
                this.sectionC = numero;
                this.nameSection = "03 (FBG-HV)";
            }else{
                this.sectionC = numero;
                this.nameSection = "04 (FBG-HV)";
            }
        },
        selectLectura(numero){
            if(numero == 1){
                this.LecturaC = numero;
                this.nameLectura = "Anillo 1";
            }else if(numero == 2){
                this.LecturaC = numero;
                this.nameLectura = "Anillo 2";
            }else if(numero == 3){
                this.LecturaC = numero;
                this.nameLectura = "Anillo 3";
            }else{
                this.LecturaC = numero;
                this.nameLectura = "Anillo 4";
            }
        },
        getSensorGrupo: async function(){
            var dataSensor = []
            await axios
                .post("https://api.safered.co/api/getSensorsForProject/" + 
                this.$route.params.projectId
            )
            .then((result)=> {
                dataSensor = JSON.parse(result.data[0]);
            });

            for(let i=0;i<dataSensor.length;i++){
                if(this.idChart == dataSensor[i].id){
                    this.groupSensor = dataSensor[i].Grupo;
                }
            }
        },
        loadProjects: async function () {
            var projectNameL = "";
            await axios
                .post(
                "https://api.safered.co/api/getProjectsForUser/" +
                    JSON.parse(localStorage.getItem("user")).id
                )
                .then((result) => {
                Object.values(result.data).forEach((element) => {
                    if (parseInt(this.idProject) == element[0].id) {
                        projectNameL = element[0].name;
                    }
                });
            });
            this.nameProject = projectNameL;
        },
        loadDataFichero: async function(){
            var idPoint = parseInt(this.$route.params.idChart);
            var idProject = parseInt(this.$route.params.projectId);

            var readings = await  getD(idPoint, idProject);

            if(readings == undefined){
                this.errorAlert = true; 
            }
            var sensor_item_listLoc = [];
            var arrayTempObj = [];
            var tempDataxy = [];

            if(readings.length == undefined){
                var dataRead = [];
                var datenew = "";
                var datoY = "";
                this.namePoint = Object.keys(readings);
                Object.keys(readings).forEach(function (key) {
                    Object.keys(readings[key]).forEach(function (key2){
                        datenew = readings[key][key2].x;
                        var day = (readings[key][key2].x).split("/",[3]);
                        datenew = day[1]+"-"+day[0]+"-"+day[2];
                        datoY = readings[key][key2].y;
                        dataRead.push({
                            x: datenew,
                            y: datoY,
                        });
                    }); 
                    
                    var tempObjTemp = {
                        name: "Vacio",
                        data: dataRead,
                        data_Update: [],
                    };
                    dataRead = [];
                    arrayTempObj.push([tempObjTemp]);
                });

                this.series = arrayTempObj;
            }else{
                for(let i=0;i<readings.length;i++){
                    this.nameP.push(Object.keys(readings[i]));
                    for(let j=0;j<readings[i][this.nameP[i]].length;j++){
                        for(let l=0;l<readings[i][this.nameP[i]][j].data.length;l++){
                            var tempx = readings[i][this.nameP[i]][j].data[l].x;
                            var Dataxy = {
                                x: tempx, 
                                y: readings[i][this.nameP[i]][j].data[l].y
                            };
                            tempDataxy.push(Dataxy);
                        }
                        var tempObjTemp = {
                            name: readings[i][this.nameP[i]][j].name,
                            data: tempDataxy,
                            data_Update: [],
                        }
                        arrayTempObj.push(tempObjTemp);
                        tempDataxy = []
                    };
                    sensor_item_listLoc.push(arrayTempObj);
                    arrayTempObj = [];
                };
                this.series = sensor_item_listLoc;
            }
            
            for(let i=0;i<this.nameP.length;i++){
                this.namePoint.push(this.nameP[i][0]);
            }
            
            
            this.exportExcel = true;
        },
        loadDataLectProcess: async function () {
            var readings;
            var fail = false
            var id = this.idChart;
            var nameP = this.nameChart.slice(0, -4);

            await axios
                .post("https://api.safered.co/api/getReadingsForSensorSaferedV3/" + id)
                .then(function (result) {
                    readings = result.data;
                }).catch(function (error) {
                    console.log(error);
                    fail = true
                });
                
                if(readings.message != undefined || readings.length == 0 ){
                    this.errorAlert = true;
                    this.failReading = true;
                    this.message = true;
                }
                if(fail){
                    this.errorAlert = true;
                    this.failReading = true;
                }

                this.namePoint = Object.keys(readings[nameP]);
                var sensor_item_listLoc = [];
                Object.keys(readings[nameP]).forEach(function (key) {
                    
                    var value = readings[nameP][key];
                    var arrayTempObj = [];
                    Object.keys(value).forEach(function (key2) {
                        var tempObjTemp = {
                            name: key2,
                            data: value[key2],
                            data_Update: value[key2][value[key2].length-1][value[key2][value[key2].length-1].length-1]
                        };
                        arrayTempObj.push(tempObjTemp);
                    });
                    sensor_item_listLoc.push(arrayTempObj);
                });
               

            for (let index1 = 0; index1 < sensor_item_listLoc.length; index1++) {
                var chartGroup = [];
                for (let index2 = 0; index2 < sensor_item_listLoc[index1].length; index2++) {
                    for(let index3 = 0; index3 < sensor_item_listLoc[index1][index2].data[0].length; index3++){
                        for(let i = 0; i < this.dataConfig.length; i++){
                                if(this.dataConfig[i].indexChart == index1){
                                    if(parseFloat(this.dataConfig[i].config.rangoMaximoY) && parseFloat(this.dataConfig[i].config.rangoMinimoY) != 0){
                                        if(parseFloat(sensor_item_listLoc[index1][index2].data[0][index3].y) > parseFloat(this.dataConfig[i].config.rangoMaximoY)){
                                            sensor_item_listLoc[index1][index2].data[0][index3].y = this.dataConfig[i].config.rangoMaximoY
                                        }else if(parseFloat(sensor_item_listLoc[index1][index2].data[0][index3].y) < parseFloat(this.dataConfig[i].config.rangoMinimoY)){
                                            sensor_item_listLoc[index1][index2].data[0][index3].y = this.dataConfig[i].config.rangoMinimoY
                                        }
                                    }
                                }
                            }
                        }

                        
                        let mergedArray = [].concat(...sensor_item_listLoc[index1][index2].data);
                        chartGroup.push({
                            name: sensor_item_listLoc[index1][index2].name,
                            data: mergedArray,
                            data_Update: sensor_item_listLoc[index1][index2].data_Update
                        });
                    }
                    
                this.series.push(chartGroup);
            }
            this.updateN = true;
        },
        loadDataLectDirect: async function(){
            var readings;
            var errorA;
            var id = this.idChart;
            await axios
                .post("https://api.safered.co/api/getReadingsForRingSAFERED/" + id)
                .then(function (result) {
                    readings = result.data[0];
                }).catch(function (error) {
                    console.log(error);
                    errorA = true;
            });
            this.errorAlert = errorA;
            var sensor_item_listLoc = [];
            var arrayTempObj = [];
            var arrayTempObj2 = [];
            
            this.namePoint = Object.keys(readings);

            Object.keys(readings).forEach(function (key) {
                if(readings[key].length > 100){
                   arrayTempObj2.push({
                        name: key,
                        data: readings[key],
                        data_Update: [],
                    });
                }else{
                    Object.keys(readings[key]).forEach(function (key2) {
                        arrayTempObj.push({
                            name: key2,
                            data: readings[key][key2],
                            data_Update: [],
                        });
                    });
                }
                
            });
            sensor_item_listLoc.push(arrayTempObj);
            sensor_item_listLoc.push(arrayTempObj2);
            this.series = sensor_item_listLoc;
        },
        clickVerifyChart: async function(){

            this.activeDataChart = this.activeDataChart + 1;
        },
        previewReport: async function(){
            var arrayCharts = this.$store.state.dataChartPrint;
                var fileU = null;
                if(this.idProject == 2){
                    if(this.parametrosCloudOInforme[0].length > 0){
                        var obj = {
                            idProject: this.idProject,
                            arrayCharts: arrayCharts,
                            img1: this.ilu_sixt,
                            img2: this.ubic_galg,
                            infoPoint: this.parametrosCloudOInforme[0].infoPoint,
                            infoLecturas: this.parametrosCloudOInforme[0].infoLecturas
                        }
                    }else{
                        var obj = {
                            idProject: this.idProject,
                            arrayCharts: arrayCharts,
                            img1: this.ilu_sixt,
                            img2: this.ubic_galg,
                            infoPoint:  this.parametrosInforme,
                            infoLecturas: this.parametrosInforme_infoLecturas
                        }
                    }
                }else{
                    if(this.parametrosCloudOInforme.length > 0){
                        var obj = {
                            idProject: this.idProject,
                            namePoint: this.namePoint,
                            arrayCharts: arrayCharts,
                            nameProject: this.nameProject,
                            img1: this.log_empr,
                        }
                    }else{
                        var obj = {
                            idProject: this.idProject,
                            namePoint: this.namePoint,
                            arrayCharts: arrayCharts,
                            nameProject: this.nameProject,
                            img1: this.log_empr,
                        }
                    }
                }
                await axios
                .post(
                    'https://safered-pdf-report.safered.co/generateReport',
                    obj,{ headers: {'Content-Type': 'application/json'}, 'responseType': 'blob'})
                .then(function (response) {
                    fileU = response.data
                });
                var fileURL = window.URL.createObjectURL(new Blob([fileU]));
                this.fileView = pdf.createLoadingTask(fileURL);
        },
         downloadReport: async function(){
            var arrayCharts = this.$store.state.dataChartPrint;

                if(this.idProject == 2){
                    if(this.parametrosCloudOInforme[0].length > 0){
                        var obj = {
                            idProject: this.idProject,
                            arrayCharts: arrayCharts,
                            img1: this.ilu_sixt,
                            img2: this.ubic_galg,
                            infoPoint: this.parametrosCloudOInforme[0].infoPoint,
                            infoLecturas: this.parametrosCloudOInforme[0].infoLecturas
                        }
                    }else{
                        var obj = {
                            idProject: this.idProject,
                            arrayCharts: arrayCharts,
                            img1: this.ilu_sixt,
                            img2: this.ubic_galg,
                            infoPoint:  this.parametrosInforme,
                            infoLecturas: this.parametrosInforme_infoLecturas
                        }
                    }
                }else{
                    if(this.parametrosCloudOInforme.length > 0){
                        var obj = {
                            idProject: this.idProject,
                            namePoint: this.namePoint,
                            arrayCharts: arrayCharts,
                            nameProject: this.nameProject,
                            img1: this.log_empr,
                        }
                    }else{
                        var obj = {
                            idProject: this.idProject,
                            namePoint: this.namePoint,
                            arrayCharts: arrayCharts,
                            nameProject: this.nameProject,
                            img1: this.log_empr,
                        }
                    }
                }
                
                await axios
                .post(
                    'https://safered-pdf-report.safered.co/generateReport',
                    obj,{ headers: {'Content-Type': 'application/json'}, 'responseType': 'blob'})
                .then(function (response) {
                    let blob = response.data
                    let reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onload = (e) => {
                        let a = document.createElement('a')
                        a.download = `fileName.pdf`
                        a.href = e.target.result
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    }
                }).catch(function (error) {
                    console.log(error);
                });

        },
        loadValB: async function(){
            var valueB = [];
            await axios
            .get('https://api.safered.co/api/getBatteryInfo/' + this.$route.params.idChart)
            .then((response) => {
                response.data.forEach((element) => {
                valueB.push(element.value);
                })
            }).catch((e) =>{
                console.log(e);
            });
            var numT = parseFloat(valueB[0]);
            this.needleValue =  Math.round((numT/20)*100);
            this.options.arcDelimiters = [Math.round((numT/20)*100)];
            this.options.centralLabel = Math.round((numT/20)*100)+'%';
            this.valorBattry = valueB[0]+" v"
            if(numT > 12){
                this.options.arcColors = ['green']
            }else if(numT < 12){
                this.options.arcColors = ['yellow']
            }else if(numT < 11){
                this.options.arcColors = ['red']
            }
            this.cargueB = true;
        },
        loadUltimaActualizacion: async function(){
            var fechaAct = ""
            var tempO = {
                idPoint: this.$route.params.idChart,
            };            
            await axios
                .post("https://api.safered.co/api/getlastUpdateForId", tempO)
                .then(function (result) {
                    fechaAct = result.data.fecha;
                }).catch(function (error) {
                    console.log(error);
            });
            this.fechaA = fechaAct;
        }

    },
    watch: {
        fileView: function(){
            this.fileView.promise.then(pdf => {
                this.numPages = pdf.numPages;
            });
        },
        ilustracion_sitio: function(){
            self = this
            toBase64(this.ilustracion_sitio)
                .then((value) => {
                    self.img1 = value;
                })
        },
        ubicacion_galgas: function(){
            self = this
            toBase64(this.ubicacion_galgas)
                .then((value) => {
                    self.img2 = value;
                })  
        }
    },

    
}
/*eslint-enable */
</script>

<style>

    #ChardOrigin div.gauge_contain{
        height: 65px;
        width: auto;
    }

    #ChardOrigin  div.content_scroll_1{
        overflow-y: scroll!important;
        height: 510px!important;
        background: gray;
        display: flex; 
        flex-direction: row; 
        align-items: center; 
        justify-content: center;
    }

    #ChardOrigin div.content_scroll{
        overflow-y: scroll!important;
        height: 510px!important;
        background: gray;
    }

    #ChardOrigin div.windows{
        background-color: #3c6478;
        padding-top: 6px;
        margin-bottom: 0px;
    }

    #ChardOrigin i.info{
        color: white;
        cursor: pointer;
        background-color: transparent!important;
    }

    #ChardOrigin .column_icon{
        align-items: center;
        display: flex;
        height: 2.25rem;
    }

    #ChardOrigin .column_icon_1{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
    }

    #ChardOrigin .column_icon_2{
        align-items: center;
        display: flex;
        justify-content: end;
    }

    #ChardOrigin .container{
        background-color: white;
        width: 100%;
        padding-right: 8px;
        padding-left: 8px;
        margin-right: auto;
        margin-left: auto;
    }

    #ChardOrigin .tab_prop{
        background-color: #F5F6F7;  
    }

    #ChardOrigin ul#tab__BV_tab_controls_.nav.nav-tabs{
        background-color: white!important;
    }

    #ChardOrigin .container_prop{
        background-color: #F2F2F2;
    }

    #ChardOrigin .ribbon-button {
        height: auto;
        min-height: 64px;
        width: auto;
        padding: 4px;
        margin: 0;
        cursor: pointer;
        min-width: 0;
        text-align: center;
        border: 1px solid transparent;
        background-color: #f5f6f7;
        outline: none;
        position: relative;
        padding-right: 8px;
    }

    #ChardOrigin .ribbon-button:hover{
        border-color: #a4cef9;
        background-color: rgba(164, 206, 249, 0.2);
    }

    #ChardOrigin .ribbon-button .icon * {
        height: 32px;
        max-width: 32px;
        font-size: 26px;
        line-height: 32px;
    }

    #ChardOrigin .ribbon-button .icon + .caption {
        margin-top: 8px;
        font-size: 12px;
        display: block;
        text-align: center;
    }

    #ChardOrigin .menu_windows{
        align-items: center;
        display: flex;
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
    }

    #ChardOrigin .menu_windows .group {
        padding: 0 4px 24px;
        display: -webkit-box;
        display: flex;
        cursor: default;
        background-color: inherit;
        position: relative;
        -webkit-box-align: start;
        align-items: flex-start;
        align-content: flex-start;
    }

    #ChardOrigin .menu_windows .group .title {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 20px;
        line-height: 24px;
        border-top: 1px solid #dadbdc;
        white-space: nowrap;
    }

    #ChardOrigin .menu_windows .group .title .text{
        font-size: 11px!important;
        text-align: center;
    }

    #ChardOrigin div.card.card_windows{
        box-shadow: 0 0px 0px rgb(0 0 0 / 0%)!important;
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 0.0rem;
    }

    #ChardOrigin .card_windows{
        background-color: white!important;
    }

    #ChardOrigin div.row.icons_view{
        display: flex!important;
        margin-right: -15px!important;
        margin-left: -15px!important;
        flex-direction: column-reverse!important;
    }

    #ChardOrigin .ribbon-button_1{
        height: auto;
        min-height: 64px;
        width: auto;
        padding: 4px;
        margin: 0;
        cursor: pointer;
        min-width: 0;
        text-align: center;
        border: 1px solid transparent;
        background-color: transparent;
        outline: none;
        position: relative;
        padding-right: 8px;
    }

    #ChardOrigin .ribbon-button_1 .icon * {
        height: 40px;
        max-width: 40px;
        font-size: 26px;
        line-height: 40px;
    }

    #ChardOrigin .ribbon-button_1 .icon + .caption {
        margin-top: 8px;
        font-size: 12px;
        display: block;
        text-align: center;
    }

    #ChardOrigin div.box_nocarga{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: space-around;
    }

    #ChardOrigin p.text_gauge{
        font-size: 8px;
        font-weight: bold;
        fill: rgb(102, 102, 102);
    }
</style>