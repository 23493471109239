<template>
    <v-row style="margin-left: 5px; margin-right: 5px; margin-top: 8px;">
        <v-col cols="12">
            <v-row>
                <v-card background-color="white" style="width: -webkit-fill-available; margin-bottom: 20px;">
                    <menuM :projects="projects" :dataGeo="dataGeo" :dataMpBits="dataMpBits" 
                        :rolesActualU="rolesActualU" :idProject="idProject" :folderGoogle="folderGoogle"
                        :listCodeP="listCodeP" :finishLoad="finishLoad" :valueLoad="valueLoad"
                        :policyCanvas="policyCanvas" :itemsGoogle="itemsGoogle"
                        />
                    <bodyMain :dataMpBits="dataMpBits" :projects="projects" :dataGeo="dataGeo" :dataPoints="dataPoints" 
                        :polygonsLoaded="polygonsLoaded" :linesLoaded="linesLoaded" 
                        :typeDocument="typeDocument" :dataPointsMpBits="dataPointsMpBits"
                        :polygonsLoadedMpBits="polygonsLoadedMpBits" :dateFilt="dateFilt"
                        :dataCanvasImage="dataCanvasImage" :tagsAndFiles="tagsAndFiles" 
                        :treeDirectories="treeDirectories" :dataDrawLienzoG="dataDrawLienzoG"
                        :dataDrawLienzoI="dataDrawLienzoI" :changeGeo="changeGeo" :changeData="changeData" :changeMpbits="Mpbits"
                        :rolesActualU="rolesActualU" :threeGoogle="threeGoogle" 
                        :folderGoogle="folderGoogle" :listCodeP="listCodeP"
                        :valueLoadP="valueLoadP" :finishloadP="finishloadP" :dataObjectP="dataObjectP"
                        :dataLinks="dataLinks" :businessList="businessList"/>
                </v-card>
            </v-row>
        </v-col>

        <v-snackbar
            v-model="NoticreateC"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha creado el <strong>Canvas</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NoticreateC = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="NotideleteC"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha eliminado el <strong>Canvas</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NotideleteC = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="NotiFail"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            No se puedo realizar la acción

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="NotiFail = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-row>
</template>
<script>

import api from '../../../api/index.js'

import menuM from "./menu/menuM.vue"
import bodyMain from "../component_Main/body_main/BodyMain.vue"
import iconCanvasSatelital from "../../../assets/img/icons/icon_canvas_gps.svg"
import iconCanvasImage from "../../../assets/img/icons/icon_canvas_img.svg"
import iconCanvasDir from "../../../assets/img/icons/icon_canvas_dir.svg"
import axios from 'axios'

export default {
    name: "mainEstruct",
    components: { 
        menuM,
        bodyMain,
    },
    data() {
        return {
            iconCanvasSatelital,
            iconCanvasImage,
            iconCanvasDir,
            NotiFail: false,
            NoticreateC: false,
            NotideleteC: false,
            projects: [],
            dataDrawLienzoG: [],
            dataGeo: [],
            dataPoints: [],
            dataFig: [],
            polygonsLoaded: [],
            linesLoaded: [], 
            errorAlert: false,
            typeDocument: [],
            lisTagInfo: [],
            dataMpBits: [],
            dataPointsMpBits: [],
            polygonsLoadedMpBits: [],
            dataDrawLienzoI: [],
            dataCanvasImage: [],
            dateFilt: [],
            tagsAndFiles: {},
            project: {},
            dataPointsRebuild: [],
            treeDirectories: [],
            changeGeo: 0,
            changeData: 0,
            Mpbits: 0,
            threeGoogle: null,
            idProject: null,
            folderId: null,
            folderGoogle: [],
            listCodeP: [],
            finishLoad: true,
            valueLoad: 0,
            finishloadP: true,
            valueLoadP: 10,
            dataPrimaryS: [],
            dataObjectP: {},
            currentUser: null,
            policyCanvas: [],
            itemsGoogle: null,
            dataLinks: [],
            listCanvas: [],
            businessList: []
        };
    },
    props: ["user", "rolesActualU" ],
    mounted: async function(){
        var self = this
        this.currentUser = JSON.parse(localStorage.user)
        this.loadProjects()
        window.addEventListener('storage', function(e) {
            var data = JSON.parse(e.newValue)
            if(e.key === 'info_event') {
                self.$store.commit("setRefreshDrawGeo",  data.refreshDrawGeo);
                self.$store.commit("setRefreshDataGeo",  data.refreshDataGeo);
                self.$store.commit("setRefreshDGeneral",  data.refreshDGeneral);
            }   
        });
    },
    methods:{
        getTreePathFromExtern: async function () {
            var dt;
            var array = []
            var self = this
            var obj = {
                idUser: JSON.parse(localStorage.user).id,
                idProject: this.idProject,
                emailClient: JSON.parse(localStorage.user).clientInfo.emailContacto
            };

            const config = {
                headers: { Authorization: `Bearer ${this.currentUser.token}` },
            };

            await axios
                .post("https://microservices.safered.co/api/getStorageForProjectList", obj, config)
                .then(function (data) {
                    self.folderId = data.data.GOOGLE_API_FOLDER_ID;
                    dt = data.data.docProjects;
                })
                .catch(function (e) {
                    console.log(e.message);
                });
                
                var projectName = self.$store.state.dataInit.projectName

                if(dt != undefined){
                    array = [
                        {
                            "text": projectName,
                            "value": projectName,
                            "id": self.folderId,
                            "icon": "",
                            "opened": true,
                            "parent": self.folderId,
                            "children": JSON.parse(JSON.stringify(dt))
                        }
                    ]
                }
                this.folderGoogle =  array;
            
            await axios
                .post("https://microservices.safered.co/api/getStorageProject", obj, config)
                .then(function (data) {
                    self.folderId = data.data.GOOGLE_API_FOLDER_ID;
                    dt = data.data.docProjects;
                })
                .catch(function (e) {
                console.log(e.message);
                });
                
                //this.data = await JSON.parse(JSON.stringify(dt));

                array = [
                    {
                        "text": projectName,
                        "value": projectName,
                        "id": self.folderId,
                        "icon": "",
                        "opened": true,
                        "parent": self.folderId,
                        "children": JSON.parse(JSON.stringify(dt))
                    }
                ]

                this.itemsGoogle = await array;
        },
        getFolderCloud: async function(){
            let listCode = null
            var obj = {
                idProject: this.idProject
            };

            const config = {
                headers: { Authorization: `Bearer ${this.currentUser.token}` },
            };

            await axios
                .post("https://microservices.safered.co/api/GetFolderCloudFromProject", obj, config)
                .then(function (data) {
                    listCode = data.data.codeP
                })
                .catch(function (e) {
                    console.log(e.message);
                });
            
            this.listCodeP = listCode
        },
        GettagsAndFiles: async function (idProject) {
            var tagsFiles = null
            this.dateFilt = []
            this.tagsAndFiles = {}
            var self = this

            await api
                .request("post", "/list-tags-forProject/" + idProject)
                .then((data) => {
                    tagsFiles = data.data
                    this.dateFilt = tagsFiles;
                })
                .catch(function (e) {
                    console.log(e);
                });
                

            if (tagsFiles != null) {
                const groupByCategory = tagsFiles.reduce((group, tagFile) => {
                const { namePoint } = tagFile;
                group[namePoint] = group[namePoint] ?? [];
                group[namePoint].push(tagFile);
                return group;
                }, {});

                this.tagsAndFiles = groupByCategory
                console.log(groupByCategory)
            }
            
            const config = {
                headers: { Authorization: `Bearer ${this.currentUser.token}` },
            };
            var obj = {
                id: idProject,
                idUser: JSON.parse(localStorage.getItem("user")).id,
                category: "idProject"
            }

            var resDGoogle = null
            await axios
            .post("https://microservices.safered.co/api/getLinkFile", obj, config)
            .then(function(data){
                resDGoogle = data.data
            })
            .catch(function(e){
                console.log(e)
            })

            if(resDGoogle != null){
                await resDGoogle.map(function(element){
                    if(element.path != undefined){
                        element = {
                            ...element,
                            title: element.text,
                            relacion:{
                                original: {
                                    Hijos: element.relacion.Hijos,
                                    Padres: element.relacion.Padres
                                }
                            }
                        }
                        self.dateFilt.push(element)
                    }
                })
            }
            console.log(this.threeGoogle)
            console.log(resDGoogle)
            this.threeGoogle = resDGoogle

        },
        loadProjects: async function(){
            this.valueLoadP = 50
            var index = 0
            var projectsL = []
            var empresas = []

            await api
            .request(
            "post",
            "/getProjectsForUser/" + JSON.parse(localStorage.getItem("user")).id
            )
            .then((response) => {
                Object.values(response.data).forEach((element) => {
                    console.log(element[4])
                    projectsL.push({
                        id: element[0].id,
                        name: element[0].name,
                        company_name: element[0].company_name,
                        year: element[0].year,
                        created_at: element[0].created_at,
                        description: element[0].description,
                        creator: element[2].creator,
                        users: element[3].users,
                        id_empresa: element[4].empresa.id != undefined ? element[4].empresa.id : 0
                    });
                });

                var data = Object.values(response.data)
                empresas = [...new Set(data.map(item => item[4].empresa))];
            })
            .catch((error) => {
                console.log(error);
            });
            this.valueLoadP = 100
            for (const project of projectsL) {
                var obj = await this.getProject(project.id)
                var arrayIcons = []
                obj.canvas.split(",").forEach(element => {
                    if(element == 'api-geo'){
                        arrayIcons.push(this.iconCanvasSatelital)
                    }
                    else if(element == 'back-img'){
                        arrayIcons.push(this.iconCanvasImage)
                    }
                    else if(element == 'directory'){
                        arrayIcons.push(this.iconCanvasDir)
                    }
                });
                
                projectsL[index].canvas = arrayIcons
                index++
            }

            var obj_us = {
                idUser: JSON.parse(localStorage.getItem("user")).id
            }
            await api
            .request(
            "post",
            "/imagenProjects/", obj_us
            )
            .then((response) => {
                projectsL.map( function(data, index){
                    var result = response.data.imgProject.filter(obj => obj.project_id == data.id);
                    if(result.length > 0){
                        projectsL[index] = {
                            ...projectsL[index],
                            path_img: result[0].path
                        }
                    }    
                })
                console.log(projectsL)
            })
            .catch((error) => {
                console.log(error);
            });

            this.businessList = empresas
            this.projects = projectsL
            this.finishloadP = false
        },
        getProject: async function (id) {
            var projectsTmp = [];
            await api
            .request("get", "/getProjectForId/" + id)
            .then((data) => {
                projectsTmp = data.data
            })
            .catch(function (e) {
                console.log(e)
                this.errorAlert = true
            });
            this.project = projectsTmp.project
            return projectsTmp.project
        },
        getDataImage: async function (id) {
            var canvasImage = [];
            await api
                .request(
                "get",
                "/getCanvasImage/" + id
                )
                .then((response) => {
                    canvasImage = response.data.canvas;
                }).catch(function (e) {
                    this.errorAlert = true;
                    console.log(e);
                });
            this.dataCanvasImage = canvasImage;
            this.dataDrawLienzoI = [];

            if(this.dataCanvasImage.canvas != undefined){
                for (let i = 0; i < this.dataCanvasImage.canvas.length; i++) {
                    var data = this.dataCanvasImage.canvas[i].id;
                    var nombre = this.dataCanvasImage.canvas[i].name;
                    this.dataDrawLienzoI.push({
                        value: data,
                        text: nombre
                    });
                }
            }
            

        },
        getDataMpBits: async function(id) {
            var data = null
            this.dataPointsMpBits = []
            this.polygonsLoadedMpBits = []

            await api
            .request(
                "get",
                "/getDataCanvasMapaBitsSFRD/" + id
            )
            .then((response) => {
                data = response.data;
            }).catch(function (error) {
                console.log(error);
                this.errorAlert = true;
            });
            this.dataMpBits = data

            for(let i=0 ;i < this.dataMpBits.length; i++){
                this.dataPointsMpBits.push(this.dataMpBits[i].Puntos)
                this.polygonsLoadedMpBits.push(this.dataMpBits[i].Figuras);
                this.listCanvas.push(this.dataMpBits[i].Name)
            }

        },
        getCanvas: async function(id){
            var self = this
            self.dataDrawLienzoG = [],
            self.dataGeo = [],
            self.dataPoints = [],
            self.dataFig = [],          

            await api
            .request(
                "get",
                "/getDataCanvasSatelital/" + id
            )
            .then((response) => {
                self.dataGeo = response.data;
            }).catch(function (error) {
                console.log(error)
                self.errorAlert = true
            });
            
            for (let i = 0; i < self.dataGeo.length; i++) {
                self.dataPoints.push(self.dataGeo[i].Puntos)
                self.dataFig.push(self.dataGeo[i].Figuras)
            }
            self.dataDrawLienzoG = [];
            

            for (let i = 0; i < self.dataGeo.length; i++) {
                var data = self.dataGeo[i].IdCanvas
                var nombre = self.dataGeo[i].Name
                self.dataDrawLienzoG.push({
                    value: data,
                    text: nombre
                });
            }

            if(self.dataGeo.length > 0){
                var obj = {
                    "idP": self.dataGeo[0].IdCanvas,
                    "tCanvas": 0
                }

                await api
                .request(
                    "post",
                    "/getSecondaryCanvas", obj
                )
                .then((response) => {
                    self.dataPrimaryS.push({
                        geo: response.data.array
                    })
                }).catch(function (error) {
                    console.log(error)
                });


                var obj1 = {
                    idCanvas: self.dataGeo[0].IdCanvas
                }

                await api
                .request(
                    "post",
                    "/listObjectsForCanvas", obj1
                )
                .then((response) => {

                    self.dataObjectP = {
                        areas: response.data.areas,
                        puntos: response.data.puntos,
                    }
                }).catch(function (error) {
                    console.log(error)
                });
            }

            console.log(this.dataGeo)

        },
        loadFigures(){
            this.polygonsLoaded = []
            this.linesLoaded = []

            for (let i = 0; i < this.dataFig.length; i++) {
                var polygonsL = []
                var linesL = []
                for (let j = 0; j < this.dataFig[i].length; j++) {
                    
                    var figs = this.dataFig[i][j];
                    if (figs.type == "polyline") {
                        linesL.push(JSON.parse(figs.points))
                    }
                    if(figs.BackColor != null){
                        if (figs.type == "polygon") {
                            polygonsL.push({
                                id: figs.id,
                                name: figs.name,
                                points: JSON.parse(figs.points),
                                colorBack: '#757575',
                                fillColor: figs.BackColor,
                            });
                        }else if(figs.type == "rectangle"){
                            polygonsL.push({
                                id: figs.id,
                                name: figs.name,
                                points: JSON.parse(figs.points),
                                colorBack: '#757575',
                                fillColor: figs.BackColor,
                            });
                        }
                    }else{
                        if (figs.type == "polygon") {
                            polygonsL.push({
                                id: figs.id,
                                name: figs.name,
                                points: JSON.parse(figs.points),
                                colorBack: '#757575',
                                fillColor: '#0571B0',
                            });
                        }else if(figs.type == "rectangle"){
                            polygonsL.push({
                                id: figs.id,
                                name: figs.name,
                                points: JSON.parse(figs.points),
                                colorBack: '#757575',
                                fillColor: '#0571B0',
                            });
                        }
                    }
                }
                this.polygonsLoaded.push(polygonsL)
                this.linesLoaded.push(linesL)
            }
        },
        downloadListTag: async function(id){
            this.lisTagInfo = [];
            this.typeDocument = [];
            await api
            .request(
                "post",
                "/list-tags-project-nversion/" + id
            )
            .then((response) => {
                for(let i=0;i<response.data.length;i++){
                this.typeDocument.push({
                    value: response.data[i].idTag,
                    text: response.data[i].nameTag
                })
                }
                this.lisTagInfo = response.data;
            }).catch((error) =>{
                console.log(error)
                console.log("error importar data list tag")
            });

        },
        activeTree: async function(){
            if (this.project.canvas) {
                this.canvasArray = this.project.canvas.split(",");
            } else {
                this.msj = "Sin canvas";
            }
            if (this.canvasArray) {
                this.canvasArray.forEach((element) => {
                    switch (element) {
                    case "api-geo":
                        this.treeDirectories.push({
                            text: this.project.name,
                            children: [],
                        });

                        this.dataPoints.forEach((element) => {
                            element.forEach(element2 => {
                                this.dataPointsRebuild.push(element2)
                                this.treeDirectories[0].children.push({
                                    text: element2.Codigo,
                                    value: element2.UbigacionGPS,
                                });
                            });
                        });

                        break;
                    case "back-img":

                        this.treeDirectories.push({
                            text: this.project.name,
                            children: [],
                        });

                        this.dataPointsMpBits.forEach((element) => {
                            element.forEach(element2 => {
                                this.dataPointsRebuild.push(element2)
                                this.treeDirectories[0].children.push({
                                    text: element2.Codigo,
                                    value: element2.UbigacionGPS,
                                });
                            });
                        });

                        break;
                    case "directory":
                        break;
                    }
                });
                await this.loadFigures();
            }
        },
        privacityCanvas: async function(id){
            var self = this
            self.policyCanvas = []
            var policyD = this.currentUser.policy

            if(policyD.length > 0){
                policyD.map(function(poli){
                    if(poli.idProject == id){
                        var dataTemp = poli.Canvas.split(',')

                        if(dataTemp[1] == ' x'){
                            self.policyCanvas.push({
                                permission:  parseInt(dataTemp[0]),
                                canva: dataTemp[1].split(/\s+/).join('')
                            })
                        }else{
                            self.policyCanvas.push({
                                permission:  parseInt(dataTemp[0]),
                                canva: parseInt(dataTemp[1])
                            })
                        }
                    }
                })
            }
            
        },
        getLinkProject: async function(id){
            var self = this
            var obj = {
                idProject: id
            }
            await axios
            .post("https://api.safered.co/api/getLinksInElementForCanvas", obj, {
                headers: {
                "Content-Type": "application/json",
                },
            })
            .then((response) => {
                self.dataLinks = response.data.LINKS
            });
            console.log(this.dataLinks)
        },
        refreshDrawG: async function(id){
            await this.getCanvas(id)
            await this.GettagsAndFiles(id)
            await this.getLinkProject(id)
            this.loadFigures()
            await this.downloadListTag(id)
            await this.getDataImage(id)
            this.changeGeo++
        },
        refreshDataG: async function(id){
            await this.getLinkProject(id)
            await this.GettagsAndFiles(id)
            await this.downloadListTag(id)
            this.changeData++
        },
        refreshMpbits: async function(id){
            await this.getDataMpBits(id)
            await this.getDataImage(id)
            await this.GettagsAndFiles(id)
            await this.downloadListTag(id)
            await this.getLinkProject(id)
            this.Mpbits++
        }
    },
    watch:{
        '$store.state.dataInit': async function(){
            this.finishLoad = true
            this.valueLoad = 10
            this.idProject = this.$store.state.dataInit.idProject

            await this.getFolderCloud()
            await this.getTreePathFromExtern()
            await this.getDataMpBits(this.idProject)
            await this.getCanvas(this.idProject)
            await this.getLinkProject(this.idProject)
            this.valueLoad = 50
            await this.GettagsAndFiles(this.idProject)
            await this.loadFigures()
            this.valueLoad = 100
            await this.downloadListTag(this.idProject)
            await this.getDataImage(this.idProject)
            await this.privacityCanvas(this.idProject)
            
            this.finishLoad = false
        },
        '$store.state.refreshDrawGeo': async function(){
            var id = await this.$store.state.dataInit.idProject
            var self = this
            setTimeout(function() {
                self.refreshDrawG(id)
            }, 2500)
        },
        '$store.state.refreshDataGeo': async function(){
            var id = await this.$store.state.dataInit.idProject
            var self = this

            setTimeout(function() {
                self.refreshDataG(id)
            }, 2500)
        },
        '$store.state.refreshImage': async function(){
            var id = await this.$store.state.dataInit.idProject
            var self = this
            setTimeout(function() {
                self.refreshMpbits(id)
            }, 2500)
        },
        '$store.state.notiCreateC': function(){
            this.NoticreateC = true
        },
        '$store.state.notiDeleteC': function(){
            this.NotideleteC = true

            // this.$store.commit("setPosicionselectC", this.$store.state.restoreGeo.posicionG)
            // this.$store.commit("setPosicionselectCE", this.$store.state.restoreGeo.posicionG)
            // this.$store.commit("setDataselectC", this.$store.state.restoreGeo.dataGeo)             
        },
        '$store.state.notiFail': function(){
            this.NotiFail = true
        },
        project: async function(){
            await this.activeTree()
        },
        lisTagInfo: function(){
            this.$store.commit("setDatalistTag", this.lisTagInfo);
        },
        '$store.state.refreshProject': async function(){
            this.loadProjects();
        }
    },
}
</script>
<style>
    
</style>
