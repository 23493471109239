<template>
  <div></div>
</template>
<script>
/*eslint-disable */
// import VJstree from "vue-jstree";
import findPoint from "../../../../../helpers";
//api.request

export default {
  name: "directorioVista",
  components: {
    // VJstree,
  },
  data() {
    return {
      data: [],
      treeDirectories: [],
      searchText: "",
    };
  },
  props:
    ['polygonsLoaded', 'tagsAndFiles', 'nameProject', 'treeDirectoriesIndex', 'polygonsLoadedIndex',
      'dataVista', 'threeGoogle', 'typeCanvas', 'linesLoaded', 'contanintD',  'searchData' ],
  created() {

    var arr = [
      {
        text: "",
        children: [],
      },
    ];
    //treeDirectoriesIndex puntos
    //polygonsLoaded
    //polygonsLoadedIndex

    this.treeDirectoriesIndex.forEach((element) => {

      if (element.Codigo != "NA" && element.UbigacionGPS != "NA") {
        arr[0].children.push({
          text: element.Codigo,
          value: element.UbigacionGPS,
        });
      } else {
        arr[0].children.push({
          text: element.Codigo,
          value: element.Codigo,
          areaMpBits: element.AreaMpBits
        });
      }
    });

    this.treeDirectories = arr;


    if (this.treeDirectories) {
      var obj = {
        text: this.nameProject,
        children: [],
        dragDisabled: false,
        "show-checkbox": false,
      };

      this.data.push(obj);
    }
    this.formatePolygons();
  },
  methods: {
    formatePolygons: async function () {
      if (this.treeDirectories[0].children.length > 0) {
        var objTree = [];
        if (Object.keys(this.tagsAndFiles).length > 0) {
          for (const key in this.tagsAndFiles) {
            if (Object.hasOwnProperty.call(this.tagsAndFiles, key)) {
              const files = this.tagsAndFiles[key];
              files.forEach((file) => {
                var keyFix;
                if (key == "NaN") {
                  if (file.idVistaMpBits !== "NaN") {
                    keyFix = file.idVistaMpBits;
                  } else if (file.idVistaGeo !== "NaN") {
                    keyFix = file.idVistaGeo;
                  } else if (
                    file.idArea !== "NaN" &&
                    file.idArea !== "0" &&
                    file.idArea !== 0
                  ) {
                    keyFix = file.idArea;
                  }
                } else {
                  keyFix = key;
                }

                let filt = objTree.filter(item => item.text == file.title && item.parent == file.parent)
                if (filt.length == 0) {
                  objTree.push({
                    text: file.title,
                    icon: "fa fa-file-o",
                    opened: false,
                    children: [],
                    parent: keyFix,
                    idFile: file.idFile,
                    tag: file.tag,
                    path: file.path,
                    namePoint: file.namePoint,
                    created_at: file.created_at,
                    relacion: {
                      Hijos: file.relacion.original.Hijos,
                      Padres: file.relacion.original.Padres
                    }
                  });

                }


              });
            }
          }
        }

        console.log(this.dataVista)
        objTree.push({
          value: this.dataVista.Name,
          text: this.dataVista.Name,
          icon: "fa fa-folder",
          opened: false,
          children: [],
          id: this.dataVista.IdCanvas,
          parent: this.nameProject,
        });



        if (this.polygonsLoadedIndex.length > 0) {
          this.polygonsLoadedIndex.forEach((polygonLoaded) => {
            objTree.push({
              text: polygonLoaded.name,
              icon: "fa fa-folder",
              opened: false,
              children: [],
              parent: this.dataVista.Name,
            });
          });
        }

        this.treeDirectories[0].children.forEach((punto) => {
          var namePoint = punto.text;
          var arrayString = punto.value.replace("[", "").replace("]", "");
          arrayString = arrayString.split(",");

          if (this.polygonsLoaded.length > 0) {
            this.polygonsLoaded.forEach((polygons) => {
              if (polygons.length > 0) {
                var conrnersX = [];
                var conrnersY = [];
                var cont = 0;
                polygons.forEach((polygon) => {
                  conrnersX = [];
                  conrnersY = [];
                  var nameArea = polygon.name;

                  if (polygon.points.length > 0 && polygon.points !== "[]") {
                    polygon.points.forEach((arrayPoints) => {
                      conrnersX.push(arrayPoints[0]);
                      conrnersY.push(arrayPoints[1]);
                    });
                    var checkPointInPoly = findPoint(
                      arrayString[0],
                      arrayString[1],
                      conrnersX,
                      conrnersY
                    );
                    if (checkPointInPoly > 0) {
                      let filt = objTree.filter(item => item.text == namePoint)
                      if (filt.length == 0) {
                        objTree.push({
                          text: namePoint,
                          icon: "fa fa-folder",
                          opened: false,
                          children: [
                            {
                              text: namePoint + ".sfr",
                              icon: "fa fa-line-chart icon-state-warning",
                              children: [],
                            },
                          ],
                          type: "point",
                          parent: nameArea,
                        });
                        objTree.push({
                          text: namePoint + ".sfr",
                          icon: "fa fa-area-chart",
                          opened: true,
                          children: [
                            {
                              text: namePoint + ".sfr",
                              icon: "fa fa-line-chart icon-state-warning",
                              children: [],
                            },
                          ],
                          parent: namePoint,
                        })

                      }
                    } else {
                      cont = cont + 1;
                    }
                    if (cont == polygons.length) {
                      let filt = objTree.filter(item => item.text == namePoint)
                      if (filt.length == 0) {
                        objTree.push({
                          text: namePoint,
                          icon: "fa fa-folder",
                          opened: false,
                          children: [
                            {
                              text: namePoint + ".sfr",
                              icon: "fa fa-line-chart icon-state-warning",
                              children: [],
                            },
                          ],
                          parent: this.dataVista.Name,
                        });
                        objTree.push({
                          text: namePoint + ".sfr",
                          icon: "fa fa-area-chart",
                          opened: true,
                          children: [
                            {
                              text: namePoint + ".sfr",
                              icon: "fa fa-line-chart icon-state-warning",
                              children: [],
                            },
                          ],
                          parent: namePoint,
                        })

                      }
                    }
                  }
                  else {

                    if (cont == 0) {
                      let filt = objTree.filter(item => item.text == namePoint)

                      if (filt.length == 0) {

                        objTree.push({
                          text: namePoint,
                          icon: "fa fa-folder",
                          opened: false,
                          children: [
                            {
                              text: namePoint + ".sfr",
                              icon: "fa fa-line-chart icon-state-warning",
                              children: [],
                            },
                          ],
                          parent: punto.areaMpBits,
                          type: "point"
                        });

                        objTree.push({
                          text: namePoint + ".sfr",
                          icon: "fa fa-area-chart",
                          opened: true,
                          children: [
                            {
                              text: namePoint + ".sfr",
                              icon: "fa fa-line-chart icon-state-warning",
                              children: [],
                            },
                          ],
                          parent: namePoint,
                        })
                        cont++

                      }
                    }

                  }
                });
              }
              else {
                let filt = objTree.filter(item => item.text == namePoint)
                if (filt.length == 0) {
                  objTree.push({
                    text: namePoint,
                    icon: "fa fa-folder",
                    opened: false,
                    children: [
                      {
                        text: namePoint + ".sfr",
                        icon: "fa fa-line-chart icon-state-warning",
                        children: [],
                      },
                    ],
                    parent: this.dataVista.Name,
                  })

                  objTree.push({
                    text: namePoint + ".sfr",
                    icon: "fa fa-area-chart",
                    opened: true,
                    children: [
                      {
                        text: namePoint + ".sfr",
                        icon: "fa fa-line-chart icon-state-warning",
                        children: [],
                      },
                    ],
                    parent: namePoint,
                  })

                }
              }
            });
          } else {
            let filt = objTree.filter(item => item.text == namePoint)
            if (filt.length == 0) {
              objTree.push({
                text: namePoint,
                icon: "fa fa-folder",
                opened: false,
                children: [
                  {
                    text: namePoint + ".sfr",
                    icon: "fa fa-line-chart icon-state-warning",
                    children: [],
                  },
                ],
                parent: this.dataVista.Name,
              })

              objTree.push({
                text: namePoint + ".sfr",
                icon: "fa fa-area-chart",
                opened: true,
                children: [
                  {
                    text: namePoint + ".sfr",
                    icon: "fa fa-line-chart icon-state-warning",
                    children: [],
                  },
                ],
                parent: namePoint,
              })

            }


          }

          //FIN POLYGON LOADED
        });


        const arrayToTree = (arr, parent) =>
          arr
            .filter((item) => item.parent === parent)
            .map((child) => ({
              ...child,
              children: arrayToTree(arr, child.text),
            }));

        var docProjects = arrayToTree(objTree, this.nameProject);
        //console.log('docProjects',docProjects)


        var self = this
        self.data[0].children = docProjects;
        var googlethree = await self.threeGoogle

        this.data[0].children.map(function (obj) {
          if (googlethree != null) {

            googlethree.map(function (childrenGoogle) {
              if (childrenGoogle.path != undefined) {

                if (obj.text == childrenGoogle.parent) {
                  obj.children.unshift(childrenGoogle)

                } else {
                  obj.children.map(function (obj2) {

                    var childrenExist = obj2.children.filter(children => children.text == childrenGoogle.text);

                    if (obj2.path == undefined && childrenExist.length == 0) {
                      if (obj2.text == childrenGoogle.parent) {

                        obj2.children.unshift(childrenGoogle)
                      } else {

                        obj2.children.map(function (obj3) {
                          var childrenExist = obj3.children.filter(children => children.text == childrenGoogle.text);

                          if (obj3.path == undefined && childrenExist.length == 0 &&
                            obj3.text == childrenGoogle.parent) {

                            obj3.children.unshift(childrenGoogle)
                          }
                        })
                      }
                    }
                  })
                }
              }
            })
          }
        })

      }
    },
  },
  watch: {
    polygonsLoaded: function () {
      // watch it
      var childrens = [];
      if (this.polygonsLoaded.length > 0) {
        this.polygonsLoaded.forEach((element) => {
          var polygonF = element.points;
          var nameArea = element.name;
          var conrnersX = [];
          var conrnersY = [];
          var obj = {
            text: nameArea,
            children: [],
            opened: false,
          };
          polygonF.forEach((element) => {
            conrnersX.push(element[0]);
            conrnersY.push(element[1]);
          });

          this.treeDirectories.forEach((element) => {
            element.children.forEach((points) => {
              if (points.value) {
                var arrayString = points.value.replace("[", "").replace("]", "");
                arrayString = arrayString.split(",");
                var checkPointInPoly = findPoint(
                  arrayString[0],
                  arrayString[1],
                  conrnersX,
                  conrnersY
                );
                if (checkPointInPoly == 1) {
                  obj.children.push({
                    text: points.text,
                    icon: "fa fa-folder",
                    opened: false,
                    children: [
                      {
                        text: points.text + ".sfr",
                        icon: "fa fa-line-chart icon-state-warning",
                        children: [],
                      },
                    ],
                  });

                  //var listTagsAndFiles = this.GetTagsAndFiles(points.text)
                  if (this.tagsAndFiles[points.text] != null) {
                    var arrayTF = this.tagsAndFiles[points.text];
                    const groupByTag = arrayTF.reduce((group, arrayTFL) => {
                      const { tag } = arrayTFL;
                      group[tag] = group[tag] ?? [];
                      group[tag].push(arrayTFL);
                      return group;
                    }, {});

                    for (const property in groupByTag) {
                      var hijos = [];
                      groupByTag[property].forEach((elementHijo) => {
                        var icon = "";
                        if (
                          elementHijo.title.includes(".jpg") ||
                          elementHijo.title.includes(".png") ||
                          elementHijo.title.includes(".gif")
                        ) {
                          icon = "fa fa-file-image-o";
                        } else if (elementHijo.title.includes(".pdf")) {
                          icon = "fa fa-file-pdf-o";
                        } else if (elementHijo.title.includes(".xlsx")) {
                          icon = "fa fa-file-excel-o";
                        } else if (
                          elementHijo.title.includes(".doc") ||
                          elementHijo.title.includes(".docx")
                        ) {
                          icon = "fa fa-file-word-o";
                        } else {
                          icon = "fa fa-file-o";
                        }

                        hijos.push({
                          text: elementHijo.title,
                          icon: icon,
                          path: elementHijo.path,
                        });
                      });

                      obj.children[obj.children.length - 1].children.push({
                        text: property,
                        icon: "fa fa-folder",
                        opened: false,
                        children: hijos,
                      });
                    }

                    /*this.tagsAndFiles[points.text].forEach(element => {
                        if(element.point === "PD1-3-PZ-1-15m"){
                          console.log(element)
                        }
                      })*/
                  }
                }
              }
            });
          });
          childrens.push(obj);
        });
      }
      this.data[0].children = childrens;
    },
    data: function () {

      if(this.contanintD){
        this.$store.commit("setFindDataFolder", this.data)
      }else if(this.searchData){
        console.log(this.data)
        this.$store.commit("setFindDataCanvas_market", this.data)
      }else{
        this.$store.commit("setFindDataCanvas", this.data[0].children)
        this.$store.commit("setFindDataCanvasName", this.data[0].children)
      }

    },
  },
};
/*eslint-enable */
</script>
<style>

.fa-folder {
  color: #DBC056;
}

.checkNone {
  display: none;
}
</style>