<template lang="">
    <div class="content" style="overflow-x: hidden; overflow-y: scroll; height: 520px;">
        <h5 v-if="!finishloadP" style="margin-top: 35px">
            <b> {{ $t("projects.welcome_projectsM") }} </b>
        </h5>
        <v-sheet
            class="mx"
            elevation="3"
            style="margin-left: 3px; 
            margin-right: 3px; 
            margin-top: 25px"
        >   
            <div v-if="!finishloadP" style="display: flex; justify-content: flex-end;">
                <v-col cols="3" style="padding: 0px; padding-top: 4px;" id="select_Business">
                    <v-select
                        v-model="selectBusiness"
                        :items="dataBusiness"
                        label="Seleccione una empresa para filtrar"
                        outlined
                    ></v-select>
                </v-col>
            </div>
            <v-row v-if="finishloadP" style="margin-top: 50px; margin-bottom: 50px;">
                <div style="height: 320px; display: flex; justify-content: center;
                align-items: center; flex-direction: column;">
                    <v-progress-circular
                        :rotate="360"
                        :size="100"
                        :width="15"
                        :value="valueLoadP"
                        color="#283271"
                    >
                        {{ valueLoadP }}
                    </v-progress-circular>
                    <h5 style="margin-top: 20px;">Cargando información</h5>
                </div>
            </v-row>
            <v-row style="margin-top: 10px; margin-bottom: 30px;" v-if="this.projectsM.length == 0 && !finishloadP">
                <v-col style="display: flex; justify-content: center;" cols="6">
                    <img
                    height="200"
                    width="240"
                    :src="logoSafered" 
                    alt="Logo SAFE NET" />
                </v-col>
                <v-col cols="6" style="display: flex; justify-content: flex-start; align-items: center;">
                    <h5 style="size: 1.5rem; font-weight: 400;">No se encuentra aun asignado 
                    algun proyecto</h5>
                </v-col>
            </v-row>
            <v-row v-if="this.projectsM.length > 0">
                <v-slide-group
                    v-model="model"
                    class="pa-4"
                    show-arrows
                    style="padding-top: 20px!important; padding-bottom: 50px!important;"
                >
                    <v-slide-item
                        v-for="(project, index) in projectsM"
                        :key="index"
                    >
                        <v-card
                            id="card_st"
                            @click="selectProject(project.id, project.name)"
                            class="ma-4"
                            width="350"
                            height="180"
                            outlined
                            elevation="2"
                        >
                            <v-row >
                                <v-col cols="5" style="display: flex;
                                    justify-content: center;
                                    align-items: center; margin-top: 30px;
                                    margin-bottom: 40px;">
                                    <div>
                                        <v-img
                                            v-if="project.path_img != undefined"
                                            width="100"
                                            height="120"
                                            :src="'https://api.safered.co/picture/'+project.path_img"
                                        >
                                        </v-img>
                                        <v-skeleton-loader
                                            v-if="project.path_img == undefined"
                                            type="image"
                                            width="100"
                                            height="100"
                                        ></v-skeleton-loader>
                                    </div>
                                </v-col>
                                <v-col cols="6"
                                    style=" margin-top: 30px;
                                    margin-bottom: 40px;">
                                    <v-row>
                                        <v-col style="height: 53px; width: 80px; padding-bottom: 0px;">
                                            <h6 class="text_large">{{project.name}}</h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col style="height: 70px; width: 100px;padding-top: 0px;">
                                            <p class="extra_text_large">
                                                {{project.description}}
                                            </p>
                                        </v-col>
                                        <v-col class="pt-0">
                                            <v-badge
                                                bordered
                                                color="green"
                                                overlap
                                                dot
                                                v-if="project.nameDocument != undefined"
                                            >
                                                <v-chip
                                                    color="#283271"
                                                    class="chip_text"
                                                    @mouseover="activeHoverDoc(index); previewDoc = true"
                                                    @mouseleave="previewDoc = false"
                                                >
                                                    Documento asociado
                                                </v-chip>
                                            </v-badge>
                                            <v-chip
                                                v-if="project.nameDocument == undefined"
                                                color="#283271"
                                                class="chip_text"
                                                @mouseover="activeHoverDoc(index); previewDoc = true"
                                                @mouseleave="previewDoc = false"
                                            >
                                                Documento asociado
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1" style="display: flex;
                                align-items: flex-start;
                                justify-content: flex-end;">
                                    <i id="icon_info" style="margin-top: 10px; 
                                        margin-right: 5px; color: #283271;"
                                        class="fa-solid fa-circle-info fa-lg"
                                        @mouseover="activeHover(index); preview = true"
                                        @mouseleave="preview = false">
                                    </i>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
            </v-row>
        </v-sheet>
        <v-row style="margin-top: 30px;">
            <p style="text-align: end;"><b>Ultima conexión: </b>{{ultimaCon}}</p>
        </v-row>

        <modalDocumentProject :project="posicionHDoc != null ? projectsM[posicionHDoc] : projects" :hover="hoverDoc"/>
        <modalDetalleProject :project="posicionH != null ? projects[posicionH] : projects" :hover="hover"/>
    </div>
</template>
<script>

import axios from 'axios';
import logoSafered from "@/assets/img/logos/safered-logo-01.png";
import modalDetalleProject from '../../../../modals/ModalDetalleProject.vue'
import modalDocumentProject from '../../../../modals/ModalDocumentProject.vue'
import imgLogo from '../../../../../assets/img/logos/log_brave.png'

export default {
    name:"projectsM",
    components:{
        modalDetalleProject,
        modalDocumentProject
    },
    data(){
        return{
            imgLogo,
            logoSafered,
            hover: false,
            hoverDoc: false,
            posicionHDoc: null,
            model: null,
            projectC: null,
            posicionH: null,
            dateCreated: "",
            created_atP: [],
            ultimaCon: 'N/A',
            projectsM: [],
            projectsMCopy: [],
            finishloadP: true,
            selectBusiness: null,
            dataBusiness: [],
        }
    },
    props:['projects', 'valueLoadP', 'businessList'],
    mounted: async function(){
        await this.detectLogP();
        await this.getListDocumentProjects();
        const fechaActual = new Date(JSON.parse(localStorage.user).timeLogin);
        this.ultimaCon = fechaActual.toLocaleString('es-co');
    },
    methods:{
        filtProyectBusiness: async function(){
            var self = this;

            if(this.projectsMCopy.length == 0){
                this.projectsMCopy = this.projectsM;
            }
            this.projectsM = this.projectsMCopy.filter(obj => obj.id_empresa == self.selectBusiness);
        },
        addNotBusiness: async function(){
            var self = this

            this.dataBusiness.push({
                text: "Proyectos no asociados",
                value: 0
            })
            this.businessList.map(function(data){
                if(data.name != undefined){
                    self.dataBusiness.push({
                        text: data.name,
                        value: data.id
                    })
                }
            })
            this.selectBusiness = this.dataBusiness[this.dataBusiness.length-1].value;
        },
        getListDocumentProjects: async function(){
            var dataList = null;
            var self = this

            var data = {
                "user_id": JSON.parse(window.localStorage.user).client_id,
                "id_tag": 93,
            };	
            await axios
            .post('https://api.safered.co/api/getListDocumentsProject', data)
            .then((response) => {
                dataList = response.data;
            })
            .catch(function (e) {
                console.log(e);
            });

            console.log(dataList);
            this.projectsM = [];

            if(dataList != null && dataList.length > 0){
                this.projects.map(function(element){
                    var found = dataList.find(res => res.project_id == element.id)
                    console.log(found)
                    if(found != undefined){
                        element = {
                            ...element,
                            nameDocument: found.title
                        }
                    }
                    self.projectsM.push(element);
                })
            }else{
                self.projectsM = this.projects;
            }
            this.finishloadP = false;
        },
        detectLogP: async function(){
            var self = this

            this.projectC = this.projects
            await this.projects.map(async function(obj, index){
                await obj.canvas.map( async function(obj1, index1){
                    if(obj1.includes('_gps')){
                        self.projectC[index].canvas[index1] =  {
                            logo: await obj1,
                            tooltip: "Estructura de canvas GPS"
                        }
                    }else if(obj1.includes('_img')){
                        self.projectC[index].canvas[index1] =  {
                            logo: await obj1,
                            tooltip: "Estructura de canvas Mapas de Bits"
                        }
                    }else if(obj1.includes('_dir')){
                        self.projectC[index].canvas[index1] =  {
                            logo: await obj1,
                            tooltip: "Estructura de directorios"
                        }
                    }
                })
            })

            if(this.projects.length > 0)
            self.created_atP = []
            await this.projects.map(function(obj){
                var temp = obj.created_at.split(" ")
                self.created_atP.push(temp[0])
            })
            console.log(this.created_atP)

        },
        selectProject: async function(id, name){
            var data = {
                hover: false,
                hoverDoc: false,
                projectName: name,
                idProject: id,
                selectProject: false,
                projectsM: false,
                projectSelect: true,
                preview: false,
                previewDoc: false
            }
        
            this.$store.commit("setdataInit", data);
        },
        activeHoverDoc(index){
            var self = this
            self.posicionHDoc = index;
            setTimeout(function() {
                self.modeOnDoc();
            }, 1500)
        },
        activeHover(index){
            var self = this
            self.posicionH = index
            setTimeout(function() {
                self.modeOn();
            }, 1500)
        },
        modeOnDoc(){
            if(this.previewDoc == true){
                this.hoverDoc = true;
            }
        },
        modeOn(){
            if(this.preview == true){
                this.hover = true;
            }
        }
    },
    watch:{
        projects: async function(){
            await this.detectLogP();
            await this.getListDocumentProjects();
            await this.addNotBusiness();
            console.log(this.projects)
        },
        hover: function(){
            this.$store.commit("setdataHoverP", true);
        },
        hoverDoc: function(){
            this.$store.commit("setdataHoverPDoc", true);
        },
        '$store.state.dataHoverP': function(){
            if(this.$store.state.dataHoverP == false){
                this.hover = this.$store.state.dataHoverP
            }
        },
        '$store.state.dataHoverPDoc': function(){
            if(this.$store.state.dataHoverPDoc == false){
                this.hoverDoc = this.$store.state.dataHoverPDoc
            }
        },
        selectBusiness: async function(){
            await this.filtProyectBusiness()
        }
    }
}
</script>
<style>

    #select_Business div.v-text-field__details{
        display: none!important;
    }

    #select_Business div.v-input__slot{
        padding: 0 5px;
    }

    i#icon_info:hover{
        color: #FFFF00;
    }

    #card_st:hover{
        transition: 0.3s all;
        -webkit-transform:scale(1.1);
        transform:scale(1.1);
    }

    h6.text_large{
        font-size: 0.95rem!important;
        font-weight: 400!important;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px!important;
        margin-top: 0px!important;
    }

    p.extra_text_large{
        font-size: 0.75rem!important;
        font-weight: 400!important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px!important;
    }

    .content::-webkit-scrollbar {
        --webkit-appearance: none;
    }

    .content::-webkit-scrollbar:vertical {
        width:10px;
    }

    .content::-webkit-scrollbar-button:increment,.content::-webkit-scrollbar-button {
        display: none;
    } 

    .content::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 2px solid #f1f2f3;
    }

    .content::-webkit-scrollbar-track {
        border-radius: 10px;  
    }

    span.chip_text span{
        color: white;
        font-size: 13px;
    }
</style>