<template>
  <v-row>
    <v-col vs-type="flex" vs-justify="center" vs-align="center" w="12">
      <div style="margin: auto" v-if="selectSection">
        <div class="d-block text-center">
          <h5>Seleccione el tipo de cliente</h5>
        </div>
        <v-row>
          <v-col style="display: flex; justify-content: flex-end;">
            <button
              @click="viewPNatural"
              type="button"
              class="btn btn-default btn-lg"
            >
              <span class="fa fa-user" aria-hidden="true"></span>
              Persona natural
            </button>
          </v-col>
          <v-col style="display: flex; justify-content: flex-start;">
            <button
              @click="viewPJuridica"
              type="button"
              class="btn btn-default btn-lg"
            >
              <span class="fa fa-building" aria-hidden="true"></span>
              Persona juridica
            </button>
          </v-col>
        </v-row>
      </div>

      <b-card v-if="s_pnatural" style="margin: 5px">
        <v-row w="2">
          <v-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <v-row>
              <v-col style="display: flex; justify-content: center;">
                <button
                  @click="viewPNatural"
                  type="button"
                  class="btn btn-default btn-lg"
                >
                  <span class="fa fa-user" aria-hidden="true"></span>
                  Persona natural
                </button>
              </v-col>
            </v-row>
          </v-col>
          <v-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <v-row>
              <v-col style="display: flex; justify-content: center;">
                <button
                  @click="viewPJuridica"
                  type="button"
                  class="btn btn-default btn-lg"
                >
                  <span class="fa fa-building" aria-hidden="true"></span>
                  Persona juridica
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <form-wizard
          shape="circle"
          title="Registrar persona natural"
          subtitle="Ingrese la información del cliente"
          color="#1565c0"
          nextButtonText="Siguiente"
          style="height:520px;"
          backButtonText="Volver"
          finishButtonText="Finalizar"
          @on-complete="savePerNatural"
        >
          <tab-content
            title="Información personal"
            :before-change="validateInfPers"
          >
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="nameClientInput">Nombres</label>
                <input
                  v-model="pNatural.nombreContacto"
                  type="text"
                  class="form-control"
                  id="nameClientInput"
                  placeholder="Escriba nombres completos"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput">Apellidos</label>
                <input
                  v-model="pNatural.apellidosContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba apellidos completos"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectTypeDocId">Tipo de documento</label>
                <select
                  v-model="pNatural.tipoDocumentoContacto"
                  class="form-control"
                  id="selectTypeDocId"
                  aria-label="Default select example"
                >
                  <option selected disabled>
                    Seleccione el tipo de documento
                  </option>
                  <option value="Cedula de ciudadania">
                    Cedula de ciudadania
                  </option>
                  <option value="Cedula de extranjeria">
                    Cedula de extranjeria
                  </option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="DNI">DNI</option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="nameProjectInput"
                  >Numero de documento de identificación</label
                >
                <input
                  v-model="pNatural.numeroDocumentoContacto"
                  type="number"
                  class="form-control"
                  id="lastNameProjectInput"
                  placeholder="Numero de identificación"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="lastNameClientInput">Email</label>
                <input
                  v-model="pNatural.emailContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba un email de contacto"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput">Numero de telefono</label>
                <input
                  v-model="pNatural.numeroTelefonoContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba un numero telefonico de contacto"
                />
              </v-col>
            </v-row>
          </tab-content>
          <tab-content
            title="Información de residencia"
            style="width: 100%"
            :before-change="validateInfResist"
          >
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectUsers">Pais de residencia</label>
                <select
                  @change="onChangeCountrie()"
                  v-model="pNatural.PaisContacto"
                  class="form-control"
                  id="selectUsers"
                  aria-label="Default select example"
                >
                  <option selected>
                    Seleccione el pais de residencia del cliente
                  </option>
                  <option
                    v-for="(item, index) in countries"
                    :key="index"
                    :id="index"
                    :value="item.country"
                  >
                    {{ item.country }}
                  </option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="selectUsers">Estado de residencia</label>
                <select
                  @change="onChangeStates()"
                  :disabled="validatedCountry"
                  v-model="pNatural.estadoContacto"
                  class="form-control"
                  id="selectUsers"
                  aria-label="Default select example"
                >
                  <option selected>
                    Seleccione el estado de residencia del cliente
                  </option>
                  <option
                    v-for="(item, index) in states"
                    :key="index"
                    :id="index"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectUsers">Ciudad de residencia</label>
                <select
                  :disabled="validatedCountry"
                  v-model="pNatural.ciudadContacto"
                  class="form-control"
                  id="selectUsers"
                  aria-label="Default select example"
                >
                  <option selected>
                    Seleccione la ciudad de residencia del cliente
                  </option>
                  <option
                    v-for="(item, index) in cities"
                    :key="index"
                    :id="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput"
                  >Dirección del domicilio principal</label
                >
                <input
                  v-model="pNatural.direccionContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba una dirección de domicilio"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectUsers">Codigo postal</label>
                <input
                  v-model="pNatural.zipCode"
                  type="text"
                  class="form-control"
                  id="zipCodeClientInput"
                  placeholder="Escriba el codigo postal"
                />
                
              </v-col>
            </v-row>
          </tab-content>
          <tab-content title="Documentación">
            <v-row style="margin-top: 30px; margin-bottom: 30px;">
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <v-file-input
                  v-model="pNatural.docIdTributariaContacto"
                  label="RUT o documento de identificación tributaria :"
                  outlined
                  dense
                  :state="Boolean(pNatural.docIdTributariaContacto)"
                ></v-file-input>
              </v-col>
              <v-col cols="1"></v-col> 
            </v-row> 
          </tab-content>
        </form-wizard>
      </b-card>

      <b-card
        v-if="s_pjuridica"
        style="margin: 5px; overflow: visible; height: 650px"
      >
        <v-row w="2">
          <v-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <v-row>
              <v-col style="display: flex; justify-content: center;">
                <button
                  @click="viewPNatural"
                  type="button"
                  class="btn btn-default btn-lg"
                >
                  <span class="fa fa-user" aria-hidden="true"></span>
                  Persona natural
                </button>
              </v-col>
            </v-row>
          </v-col>
          <v-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <v-row>
              <v-col style="display: flex; justify-content: center;">
                <button
                  @click="viewPJuridica"
                  type="button"
                  class="btn btn-default btn-lg"
                >
                  <span class="fa fa-building" aria-hidden="true"></span>
                  Persona juridica
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <form-wizard
          shape="circle"
          title="Persona Juridica"
          subtitle="Ingrese la información del cliente"
          color="#1565c0"
          nextButtonText="Siguiente"
          style="height:650px;"
          backButtonText="Volver"
          finishButtonText="Finalizar"
          @on-complete="savePerJuridica"
        >
          <tab-content
            title="Información de la compañia"
            style="width: 100%"
            :before-change="validateInfPersJur"
            validateInfPersJurContact
          >
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="nameClientInput">Razón social</label>
                <input
                  v-model="pJuridica.company.razonSocial"
                  type="text"
                  class="form-control"
                  id="nameClientInput"
                  placeholder="Escriba la razón social de la compañia"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput"
                  >Numero de identificación tributaria</label
                >
                <input
                  v-model="pJuridica.company.numIdTributaria"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba numero de identificación tributaria (TAX ID, NIT, entre otros)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectUsers"
                  >Pais de domicilio principal de la empresa</label
                >
                <select
                  @change="onChangeCountrie()"
                  v-model="pJuridica.company.paisCompany"
                  class="form-control"
                  id="selectUsers"
                  aria-label="Default select example"
                >
                  <option selected>
                    Seleccione el pais del domicilio principal de la empresa
                  </option>
                  <option
                    v-for="(item, index) in countries"
                    :key="index"
                    :id="index"
                    :value="item.country"
                  >
                    {{ item.country }}
                  </option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="selectUsers"
                  >Estado de domicilio principal de la empresa</label
                >
                <select
                  @change="onChangeStates()"
                  :disabled="validatedCountry"
                  v-model="pJuridica.company.estadoCompany"
                  class="form-control"
                  id="selectUsers"
                  aria-label="Default select example"
                >
                  <option selected>
                    Seleccione el estado de domicilio principal de la empresa
                  </option>
                  <option
                    v-for="(item, index) in states"
                    :key="index"
                    :id="index"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectUsers"
                  >Ciudad de domicilio principal de la empresa</label
                >
                <select
                  :disabled="validatedCountry"
                  v-model="pJuridica.company.ciudadCompany"
                  class="form-control"
                  id="selectUsers"
                  aria-label="Default select example"
                >
                  <option selected>
                    Seleccione la ciudad de domicilio principal de la empresa
                  </option>
                  <option
                    v-for="(item, index) in cities"
                    :key="index"
                    :id="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput"
                  >Dirección del domicilio principal</label
                >
                <input
                  v-model="pJuridica.company.direccionCompany"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba una dirección de domicilio"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectUsers">Codigo postal</label>
                <input
                  v-model="pJuridica.company.zipCode"
                  type="text"
                  class="form-control"
                  id="zipCodePJurInput"
                  placeholder="Escriba el codigo postal"
                />
                
              </v-col>
            </v-row>
          </tab-content>
          <tab-content
            title="Personal de contacto"
            style="width: 100%"
            :before-change="validateInfPersJurContact"
          >
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="nameClientInput">Nombres</label>
                <input
                  v-model="pJuridica.contact.nombreContacto"
                  type="text"
                  class="form-control"
                  id="nameClientInput"
                  placeholder="Escriba nombres completos"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput">Apellidos</label>
                <input
                  v-model="pJuridica.contact.apellidosContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba apellidos completos"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectTypeDocId">Tipo de documento</label>
                <select
                  v-model="pJuridica.contact.tipoDocumentoContacto"
                  class="form-control"
                  id="selectTypeDocId"
                  aria-label="Default select example"
                >
                  <option selected disabled>
                    Seleccione el tipo de documento
                  </option>
                  <option value="Cedula de ciudadania">
                    Cedula de ciudadania
                  </option>
                  <option value="Cedula de extranjeria">
                    Cedula de extranjeria
                  </option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="DNI">DNI</option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="nameProjectInput"
                  >Numero de documento de identificación</label
                >
                <input
                  v-model="pJuridica.contact.numeroDocumentoContacto"
                  type="number"
                  class="form-control"
                  id="lastNameProjectInput"
                  placeholder="Numero de identificación"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="lastNameClientInput">Email</label>
                <input
                  v-model="pJuridica.contact.emailContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba un email de contacto"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput">Cargo</label>
                <input
                  v-model="pJuridica.contact.cargoContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba el cargo del contacto"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="lastNameClientInput">Numero de telefono</label>
                <input
                  v-model="pJuridica.contact.numeroTelefonoContacto"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba un numero telefonico de contacto"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
              </v-col>
            </v-row>
          </tab-content>
          <tab-content title="Representante legal" style="width: 100%">
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="nameClientInput">Nombres</label>
                <input
                  v-model="pJuridica.repLegal.nombres"
                  type="text"
                  class="form-control"
                  id="nameClientInput"
                  placeholder="Escriba nombres completos"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput">Apellidos</label>
                <input
                  v-model="pJuridica.repLegal.apellidos"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba apellidos completos"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="selectTypeDocId">Tipo de documento</label>
                <select
                  v-model="pJuridica.repLegal.tipoDocumento"
                  class="form-control"
                  id="selectTypeDocId"
                  aria-label="Default select example"
                >
                  <option selected disabled>
                    Seleccione el tipo de documento
                  </option>
                  <option value="Cedula de ciudadania">
                    Cedula de ciudadania
                  </option>
                  <option value="Cedula de extranjeria">
                    Cedula de extranjeria
                  </option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="DNI">DNI</option>
                </select>
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="nameProjectInput"
                  >Numero de documento de identificación</label
                >
                <input
                  v-model="pJuridica.repLegal.numeroDocumento"
                  type="number"
                  class="form-control"
                  id="lastNameProjectInput"
                  placeholder="Numero de identificación"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 2px"
              >
                <label for="lastNameClientInput">Email</label>
                <input
                  v-model="pJuridica.repLegal.email"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba un email de contacto"
                />
              </v-col>
              <v-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                w="5"
                style="margin-left: 60px"
              >
                <label for="lastNameClientInput">Numero de telefono</label>
                <input
                  v-model="pJuridica.repLegal.numeroTelefono"
                  type="text"
                  class="form-control"
                  id="lastNameClientInput"
                  placeholder="Escriba un numero telefonico de contacto"
                />
              </v-col>
            </v-row>
          </tab-content>
          <tab-content title="Documentación">
            <div class="form-group">
              <v-row style="margin-top: 30px; margin-bottom: 30px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                  <v-file-input
                    v-model="pJuridica.company.certificadoTributario"
                    label="RUT o documento de identificación tributaria :"
                    show-size
                    :state="Boolean(pJuridica.company.certificadoTributario)"
                  ></v-file-input>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </div>
          </tab-content>
        </form-wizard>
      </b-card>
    </v-col>
  </v-row>
</template>
<script>
import api from "@/api";
import axios from "axios";

export default {
  name: "create-client-modal",
  components: {},
  data() {
    return {
      viewClient: true,
      viewClientJur: false,
      s_pjuridica: false,
      s_pnatural: false,
      selectSection: true,
      formEdit: {
        id: "",
        email: "",
        name: "",
        lastName: "",
        typeClient: null,
        typeDocument: null,
        numIdClient: 0,
        checked: [],
      },
      typeClients: [
        { text: "Seleccionar tipo de cliente", value: null },
        "Persona Natural",
        "Persona Juridica",
      ],
      typeDocuments: [
        { text: "Seleccionar tipo de documento", value: null },
        "Cedula de ciudadania",
        "DNI",
        "Pasaporte",
        "Cedula Extranjeria",
      ],
      pNatural: {
        tipo: "",
        nombreContacto: "",
        apellidosContacto: "",
        tipoDocumentoContacto: "",
        numeroDocumentoContacto: "",
        emailContacto: "",
        numeroTelefonoContacto: "",
        PaisContacto: "",
        estadoContacto: "",
        ciudadContacto: "",
        direccionContacto: "",
        docIdTributariaContacto: "",
        cargoContacto: "",
        zipCode: ''
      },
      pJuridica: {
        company: {
          razonSocial: "",
          numIdTributaria: "",
          paisCompany: "",
          estadoCompany: "",
          ciudadCompany: "",
          direccionCompany: "",
          certificadoTributario: "",
          zipCode: ''
        },
        contact: {
          tipo: "",
          nombreContacto: "",
          apellidosContacto: "",
          tipoDocumentoContacto: "",
          numeroDocumentoContacto: "",
          emailContacto: "",
          numeroTelefonoContacto: "",
          PaisContacto: "",
          estadoContacto: "",
          ciudadContacto: "",
          direccionContacto: "",
          docIdTributariaContacto: "",
          cargoContacto: "",
        },
        repLegal: {
          nombres: "",
          apellidos: "",
          tipoDocumento: "",
          numeroDocumento: "",
          email: "",
          numeroTelefono: "",
        },
      },

      countries: [],
      validatedCountry: true,
      states: [],
      cities: [],
    };
  },
  methods: {
    loadCountries: async function () {
      await axios
        .get("https://countriesnow.space/api/v0.1/countries/")
        .then((result) => {
          this.countries = result.data.data;
        });
    },
    onChangeCountrie: async function () {
      if (this.pNatural.PaisContacto !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/states", {
            country: this.pNatural.PaisContacto,
          })
          .then((result) => {
            this.states = result.data.data.states;
          });
      } else if (this.pJuridica.company.paisCompany !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/states", {
            country: this.pJuridica.company.paisCompany,
          })
          .then((result) => {
            this.states = result.data.data.states;
          });
      } else {
        console.log("vacio");
      }
    },
    onChangeStates: async function () {
      if (this.pNatural.estadoContacto !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
            country: this.pNatural.PaisContacto,
            state: this.pNatural.estadoContacto,
          })
          .then((result) => {
            console.log(result);
            this.cities = result.data.data;
          });
      } else if (this.pJuridica.company.estadoCompany !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
            country: this.pJuridica.company.paisCompany,
            state: this.pJuridica.company.estadoCompany,
          })
          .then((result) => {
            console.log(result);
            this.cities = result.data.data;
          });
      } else {
        console.log("vacio");
      }
    },
    validateInfPersJurContact: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pJuridica.contact.nombreContacto === "" &&
            this.pJuridica.contact.apellidosContacto === "" &&
            this.pJuridica.contact.tipoDocumentoContacto === "" &&
            this.pJuridica.contact.numeroDocumentoContacto === "" &&
            this.pJuridica.contact.emailContacto === "" &&
            this.pJuridica.contact.cargoContacto === "" &&
            this.pJuridica.contact.numeroTelefonoContacto === ""
          ) {
            reject(
              "Debe registrar la información del contacto antes de continuar."
            );
            alert(
              "Debe registrar la información del contacto antes de continuar."
            );
          } else if (this.pJuridica.contact.nombreContacto === "") {
            reject("Debe ingresar el nombre del contacto de la emrpesa.");
            alert("Debe ingresar el nombre del contacto de la emrpesa.");
          } else if (this.pJuridica.contact.apellidosContacto === "") {
            reject("Debe ingresar el apellido del contacto de la emrpesa.");
            alert("Debe ingresar el apellido del contacto de la emrpesa.");
          } else if (this.pJuridica.contact.tipoDocumentoContacto === "") {
            reject(
              "Debe seleccionar el tipo de documento del contacto de la empresa"
            );
            alert(
              "Debe seleccionar el tipo de documento del contacto de la empresa"
            );
          } else if (this.pJuridica.company.estadoCompany === "") {
            reject(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.ciudadCompany === "") {
            reject(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.direccionCompany === "") {
            reject(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
            alert(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
          } else {
            console.log(this.pJuridica.company);
            resolve(true);
          }
        }, 1000);
      });
    },
    savePerJuridica: async function () {
      if (this.pJuridica.company.certificadoTributario === "") {
        alert(
          "Debe cargar el documento de certificación tributaria del cliente."
        );
      } else {
        console.log(this.pJuridica);
        await api.request("post","/store_p_jur_client",this.pJuridica).then((response)=>{
          alert("Cliente creado exitosamente");
          this.$store.commit("setClientesAdmin", true)
          this.$store.commit("setCreateClients", false)
          if(response.data.status == 200)
            this.pJuridica = {}
        }).catch(function (e) {
            console.log(e);
        });
      }
    },
    viewPNatural() {
      this.s_pjuridica = false;
      this.s_pnatural = true;
      this.selectSection = false;
      (this.viewClient = true), (this.viewClientJur = false);
    },
    viewPJuridica() {
      (this.viewClient = false), (this.viewClientJur = true);
      this.s_pjuridica = true;
      this.s_pnatural = false;
      this.selectSection = false;
    },
    resetView() {
      this.s_pjuridica = false;
      this.s_pnatural = false;
      this.selectSection = true;
    },
    CreateClients: function () {
      this.$refs["my-modal-add-client"].show();
    },
    savePerNatural: async function () {
      let formData = new FormData();

      if (this.pNatural.docIdTributariaContacto === "") {
        alert(
          "Debe cargar el documento de certificación tributaria del cliente."
        );
      } else {
        formData.append("tipo", "Persona natural");
        formData.append("nombreContacto", this.pNatural.nombreContacto);
        formData.append("apellidosContacto", this.pNatural.apellidosContacto);
        formData.append(
          "tipoDocumentoContacto",
          this.pNatural.tipoDocumentoContacto
        );
        formData.append(
          "numeroDocumentoContacto",
          this.pNatural.numeroDocumentoContacto
        );
        formData.append(
          "numeroTelefonoContacto",
          this.pNatural.numeroTelefonoContacto
        );
        formData.append("emailContacto", this.pNatural.emailContacto);
        formData.append("PaisContacto", this.pNatural.PaisContacto);
        formData.append("estadoContacto", this.pNatural.estadoContacto);
        formData.append("ciudadContacto", this.pNatural.ciudadContacto);
        formData.append("direccionContacto", this.pNatural.direccionContacto);
        formData.append(
          "docIdTributariaContacto",
          this.pNatural.docIdTributariaContacto
        );
        formData.append("cargoContacto", this.pNatural.cargoContacto);
        
        await api.request("post","/store_p_natural_client",formData).then((response)=>{
          alert("Cliente creado exitosamente");
          this.$store.commit("setClientesAdmin", true)
          this.$store.commit("setCreateClients", false)
          if(response.status  == 200)
            this.pNatural = {}
        }).catch(function (e) {
            console.log(e);
        });
      
      
      }
    },
    validateInfPersJur: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pJuridica.company.razonSocial === "" &&
            this.pJuridica.company.numIdTributaria === "" &&
            this.pJuridica.company.paisCompany === "" &&
            this.pJuridica.company.estadoCompany === "" &&
            this.pJuridica.company.ciudadCompany === "" &&
            this.pJuridica.company.direccionCompany === ""
          ) {
            reject(
              "Debe registrar la información de la empresa antes de continuar."
            );
            alert(
              "Debe registrar la información de la empresa antes de continuar."
            );
          } else if (this.pJuridica.company.razonSocial === "") {
            reject("Debe ingresar la razón social o nombre de la emrpesa.");
            alert("Debe ingresar la razón social o nombre de la emrpesa.");
          } else if (this.pJuridica.company.numIdTributaria === "") {
            reject(
              "Debe ingresar el numero de identificación tributaria de la empresa"
            );
            alert(
              "Debe ingresar el numero de identificación tributaria de la empresa"
            );
          } else if (this.pJuridica.company.paisCompany === "") {
            reject(
              "Debe seleccionar el pais del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar el pais del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.estadoCompany === "") {
            reject(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.ciudadCompany === "") {
            reject(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.direccionCompany === "") {
            reject(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
            alert(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
          } else {
            console.log(this.pJuridica.company);
            resolve(true);
          }
        }, 1000);
      });
    },
    validateInfPers: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pNatural.nombreContacto === "" &&
            this.pNatural.apellidosContacto === "" &&
            this.pNatural.tipoDocumentoContacto === "" &&
            this.pNatural.numeroDocumentoContacto === "" &&
            this.pNatural.emailContacto === "" &&
            this.pNatural.numeroTelefonoContacto === ""
          ) {
            reject(
              "Debe registrar la información personal antes de continuar."
            );
            alert("Debe registrar la información personal antes de continuar.");
          } else if (this.pNatural.nombreContacto === "") {
            reject("Debe ingresar el nombre completo del cliente.");
            alert("Debe ingresar el nombre completo del cliente.");
          } else if (this.pNatural.apellidosContacto === "") {
            reject("Debe ingresar el apellidos completos del cliente.");
            alert("Debe ingresar el apellidos completos del cliente.");
          } else if (this.pNatural.tipoDocumentoContacto === "") {
            reject(
              "Debe seleccionar el tipo de documento de identificación del cliente."
            );
            alert(
              "Debe seleccionar el tipo de documento de identificación del cliente."
            );
          } else if (this.pNatural.numeroDocumentoContacto === "") {
            reject(
              "Debe ingresar el numero de documento de identificación del cliente."
            );
            alert(
              "Debe ingresar el numero de documento de identificación del cliente."
            );
          } else if (this.pNatural.emailContacto === "") {
            reject("Debe ingresar un email de contacto valido del cliente.");
            alert("Debe ingresar un email de contacto valido del cliente.");
          } else if (this.pNatural.numeroTelefonoContacto === "") {
            reject(
              "Debe ingresar un numero telefonico de contacto del cliente"
            );
            alert("Debe ingresar un numero telefonico de contacto del cliente");
          } else {
            console.log(this.pNatural);
            resolve(true);
          }
        }, 1000);
      });
    },
    validateInfResist: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pNatural.PaisContacto === "" &&
            this.pNatural.estadoContacto === "" &&
            this.pNatural.ciudadContacto === "" &&
            this.pNatural.direccionContacto === ""
          ) {
            reject(
              "Debe registrar la información de residencia antes de continuar."
            );
            alert(
              "Debe registrar la información de residencia antes de continuar."
            );
          } else if (this.pNatural.PaisContacto === "") {
            reject("Debe ingresar el pais de residencia del cliente.");
            alert("Debe ingresar el pais de residencia del cliente.");
          } else if (this.pNatural.estadoContacto === "") {
            reject("Debe ingresar el estado de residencia del cliente.");
            alert("Debe ingresar el estado de residencia del cliente.");
          } else if (this.pNatural.ciudadContacto === "") {
            reject("Debe ingresar la ciudad de residencia del cliente.");
            alert("Debe ingresar la ciudad de residencia del cliente.");
          } else if (this.pNatural.direccionContacto === "") {
            reject("Debe ingresar la dirección de residencia del cliente.");
            alert("Debe ingresar la dirección de residencia del cliente.");
          } else {
            console.log(this.pNatural);
            resolve(true);
          }
        }, 1000);
      });
    },
  },
  mounted() {
    this.loadCountries();
  },
};
</script>
<style scoped>
</style>
