<template>
  <v-app id="init">
    <video autoplay muted loop id="myVideo" :poster="imgBack">
      <source :src="videoBack" type="video/mp4" />
    </video>
    <v-main style="display: flex; align-items: center">
      <v-container>
        <v-row>
          <v-col cols="4"> </v-col>
          <v-col cols="4">
            <v-card elevation="2" outlined shaped>
              <v-row style="display: flex; justify-content: center">
                <v-col
                  cols="10"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <v-img max-height="180" max-width="180" :src="logosN">
                  </v-img>
                </v-col>
              </v-row>
              <v-container>
                <v-form @submit="login" action="/login" method="post">
                  <v-alert
                    border="top"
                    color="red lighten-2"
                    dark
                    v-for="(error, index) in errors"
                    :id="index"
                    :key="index"
                    dismissible
                    fade
                    :show="showDismissibleAlert"
                    @dismissed="showDismissibleAlert = false"
                  >
                    {{ error }}
                  </v-alert>

                  <v-col>
                    <v-text-field
                      v-model="email"
                      :label="$t('Auth.enterEmail_login')"
                      required
                      :rules="emailRules"
                    >
                      <v-img
                        class="color-fix"
                        slot="prepend"
                        max-height="25"
                        max-width="25"
                        :src="icon_user"
                      >
                      </v-img>
                    </v-text-field>
                    <v-text-field
                      v-model="password"
                      :label="$t('Auth.enterPass_login')"
                      required
                      :rules="rulesPass"
                      type="password"
                    >
                      <v-img
                        class="color-fix"
                        slot="prepend"
                        max-height="25"
                        max-width="25"
                        :src="icon_pass"
                      >
                      </v-img>
                    </v-text-field>
                    <v-container
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <v-btn
                        type="submit"
                        small
                        depressed
                        color="rgb(40, 50, 113)"
                        elevation="2"
                      >
                        <h6 style="color: white; margin-bottom: 0px">
                          {{ $t('Auth.login') }}
                        </h6>
                      </v-btn>
                      <a style="margin-top: 25px" @click="dialog = true"
                        >{{ $t('Auth.forgotPass_login') }}</a
                      >
                    </v-container>
                  </v-col>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="4"> </v-col>
        </v-row>
      </v-container>
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
        <v-card>
          <v-card-text>
            <div class="text-h6 pa-6">
              {{ $t('Auth.enterReseting_login') }}
            </div>
            <v-text-field
              label="example@safered.co"
              hide-details="auto"
              v-model="correoRe"
              :rules="emailRules"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="#57B55D"
              class="ma-2"
              style="color: white"
              @click="resetEmail()"
            >
              {{ $t('Auth.reset_login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="NotiReset"
        color="success"
        outlined
        timeout="5000"
        :top="true"
      >
        {{ $t('Auth.reset_login') }}
        <template v-slot:action="{ attrs }">
          <v-btn color="success" text v-bind="attrs" @click="NotiReset = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer color="#283271" padless app>
      <footerP />
    </v-footer>
  </v-app>
</template>

<script>
import footerP from "../../components/structure_p/FooterP.vue";
import googleDrive from "../../api/storage/googleDrive/index.js";
import cloudNet from "../../assets/video/cloud_network.mp4";
import cloufNetP from "../../assets/img/backGround.png";
import icon_Pass from "../../assets/img/icons/icon_password_user.svg";
import icon_User from "../../assets/img/icons/icon_user.svg";
import pick from "../../assets/img/icons/user_profile.svg";
import logoSN from "../../assets/img/logos/safered-logo-01.png";
import api from "../../api/index.js";

export default {
  name: "LoginS",
  components: {
    footerP,
  },
  data() {
    return {
      emailRules: [
        (v) => !!v || this.$t('Auth.emailReq_login'),
        (v) => /.+@.+\..+/.test(v) || this.$t('Auth.emailValid_login'),
      ],
      rulesPass: [
        (value) => !!value || this.$t('Auth.req_login'),
        (value) => (value && value.length >= 3) || this.$t('Auth.minCha_login')
      ],
      logosN: logoSN,
      videoBack: cloudNet,
      imgBack: cloufNetP,
      icon_pass: icon_Pass,
      icon_user: icon_User,
      data: {
        test:  ''
      },
      errors: [],
      email: null,
      password: null,
      correoRe: null,
      dialog: false,
      NotiReset: false,
    };
  },
  mounted(){
    console.log("Hola")
  },
  methods: {
    login: async function (e) {
      e.preventDefault();
      this.errors = [];

      if (!this.password) {
        this.errors.push(this.$t('Auth.passReq_login'));
      }
      if (!this.email) {
        this.errors.push(this.$t('Auth.emailR_login'));
      } else if (!this.validEmail(this.email)) {
        this.errors.push(this.$t('Auth.validEma_login'));
      }

      if (!this.errors.length) {
        var email = this.email;
        var password = this.password;

        let response = await api.request("post", "/login", { email, password });
        console.log(response.data);
        if (response.data.message == "Invalid Credentials") {
          this.errors.push(this.$t('Auth.passV_login'));
          this.showDismissibleAlert = true;
        } else if (response.data.message == "Login successfully") {
          var hoy = new Date();
          var current_user = {
            id: response.data.user.id,
            name: response.data.user.name,
            email: response.data.user.email,
            email_verified_at: null,
            created_at: response.data.user.created_at,
            updated_at: response.data.user.updated_at,
            client_id: response.data.user.client_id,
            access_token: response.data.access_token,
            isAdmin: response.data.isAdmin,
            isBoss: response.data.isBoss,
            isEditor: response.data.isEditor,
            avatar: pick,
            active: response.data.active,
            groups: response.data.groups,
            clientInfo: response.data.clientInfo,
            policy: response.data.policy,
            timeLogin: hoy,
          };

          if (current_user.active == "True") {
            this.$store.state.current_user = current_user;

            await this.getToken(current_user);

            if (window.localStorage) {
              window.localStorage.setItem("user", JSON.stringify(current_user));
            }
            this.$router.push("/App");
          } else {
            this.errors.push(this.$t('Auth.userBloc_login'));
          }
        }
      }

      this.showDismissibleAlert = true;

      e.preventDefault();
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      return re.test(email);
    },
    getToken: async function (current_user) {
      let data = await googleDrive.getToken(this.email, this.password);
      if (data.token == null) {
        let register = await googleDrive.getRegister(
          current_user.name,
          this.email,
          this.password,
          current_user.id,
        );
        if (register == true) {
          data = await googleDrive.getToken(this.email, this.password);
        }
      }
      this.$store.state.current_user.token = data.token;
      this.$store.state.current_user.id_cloudD = data.user.id;
    },
    resetEmail: async function () {
      var self = this;
      var obj = {
        email: this.correoRe,
      };

      await api
        .request("post", "/forgotPassword", obj)
        .then((data) => {
          console.log(data);
          self.NotiReset = true;
        })
        .catch(function (e) {
          console.log(e);
        });
    },
  },
};
</script>
<style>
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  filter: opacity(100%);
}

.color-fix {
  background: rgb(38, 109, 180);
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
}
</style>
